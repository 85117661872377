import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
			let roles = currentUser.roles;
			let result = false;
			roles.forEach(function(value){
				if (route.data.roles && route.data.roles.indexOf(value.name) != -1)
				{
					result = true;
				}
			});
			if(result)
				return true;
            
			this.router.navigate(['/home']);
			//this.router.navigate(['/login']);
			return false;
        }
		
		// not logged in so redirect to login page with the return url
       // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        this.router.navigate(['/login']);
        return false;
	}
	
	userPermissions(slug=''){
		const currentUser = this.authenticationService.currentUserValue;
		const permissions = currentUser.permission;
		const isAdmin = currentUser.isAdmin;
		if(!isAdmin && permissions.length > 0){
      		const current_url = this.router.url;
			//const current_slug = current_url.replace(/\\|\//g,'');
			const current_slug = slug == '' ? current_url.split("/").pop().split('#')[0] : slug;
			//console.log(current_slug);
			const permission = permissions[current_slug];
			//return false;
			var perm_array = [];
			if(typeof permission !== 'undefined'){
				if(permission.includes("c")){
				const create_perm = true;
				perm_array.push('create',create_perm);
				//console.log('create permission')
				}
				if(permission.includes("r")){
				const read_perm = true;
				//console.log('read permission')
				}
				if(permission.includes("u")){
				const update_perm = true;
				//console.log('update permission')
				}
				if(permission.includes("d")){
				const delete_perm = true;
				//console.log('delete permission')
				}
				return permission;
			}
			else{
				this.router.navigate(['/home']);
			} 
			//console.log(perm_array);
		}
	}

	premiumPackage(store_id){
		//console.log(store_id);
		const currentUser = this.authenticationService.currentUserValue;
		const storeInfo = currentUser['storeInfo'];
		var storeDetails = '';
		for(let i=0; i <= storeInfo.length; i++){
			if (typeof storeInfo[i] !== 'undefined')
			{
				const current_id = storeInfo[i]['id'];
				if(current_id == store_id){
					storeDetails = storeInfo[i];
				}
			}
		}
		const planDetails = JSON.parse(storeDetails['planDetails']);
		//console.log(planDetails);
		const premiumUser = planDetails['premium'];
		if(premiumUser == '1'){
			return true;
		}
		return false;
	}
}
