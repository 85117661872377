import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService, AuthenticationService } from '../../_services';
import { StoreService } from "../../_services/store.service";
import { MessageService } from "../../_services/message.service";
import { User,Role } from '../../_models';

@Component({
  selector: 'app-stores-combo',
  templateUrl: './stores-combo.component.html',
  styleUrls: ['./stores-combo.component.less']
})
export class StoresComboComponent implements OnInit {
	currentUser: User;
  stores: Storage[] = [];
  storeIndexSelected: number;
  companyName:string = '';
  constructor(
      private storeService: StoreService,
      private messageService: MessageService,
	  private userService: UserService,
	  private authenticationService: AuthenticationService,
  ) {
	  this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
	  
    this.reloadData();
	/*this.messageService.getMessageReloadStore().subscribe(message => {
		this.storeService.getStoreList().subscribe((data: any) =>{
		  this.stores = data.stores;
		});
		
	});*/
  }

  reloadData(){
		const currentUser = this.authenticationService.currentUserValue;
		this.stores = currentUser.storeInfo;
		localStorage.setItem('api_access_token', JSON.stringify(currentUser.access_token));
		//console.log('1233-'+currentUser.storeInfo);
      if(this.stores && this.stores.length > 0){
        this.storeIndexSelected = this.stores[0].id;
        //this.populateSelectedStorage(this.stores[0]);
      }

    var local_store_id = localStorage.getItem('store_id');  
    var store_id = local_store_id != 'null' ? local_store_id : this.storeIndexSelected;
    this.storeIndexSelected = Number(store_id);  
		this.onStoreSelected(store_id);
    /*this.storeService.getStoreList().subscribe((data: any) =>{
      this.stores = data.stores;
      if(this.stores && this.stores.length > 0){
        this.storeIndexSelected = this.stores[0].id;
        this.populateSelectedStorage(this.stores[0]);
      }
    });*/
  }

  onStoreSelected(value){
    if(value != ''){
      var foundStore = this.stores.filter(obj=>obj.id == value);
      this.populateSelectedStorage(foundStore[0]);
      this.sendMessage(foundStore[0]);
    }
  }

  sendMessage(value): void {
    // send message to subscribers via observable subject
    this.messageService.sendMessage(value);
  }

  populateSelectedStorage(storage){
    localStorage.setItem('selectedStorage', JSON.stringify(storage));
  }
}
