import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {retry, catchError} from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(
      private http: HttpClient
  ) { }
	
  getTodaySale(store_idd): Observable<any> {
    var store_id = localStorage.getItem('store_id'); 
      return this.http.get(`${environment.apiUrl}/daily_revenue/todaysale/${store_id}`);
  }
  getSales(store_idd, year, quarter): Observable<any> {
    var store_id = localStorage.getItem('store_id'); 
      return this.http.get(`${environment.apiUrl}/daily_revenue/sales/${store_id}/${year}/${quarter}`);
  }
	
  createSale(store_idd,retail,wire,widding_events,wholesale,delivery,sales_date){
    var user_id = localStorage.getItem('user_id'); 
    var store_id = localStorage.getItem('store_id'); 
        return this.http.post(`${environment.apiUrl}/daily_revenue/sale/create/${store_id}/${user_id}`, {retail,wire,widding_events,wholesale,delivery,sales_date} );
  }
  
  createInvoice(store_idd,invoice_name,invoice_number,fresh,goods,invoice_date,delivery,suppliers){
    var store_id = localStorage.getItem('store_id'); 
        return this.http.post(`${environment.apiUrl}/invoice/create_v2/${store_id}`, {invoice_name,invoice_number,fresh,goods,invoice_date,delivery,suppliers} );
  }
  
  updateDay(id,merchandise,wire,delivery){
        return this.http.put(`${environment.apiUrl}/daily_revenue/update/${id}`, {merchandise,wire,delivery} );
  }

  getProjWeeklyRevQuarter_v2(store_idd, year, quarter) : Observable<any> {
    var user_id = localStorage.getItem('user_id'); 
    var store_id = localStorage.getItem('store_id'); 
       return this.http.get(`${environment.apiUrl}/weekly_projection_percent_revenue/proj_weekly_revenue_quarter_v2/${store_id}/${year}/${quarter}/${user_id}`);
  }
	
  getProjWeeklyRevQuarter(store_idd, year, quarter) : Observable<any> {
    var store_id = localStorage.getItem('store_id'); 
       return this.http.get(`${environment.apiUrl}/weekly_projection_percent_revenue/proj_weekly_revenue_quarter/${store_id}/${year}/${quarter}`);
  }
  
  getSales_v2(store_idd, year, quarter, store_at): Observable<any> {
    var store_id = localStorage.getItem('store_id'); 
    var user_id = localStorage.getItem('user_id'); 
      return this.http.get(`${environment.apiUrl}/daily_revenue/sales_v2/${store_id}/${year}/${quarter}/${store_at}/${user_id}`);
  }
	
  getYearlySales_v2(store_idd, year, store_at): Observable<any> {
    var user_id = localStorage.getItem('user_id'); 
    var store_id = localStorage.getItem('store_id'); 
      return this.http.get(`${environment.apiUrl}/daily_revenue/yearlysales_v2/${store_id}/${year}/${store_at}/${user_id}`);
  }
	getQuarterSale(store_idd, year): Observable<any> {
        var user_id = localStorage.getItem('user_id');
        var store_id = localStorage.getItem('store_id');  
      return this.http.get(`${environment.apiUrl}/daily_revenue/quarter_sales/${store_id}/${year}/${user_id}`);
  }
  
  checkWeekProjection(store_idd): Observable<any> {
    var store_id = localStorage.getItem('store_id'); 
      return this.http.get(`${environment.apiUrl}/weekly_projection_percent_revenue/weekprojection/${store_id}`);
  }
  
	updateDay_v2(store_idd, id,entered_date,retail,wire,delivery,widding_events,wholesale){
    var user_id = localStorage.getItem('user_id'); 
    var store_id = localStorage.getItem('store_id'); 
      return this.http.put(`${environment.apiUrl}/daily_revenue/update_v2/${store_id}/${id}/${user_id}`, {entered_date,retail,wire,delivery,widding_events,wholesale} );
  }


    getWeekSales_v2(year, quarter): Observable<any> {
        return this.http.get(`${environment.apiUrl}/daily_revenue/week_sales_v2/${year}/${quarter}`);
    }
    createVendor(store_idd,vendor_name){
      var store_id = localStorage.getItem('store_id'); 
        return this.http.post(`${environment.apiUrl}/invoice/create_vendor/${store_id}`, {vendor_name} );
    }
    
    getVendors(store_idd){
        var user_id = localStorage.getItem('user_id'); 
        var store_id = localStorage.getItem('store_id'); 
        return this.http.get(`${environment.apiUrl}/invoice/get_vendors/${store_id}/${user_id}`, {} );
    }
    getCategories(store_idd){
      var user_id = localStorage.getItem('user_id');
      var store_id = localStorage.getItem('store_id');  
      return this.http.get(`${environment.apiUrl}/invoice/getcategories/${store_id}/${user_id}`, {} );
  }
	
	create_store_percent(store_idd,cof_retail,cof_wire,cof_weddings,cog_retail,cog_wire,cog_weddings){
    var store_id = localStorage.getItem('store_id'); 
    return this.http.post(`${environment.apiUrl}/store_percent/create`,{store_id,cof_retail,cof_wire,cof_weddings,cog_retail,cog_wire,cog_weddings});
  }
	getStoresPercent(store_idd){
    var store_id = localStorage.getItem('store_id'); 
    return this.http.post(`${environment.apiUrl}/store_percent/get`,{store_id});
  }
  create_vendornew(store_idd,v_name,category,account,contact_person,contact_number,alt_contact_number,addressline1,addressline2,city,state,zip,vendorlogin,vendornotes){
    var user_id = localStorage.getItem('user_id'); 
    var store_id = localStorage.getItem('store_id'); 
    return this.http.post(`${environment.apiUrl}/invoice/create_vendornew/${store_id}`, {user_id,v_name,category,account,contact_person,contact_number,alt_contact_number,addressline1,addressline2,city,state,zip,vendorlogin,vendornotes} );
}


}
