import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { UserService, AuthenticationService, PlanService, CompanyService } from './_services';
/*import {PlanService} from '../_services'*/
import { User,Role } from './_models';
import { CheckRole } from "./_helpers/check-role";
import { StoreSubscriberService } from "./_services/storeSubscriber.service";
declare var $: any;
/*declare var jquery: any*/

@Component({ selector: 'app', templateUrl: 'app.component.html',providers: [CheckRole]})
export class AppComponent implements OnInit{
    currentUser: User;
    toggled: boolean = false;
    modules: any;
    stores: Storage[] = [];
	companyName:string = '';
	profile_name:string= '';
	
	varr:boolean = true;
	storeIndexSelected:number;
	companyIndexSelected:number;
	imagePath = UserService.imagePath;
	selectedStorage: any;
	company: any[];
	profileAccess = false;
	menuOpened = true;
	profile_image = "";
    ngOnInit() {
		
		const store_id = localStorage.getItem('store_id');
		const currentUser = this.authenticationService.currentUserValue;
		if(currentUser !== null){
			this.stores = currentUser.storeInfo;
			this.profile_image = currentUser.profile_image;
			localStorage.setItem('api_access_token', JSON.stringify(currentUser.access_token));		
		}
		if(this.stores && this.stores.length > 0){
			this.storeIndexSelected = this.stores[0].id;
			this.companyIndexSelected = this.stores[0].company_id;
		}
		this.loadModules();
		this.loadStores();
		this.profilePermission();

		$( document ).ready(function() {
			$(".cstmprflmenu1").click(function(e){
				e.preventDefault();
				$(this).toggleClass('show');
			});

			$(document).on("click","#togle_menu",function(e) {
				e.preventDefault();
				if ($(this).parents('.cstmprflmenu1').hasClass("show")) {
					$(this).parents('.cstmprflmenu1').removeClass("show");
				}
				else{
					$(this).parents('.cstmprflmenu1').addClass("show");
				}
			});
			$(document).on("click",".dropdown-menu .dropdown-item",function(e) {
				$(this).parents('.cstmprflmenu1').removeClass("show");
			});
		});
	}

	ngAfterViewInit() {
		this.cdr.detectChanges();
		const store_ID = localStorage.getItem('store_id');
		const user_type = localStorage.getItem('user_type');
		console.log(store_ID);
		if(user_type =='3')
		{
			setTimeout(() => {
				$('.vertMenuUl li:nth-child(8)').hide();
			}, 1000); 
		}
		if(store_ID=='99999')
		{
			setTimeout(() => {
				$('.vertMenuUl li:nth-child(2)').hide();
				$('.vertMenuUl li:nth-child(3)').hide();
				$('.vertMenuUl li:nth-child(4)').hide();
				$('.vertMenuUl li:nth-child(5)').hide();
				$('.vertMenuUl li:nth-child(6)').hide();
				$('.vertMenuUl li:nth-child(7)').hide();
				$('.vertMenuUl li:nth-child(8)').hide();
				$('.BottomMenuicon').hide();
				
			  }, 1000); 
		}

		
	}

    loadModules(){
        const currentUser = this.authenticationService.currentUserValue;
        if(currentUser)
        {
		   return this.planService.getByUser(currentUser.user_id).subscribe((data: any) =>{
				const permissions = currentUser.permission;
				const isAdmin = currentUser.isAdmin;
				if(!isAdmin && permissions.length > 0){
					const modules = [];
					for(let i = 0; i<= data.modules.length ; i++){
						
						const data_modules = data.modules[i];
						if (typeof data_modules !== 'undefined'){
							const page_action = data.modules[i].action;
							const current_slug = page_action.split("/").pop();
							const permission = permissions[current_slug];
							if(typeof permission !== 'undefined'){
								
								modules.push(data_modules);
							}
						}
					}
					this.modules = modules;
				}
				else{
					this.modules = data.modules;	
				}
				
            });
        }
    }

    loadStores(){
        const currentUser = this.authenticationService.currentUserValue;
		if(currentUser)
        {
			this.profile_name = currentUser.profile_name;
			this.stores = currentUser.storeInfo;
            /*return this.companyService.storesByCompany().subscribe((data: any) =>{
                this.stores = data.stores;
            });*/
        }
    }

    loadComponents(component_id: string)
    {
        this.router.navigate([component_id]);
    }

	toggleMenu()
    {
        if(!this.toggled)
            this.toggled = true;
        else{
            this.toggled = false;
        }
    }

    constructor(
        private router: Router,
        private userService: UserService,
        private planService:PlanService,
        private companyService:CompanyService,
        private authenticationService: AuthenticationService,
		private checkRole: CheckRole,
		private storeSubscriberService: StoreSubscriberService,
		private cdr: ChangeDetectorRef
    ) {
		storeSubscriberService.subscribe(this,function (ref,store) {
			ref.receiveStorage(store);
			});
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
	}


	receiveStorage(storage){
		this.selectedStorage = storage;
		this.companyIndexSelected = this.selectedStorage.company_id;
	}
    logout() {
        this.router.navigate(['/login']);
		this.authenticationService.logout();
    }
	
	get hasAcces() {
    	if(this.checkRole.isRoot() || this.checkRole.isCompanyAdmin() || this.checkRole.isStoreManager())
		  return true;
		else return false;
	}
	
	get editCompanyEmployee()
	{
		if(this.checkRole.isRoot() || this.checkRole.isCompanyAdmin())
		  return true;
		else return false;
	}
	
	get editAppUser()
	{
		if(this.checkRole.isRoot() || this.checkRole.isCompanyAdmin() || this.checkRole.isStoreManager() || this.checkRole.isAppUser())
		  return true;
		else return false;
	}
	
	isStoreSubmenu()
	{
		let url = this.router.url;
		if(url.includes('store') || url.includes('storeedit') || url.includes('storecreate'))
		{
			return true;
		}
		return false;
	}
	
	isEmployeeSubmenu()
	{
		let url = this.router.url;
		if(url.includes('employees') || url.includes('edit-employee') || url.includes('create-employee'))
		{
			return true;
		}
		return false;
	}
	
	isCompanyEmployeeSubmenu()
	{
		let url = this.router.url;
		if(url.includes('companyemployee-list') || url.includes('create-companyemployee') || url.includes('edit-companyemployee'))
		{
			return true;
		}
		return false;
	}
	
	isAppUserSubmenu()
	{
		let url = this.router.url;
		if(url.includes('app-users') || url.includes('app-user-create') || url.includes('app-user-edit'))
		{
			return true;
		}
		return false;
	}
	
	isSettingsActive()
	{
		let url = this.router.url;
		if(this.router.isActive('stores',true)  || this.router.isActive('employees',true) || this.router.isActive('companyemployee-list',true) || this.router.isActive('app-users',true))
		{
			
			return true;
		}
		else if(url.includes('storeedit') || url.includes('store') || url.includes('storecreate') || url.includes('employees')|| url.includes('create-employee') || url.includes('edit-employee')
			|| url.includes('companyemployee-list') || url.includes('create-companyemployee') || url.includes('edit-companyemployee') || url.includes('app-users') || url.includes('app-user-create') || url.includes('app-user-edit'))
		{
			return true;
		}
		else 
			return false;
	}
	
	setActiveManu(router_url:any, action_url:any)
	{
		if(router_url===action_url)
			return true;
		if(router_url.includes('week-panel') && action_url == '/cost-of/fresh')
			return true;
		if(router_url.includes('month-panel') && action_url == '/cost-of/goods')
			return true;
		if(router_url.includes('inventoryreport') && action_url == '/inventory')
			return true;
		return false;
	}
	storeSelect(event){
		this.ngOnInit();
	}
	 navigateWithState(value) {
		$('a.nav-link[href="#'+value+'"]').click(); 
		this.router.navigateByUrl('/profile', {
			state: {
				page: value
			}
		});
	}

	profilePermission(){
		const currentUser = this.authenticationService.currentUserValue;
		if(currentUser){
			const permissions = currentUser.permission;
			
			const isAdmin = currentUser.isAdmin;
			const permission = permissions['profile'];
			if(!isAdmin){
				const modules = [];
				if (permissions.length == 0){
					this.profileAccess = true;
				}
				else{
					if(typeof permission !== 'undefined'){
						this.profileAccess = true;
					}
				}
				
			}
			else{
				this.profileAccess = true;
			}
		}
	}

	toggleMenuClick(){
		const active = this.menuOpened == true ? false : true;
		this.menuOpened = active;
	}
}
