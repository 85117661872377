import { Component, OnInit, NgZone } from '@angular/core';
import { AuthenticationService } from '@app/_services'; 
import { SalesService } from "../_services/sales.service";
import { UtilsService } from "../_services/utils.service";
import { AuthGuard } from "../_helpers/auth.guard";
import {TableModule} from 'primeng/table';
import {Observable} from "rxjs";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray ,ValidationErrors} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-company-stores',
  templateUrl: './add-company-stores.component.html',
  styleUrls: ['./add-company-stores.component.less']
})
export class AddCompanyStoresComponent implements OnInit {
  stateLoading = false;
  storecreateloading = false;
  stateLoadingStore = [];
  states = [];
  States = [];
  isValidFormSubmitted = null;
  submitted = null;
  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,//service used to receive store from top bar stores combobox
    private salesService: SalesService,
    private utilService: UtilsService,
    private route : Router,
    private authGuard : AuthGuard,
    private zone: NgZone,
  ) { }
  countries=[];
  timezones=[];
  CompanyForm: FormGroup;
  ngOnInit(): void {
    document.body.classList.remove('loginbody');
    this.authenticationService.getcountries().subscribe((data: any) => {
      this.countries = data.countries;
    });

    this.authenticationService.gettimezones().subscribe((data: any) => {
      this.timezones = data;
    });
    this.CompanyForm = this.fb.group({
      store_name: ['', Validators.required],
      owner_name: ['', Validators.required],
      contact_number: ['', [Validators.required, Validators.minLength(5)]],
      email_Address: ['', [Validators.required, Validators.email]],
      address_line1: ['', Validators.required],
      address_line2: [''],
      country: ['', Validators.required],
      city: ['', Validators.required],
      State: ['', Validators.required],
      zip: ['', Validators.required],
      timezone: ['', Validators.required],
      
    });
  }

  get f() { 
    Object.keys(this.CompanyForm.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.CompanyForm.get(key).errors;
      if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
            });
          }
        });
      
    return this.CompanyForm.controls; 
  }


  changeState(event) {
    this.stateLoading = true;
    localStorage.setItem('country.id', event);
    this.authenticationService.getRegions(event).subscribe(res => {
      this.states = res.regions;
      this.stateLoading = false;
    });
    
    this.CompanyForm.controls.State.patchValue("");

  }

  Submit() {
    this.storecreateloading = true;
    this.isValidFormSubmitted = false;
    this.submitted = true;
    if (this.CompanyForm.invalid) {
      this.storecreateloading = false;
      return;
    }

    this.isValidFormSubmitted = true;
    
    this.authenticationService.CreateCompanyAndStores(this.CompanyForm.value,).subscribe(res => {
      if (res.status == 1) {
        this.storecreateloading = false;
        localStorage.setItem('store_id_new', res.store_ID);
        this.route.navigate(['/add-product-subscriptions']);
      }
      else{
        this.storecreateloading = false;
      }

    })

  }
  

}
