import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {retry, catchError} from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CostOfFreshService{

  constructor(
      private http: HttpClient
  ) { }

  getMasterOverviewWeekly(cosf_of, store_id, year, quarter): Observable<any> {
    var store = localStorage.getItem('store_id'); 
     return this.http.get(`${environment.apiUrl}/master_overview_weekly/master_overview_weekly_of/${cosf_of}/${store}/${year}/${quarter}`);
  }

	getMasterOverviewWeekly_v2(cosf_of, store_id, year, quarter, created_at): Observable<any> {
    var store = localStorage.getItem('store_id'); 
    var user_id = localStorage.getItem('user_id'); 
     return this.http.get(`${environment.apiUrl}/master_overview_weekly/master_overview_weekly_of_v2/${cosf_of}/${store}/${year}/${quarter}/${created_at}/${user_id}`);
  }
}
