import { Component, Output, EventEmitter,OnInit,Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ProfileService } from "../_services/profile.service";
import { AuthenticationService } from '../_services';
import { StoreSubscriberService } from "../_services/storeSubscriber.service";
import { MessageToastService } from "../_services/messageToast.service";
import { Subject } from "rxjs";

@Component({
  selector: 'app-add-tax',
  templateUrl: './add-tax.component.html',
  styleUrls: ['./add-tax.component.less']
})
export class AddTaxComponent implements OnInit {

  constructor(
    private profileService: ProfileService,
	  private authenticationService: AuthenticationService,
  	private storeSubscriberService: StoreSubscriberService,
	  private formBuilder: FormBuilder,
  	private messageToastService: MessageToastService,

  ) { 
    storeSubscriberService.subscribe(this,function (ref,store) {
      ref.receiveStorage(store);
      });
  }
  @Input() formData: [];
  add_user_submitted = false;
	emailError = '';
	addedById= '';
	userList = [];
  userTableCols = [];
  Userloading = false;
	UserRoleList = [];
	selectedStorage: any;
	company: any[];
	taxForm : FormGroup;
	submitted = false;
  error = "";
  tax_name = "";
  @Output() adduserEvent = new EventEmitter<string>();
  @Input() resetFormSubject: Subject<boolean> = new Subject<boolean>();

  ngOnInit(): void {
    this.resetFormSubject.subscribe(response => {
      if(response){
        this.submitted = false; 
        }
    });
      this.selectedStorage = JSON.parse(localStorage.getItem('selectedStorage'));
     this.addedBy();
    
    this.taxForm = this.formBuilder.group({
      id : [''],
      tax_name: ['', Validators.required],
      tax_value: ['', Validators.required],
    
    });
  }

  receiveStorage(storage){
    this.selectedStorage = storage;
    this.getUserRoleList();
    this.addedBy();
  }
  add_user_form = new FormGroup({
    tax_name: new FormControl('', [Validators.required]),
    tax_type: new FormControl('', [Validators.required]),
    tax_value: new FormControl('', [Validators.required]),

		role: new FormControl('', Validators.required)
	});
	  
	get add_user_f(){
		return this.add_user_form.controls;
  }
  
  add_user_submit(){
    
		this.add_user_submitted = true;
		if(this.add_user_form.invalid){
			this.Userloading = false;
			return;
		}

		this.Userloading = true;

		const tax_name = this.add_user_form.value.tax_name;
		const tax_type = this.add_user_form.value.tax_type;
		const tax_value = this.add_user_form.value.tax_value;
		

  }
  
  addUserModal(){
		this.add_user_submitted = false;
		this.add_user_form.reset();
		this.add_user_form.get("role").setValue("");
	}

	emailEnter(){
		this.emailError = '';
  }

  getUserRoleList(){
		this.profileService.getUserRole(this.selectedStorage.id).subscribe((response: any) =>{
			if(response.status == 1){
				this.UserRoleList = response.result.data;
			}
		});
  }
  
  addedBy()
	{
    //this.addedById = this.company['added_by'];
  }

  get f() { return this.taxForm.controls; }
	
	keyUpFunction(event)
	{
		if(event.keyCode == 13)
		{
			this.onSubmit();
		}
  }
  
  onSubmit() {
    this.submitted = true; 
    if (this.taxForm.invalid) {
        return;
    }
  this.profileService.createTax(this.selectedStorage.id, this.f.tax_name.value,this.f.tax_value.value, this.f.id.value )
.pipe()
.subscribe(
data => {
  
  let element:HTMLElement = document.getElementById('closeEmpTaxModal') as HTMLElement;
  element.click();
  this.messageToastService.sendMessage('success','Tax Message','Tax was created!');
  this.loadEmployeeTaxData();
  this.submitted = false; 
  this.adduserEvent.emit('true');
},
error => {
  this.error = error;
});
}
loadEmployeeTaxData = function (){
  this.profileService.getEmployees(this.selectedStorage.id).subscribe((response: any) =>{
    if(response.employees.length > 0){
      this.employeeData = response.employees;
    }
    else{
      this.employeeData = '';
    }
  });
}





}
