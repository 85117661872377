import { Component, Output, EventEmitter, OnInit, Input, OnChanges,ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ProfileService } from "../_services/profile.service";
import { AuthenticationService } from '../_services';
import { StoreSubscriberService } from "../_services/storeSubscriber.service";
import { MessageToastService } from "../_services/messageToast.service";
import { Subject } from "rxjs";
interface SelectedValue {
	value: string;
	text: string;
	taxValue: number;
	percentage: number;
}
@Component({
	selector: 'app-add-user',
	templateUrl: './add-user.component.html',
	styleUrls: ['./add-user.component.less']
})
export class AddUserComponent implements OnInit {

	constructor(
		private profileService: ProfileService,
		private authenticationService: AuthenticationService,
		private storeSubscriberService: StoreSubscriberService,
		private formBuilder: FormBuilder,
		private messageToastService: MessageToastService,
		private cdr: ChangeDetectorRef
	) {
		storeSubscriberService.subscribe(this, function (ref, store) {
			ref.receiveStorage(store);
		});

	}
	@Input() formData: [];
	emp_form_data = [];
	add_user_submitted = false;
	emailError = '';
	addedById = '';
	userList = [];
	optionInputValue: string;
	userTableCols = [];
	Userloading = false;
	employee_tax_value = true;
	UserRoleList = [];
	Stores1 = [];
	selectedStorage: any;
	selectedValues: SelectedValue[] = [];
	company: any[];
	percentage: number;
	employeeForm: FormGroup;
	private runningTotal: number = 0;
	// selectedValues: { value: string, text: string, for: string }[] = [];
	submitted = false;
	total_value_tax: string;
	error = "";

	@Output() adduserEvent = new EventEmitter<string>();
	@Output() editEmployeeEvent = new EventEmitter<number>();
	@Input() resetFormSubject: Subject<boolean> = new Subject<boolean>();
	editEmployee1(id: number) {
		console.log('dasdasd');
		this.editEmployeeEvent.emit(id);
		this.cdr.detectChanges(); // Trigger change detection
	  }

	ngOnInit(): void {
		this.resetFormSubject.subscribe(response => {
			if (response) {
				this.submitted = false;
			}
		});
		this.selectedStorage = JSON.parse(localStorage.getItem('selectedStorage'));
		this.addedBy();
		this.getuserStores();
		this.loadEmployeeTaxData();
		this.getAllComp();
		this.employeeForm = this.formBuilder.group({
			id: new FormControl(''),
			first_name: ['', Validators.required],
			last_name: ['', Validators.required],
			email: ['', Validators.required],
			status: ['', Validators.required],
			storesmultiple: ['', Validators.required],
			employee_type: ['', Validators.required],
			phone_number: ['', Validators.required],
			employee_value: ['', Validators.required],
			tax_IDD: [[]],
			total_value_tax: ['']
		});


	}
	
	getAllComp() {
		const employee_id = localStorage.getItem('employee_id');
		console.log('dddd', employee_id);
		let json = [];
		let emp = this.profileService.getempbyid(employee_id).subscribe((response: any) => {
			console.log(response);
			json = response.employee_tax;
		});
		console.log(json,'json')
		this.selectedValues = json;
	}

	getuserStores() {
		this.profileService.getuserStores().subscribe((response: any) => {
			this.Stores1 = response.data;
		});
	}
	receiveStorage(storage) {
		this.selectedStorage = storage;
		this.getUserRoleList();
		this.addedBy();
	}
	add_user_form = new FormGroup({
		first_name: new FormControl('', [Validators.required]),
		last_name: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required, Validators.email]),
		mobile_no: new FormControl('', [Validators.required, Validators.minLength(8)]),
		role: new FormControl('', Validators.required)
	});

	get add_user_f() {
		return this.add_user_form.controls;
	}

	add_user_submit() {
		this.add_user_submitted = true;
		if (this.add_user_form.invalid) {
			this.Userloading = false;
			return;
		}

		this.Userloading = true;

		const email = this.add_user_form.value.email;
		const first_name = this.add_user_form.value.first_name;
		const last_name = this.add_user_form.value.last_name;
		const mobile_no = this.add_user_form.value.mobile_no;
		const role_id = this.add_user_form.value.role;
		const emp_id = this.add_user_form.value.id;
		const role_vendor = '';
		const role_vendor1 = '';
		const role_vendor2 = '';


		this.profileService.createUser(this.addedById, email, first_name, last_name, mobile_no, role_id, this.selectedStorage.id, role_vendor, role_vendor1, role_vendor2).subscribe((response: any) => {
			this.Userloading = false;

			if (response.status == 1) {

				this.adduserEvent.emit('true');
				let element: HTMLElement = document.getElementById('closeUserModal') as HTMLElement;
				element.click();
			}
			else {
				this.emailError = response.validationErrors.email;

			}
		});

	}

	addUserModal() {
		this.add_user_submitted = false;
		this.add_user_form.reset();
		this.add_user_form.get("role").setValue("");
	}

	emailEnter() {
		this.emailError = '';
	}

	getUserRoleList() {
		this.profileService.getUserRole(this.selectedStorage.id).subscribe((response: any) => {
			if (response.status == 1) {
				this.UserRoleList = response.result.data;
			}
		});
	}
	addedBy() {
		//	 this.addedById = this.company['added_by'];
	}

	get f() { return this.employeeForm.controls; }

	keyUpFunction(event) {
		if (event.keyCode == 13) {
			this.onSubmit();
		}
	}
	onSubmit() {
		this.submitted = true;
		if (this.employeeForm.invalid) {
			return;
		}
		this.profileService.createEmployee(this.selectedStorage.id, this.f.first_name.value, this.f.last_name.value, this.f.email.value, this.f.status.value, this.f.employee_type.value, this.f.employee_value.value, this.f.phone_number.value, this.f.id.value, this.f.storesmultiple.value, this.selectedValues, this.f.total_value_tax.value)
			.pipe()
			.subscribe(
				data => {
					let element: HTMLElement = document.getElementById('closeEmpModal') as HTMLElement;
					element.click();
					this.messageToastService.sendMessage('success', 'Employee Message', 'Employee was created!');
					this.loadEmployeeData();
					this.submitted = false;
					this.adduserEvent.emit('true');
					$('.employee_valuedev').hide();
				},
				error => {
					this.error = error;
					//this.loading = false;
				});
	}

	loadEmployeeData = function () {
		this.profileService.getEmployees(this.selectedStorage.id).subscribe((response: any) => {
			if (response.employees.length > 0) {
				this.employeeData = response.employees;
			}
			else {
				this.employeeData = '';
			}
		});
	}
	onOptionsSelected(value: string) {
		if (value != "") {
			$('.employee_valuedev').show();
		}
		else {
			$('.employee_valuedev').hide();
		}
	}
	loadEmployeeTaxData = function () {
		this.profileService.getEmployeesTax(this.selectedStorage.id).subscribe((response: any) => {
			if (response.employeetax.length > 0) {
				this.employeeTaxData = response.employeetax;
				console.log(this.employeeTaxData);
			}
			else {
				this.employeeTaxData = '';
			}
		});
	}

	onSelectChange(event: Event) {
		this.employee_tax_value = false;
		const target = event.target as HTMLSelectElement;
		const selectedValue = target.value;
		const selectedText = target.options[target.selectedIndex].text;
		const selectedTaxValue = parseFloat(target.options[target.selectedIndex].getAttribute('data-tax-value'));
		const employeeValue = parseFloat(this.f.employee_value.value);

		const isAlreadySelected = this.selectedValues.some(item => item.value === selectedValue);

		if (!isAlreadySelected) {
			const percentage = this.calculatePercentage(employeeValue, selectedTaxValue); // Calculate the percentage
			const option = { value: selectedValue, text: selectedText, taxValue: selectedTaxValue, percentage: percentage };

			this.selectedValues.push(option);
			this.runningTotal += percentage;
			this.optionInputValue = selectedValue;
			this.total_value_tax = (employeeValue + this.runningTotal).toFixed(2);

		}
	}
	calculatePercentage(employeeValue: number, selectedTaxValue: number): number {
		return (selectedTaxValue / 100) * employeeValue;
	}

	removeItem(index: number) {
		this.selectedValues.splice(index, 1);
		this.recalculateTotalValueTax();
	}
	recalculateTotalValueTax() {
		const employeeValue = parseFloat(this.f.employee_value.value);
		this.runningTotal = 0;
		for (const option of this.selectedValues) {
			this.runningTotal += option.percentage;
		}
		this.total_value_tax = (employeeValue + this.runningTotal).toFixed(2);
	}

	onEmployeeValueChange() {
		const employeeValue = parseFloat(this.f.employee_value.value);
		this.selectedValues.forEach(option => {
			option.percentage = this.calculatePercentage(employeeValue, option.taxValue);
		});

		this.runningTotal = this.selectedValues.reduce((total, option) => total + option.percentage, 0);
		this.total_value_tax = (employeeValue + this.runningTotal).toFixed(2);
	}




}
