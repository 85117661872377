import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customFunctions'
})
export class CustomFunctionsPipe implements PipeTransform {

  /* transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  } */

  transform(value: number): number {
    if(!isNaN(value)){
        return value;
    }
    return null;
  }
}
