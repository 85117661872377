import { Component, ViewChild, OnInit ,Output,EventEmitter} from '@angular/core';
import { AuthenticationService } from '../_services';
import { StoreSubscriberService } from "../_services/storeSubscriber.service";
import { UtilsService } from "../_services/utils.service";
import { ProfileService } from "../_services/profile.service";
import { WeekPanelService } from "../_services/weekPanel.service";
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { PlanService } from '../_services';
import { ActivatedRoute } from '@angular/router';


import * as $ from 'jquery' 
declare  var jQuery:  any;
import { MessageToastService } from "../_services/messageToast.service";
import { Subject } from "rxjs";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AuthGuard } from "../_helpers/auth.guard";
import { Router } from '@angular/router';
interface SelectedValue {
	value: string;
	text: string;
	taxValue: number;
	percentage: number;
}
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less']
})
export class ProfileComponent implements OnInit {
	
	@ViewChild('closeEmpModal') closeEmpModal;
	pageLoaded = false;
	
	private runningTotal: number = 0;
	keyword1: string;
	keyword2: string;
	keywordstatus:string;
	vendoralready:any;
	storename: string;
	categorylabel: string;
	selectedStorage: any;
	Storeselecteddata:any;
	selectedItems: any[] = [];
	selectedItemsemp: any[] = [];
	company: any[];
	selectedValues: SelectedValue[] = [];
	employeeTableCols: any[];
	employeeData: any[];
	loading = true;
	Userloading = false;
	MergeVendorloading = false;
	employeeForm: FormGroup;
	submitted = false;
	error:'';
	store_name = '';
	store_email = '';
	store_contact = '';
	store_owner = '';
	store_address_line1='';
	store_address_line2='';
	store_city='';
	store_state='';
	store_country='';
	store_zip='';

	depColor = '31BD6D';
	cof_retailget:number;
	cof_wireget:number;
	cof_weddingsget:number;
	cof_wholesaleget:number;
	cof_payoutget:number;
	cog_retailget:number;
	laborget:number;
	cog_wireget:number;
	cog_weddingsget:number;
	cog_wholesaleget:number;
	cog_payoutget:number;
	role_vendor : any;
	departmentSubmitted = false;
	departmentschSubmitted = false;
	categorySubmitted = false;
	departmentList = [];
	categoryList = [];
	depTableCols = [];
	add_user_submitted = false;
	merge_vendor_submitted = false;
	add_cost_submitted = false;
	edit_vendor_submitted = false;
	emailError = '';
	addedById= '';
	userList = [];
	Stores = [];
	userTableCols = [];
	employeeTableTaxCols = [];
	modules = [];
	addRoleSubmitted = false;
	viewChecked = [];
	loadingRoleForm = false;
	employee_tax_value = false;
	vendorList = [];
	getSchedulerList = [];
	UserRoleList = [];
	roleTableCols = [];
	defaultTab = 'users';
	activeTab = "";
	premiumPlan = false;
	resetFormSubject: Subject<boolean> = new Subject<boolean>();
	userPermission = [];
	store_logo = "";
	tax_form_data = [];
	emp_form_data = [];
	//Storeselecteddata : any[] = [];
	vendor_form_data = [];
	vendor_merge_one = [];
	vendor_merge_two = [];
	editUserData = [];
	roleData = [];
	store_role_name = '';
	store_role_id = '';
	isLoading =  false;
	total_value_tax: string;
	optionInputValue: string;
	@Output() adduserEvent = new EventEmitter<string>();
	resetChildForm(){
		$('.compensec').hide();
		$('.employee_valuedev').hide();
		this.employeeForm.reset();
		this.selectedValues = [];
		this.tax_form_data = [];
		this.emp_form_data = [];
		$(".addtaxpopup").trigger('reset');
		this.resetFormSubject.next(true);
	}

	adduserEventHander($event: any) {
		console.log($event);
		if($event){
		  this.loadEmployeeData();
		  this.loadEmployeeTaxData();
			this.getVendors1();
		}
		//this.userSubmitted = $event;
	  }
  constructor(
	private messageToastService: MessageToastService,
	private formBuilder: FormBuilder,
	private storeSubscriberService: StoreSubscriberService,
	private utilService: UtilsService,
	private profileService: ProfileService,
	private authenticationService: AuthenticationService,
	private planService:PlanService,
	private route: ActivatedRoute,
	private authGuard : AuthGuard,
	private router: Router,
  ) {
	
	this.authGuard.userPermissions();  
	storeSubscriberService.subscribe(this,function (ref,store) {
		ref.receiveStorage(store);
	});
    let currentYear = this.utilService.GetCurrentYear();
  }
  	getMessage(message: string) {
		//console.log(message);
	}
  ngOnInit(): void {
	const permissions = this.authGuard.userPermissions();
    this.userPermission = permissions;
	this.route.fragment.subscribe((fragment: string) => {
		this.activeTab = fragment;
		if(!fragment){
			this.activeTab = this.defaultTab;
		}
    })
	document.body.classList.remove('loginbody');
	this.selectedStorage = JSON.parse(localStorage.getItem('selectedStorage'));
	this.role_vendor = localStorage.getItem('role_vendor')
	this.checkPremium(this.selectedStorage.id);
	this.storename = this.selectedStorage.store_name;
  	(function ($) {
       $(document).ready(function(){
		  $(".cnewemployee").click(function(){
		  $(".employeemaintab .table-responsive").hide();
			$(".createnewrole").hide();
			$(".stepformemployee").show();
		  });
		  $(".cancelemployee").click(function(){
			$(".employeemaintab .table-responsive").show();
			$(".createnewrole").show();
			$(".stepformemployee").hide();
		  });
		  
		   $(".cnewstore").click(function(){
		  $("#mystores .table-responsive").hide();
			$(".createnewstore").hide();
			$(".stepformstore").show();
		  });
		  $(".cancelstore").click(function(){
			$("#mystores .table-responsive").show();
			$(".createnewstore").show();
			$(".stepformstore").hide();
		  });

		  /******Store End*****/

			 /******User Start*****/
		   $(".cnewusers").click(function(){
		  $("#users .table-responsive").hide();
			$(".createnewusers").hide();
			$(".stepformusers").show();
		  });
		  $(".cancelusers").click(function(){
			$("#users .table-responsive").show();
			$(".createnewusers").show();
			$(".stepformusers").hide();
		  });
		  /******User End*****/
		  
		   /******Role Start*****/
		   $(".cnewroles").click(function(){
		  $("#roles .table-responsive").hide();
			$(".createnewrole").hide();
			$(".stepformroles").show();
		  });
		  $(".cancelroles").click(function(){
			$("#roles .table-responsive").show();
			$(".createnewrole").show();
			$(".stepformroles").hide();
		  });
		  /******Role End*****/
		  $('a.nav-link[href="#'+history.state.page+'"]').click(); 

		  $(".form-control.cstm_color option").each(function() {
			$(this).css("background-color", $(this).val())
		  })
		});
     })(jQuery);
	this.loadHeaders();
	this.loadEmployeeData();
	this.getVendors1();  
	this.getschedule_format();
	this.getStorePercent();
	this.loadEmployeeTaxData();
	this.loadDepHeader();
	this.employeeForm = this.formBuilder.group({
		id: new FormControl(''),
		first_name: ['', Validators.required],
		last_name: ['', Validators.required],
		email: ['', Validators.required],
		status: ['', Validators.required],
		storesmultiple: ['', Validators.required],
		employee_type: ['', Validators.required],
		phone_number: ['', Validators.required],
		employee_value: ['', Validators.required],
		tax_IDD: [[]],
		total_value_tax: ['']
	});
	//this.loadCompanyInfo();
	this.fetchStore();
	this.getDepartment();
	this.getCategories()
	this.addedBy();
	this.getUserList();
	this.getuserStores();
	this.loadUserHeaders();
	this.loadTaxHeaders();
	this.loadModules();
	this.getUserRoleList();
	this.loadRoleHeaders();
  }

  receiveStorage(storage){

    this.selectedStorage = storage;
	this.storename = this.selectedStorage.store_name;
	this.checkPremium(this.selectedStorage.id);
	
    this.loadHeaders();
	this.loadEmployeeData();
	this.loadEmployeeTaxData();
	this.fetchStore();
	this.getDepartment();
	this.getCategories();
	this.addedBy();
	this.getUserList();
	this.getUserRoleList();
	this.getStorePercent();
	this.getVendors1();
  }
  fetchStore(){
	  
	  this.store_name = this.selectedStorage.store_name;
	  this.store_email = this.selectedStorage.email;
	  this.store_contact = this.selectedStorage.contact_number;
	  this.store_owner = this.selectedStorage.owner_name;
	  this.store_logo = this.selectedStorage.store_logo;
	  this.store_address_line1 = this.selectedStorage.address_line1;
	  this.store_address_line2 = this.selectedStorage.address_line2;
	  this.store_city = this.selectedStorage.city;
	  this.store_state = this.selectedStorage.state;
	  this.store_country = this.selectedStorage.country;
	  this.store_zip = this.selectedStorage.zip;
	  
  }
	
	
	loadHeaders()
	{
		this.employeeTableCols = [
			{ field: 'first_name', header: 'First Name'},
			{ field: 'last_name', header: 'Last Name'},
			{ field: 'email', header: 'Email Address'},
			{ field: 'phone_number', header: 'Phone Number'},
		];	
	}
  
	
	
	addedBy()
	{
		
	}

	
	
	loadEmployeeData = function (){
		this.keywordstatus = $('#keywordstatus').val();
		this.profileService.getEmployees(this.selectedStorage.id,this.keywordstatus).subscribe((response: any) =>{
			if(response.employees.length > 0){
				console.log(response.employees);
				this.employeeData = response.employees;
			}
			else{
				this.employeeData = '';
			}
		});
	}
	getVendorssearchname(event)
	{
		if(event.keyCode == 13)
		{
		   this.getVendors1();
		}
	}

	onchangestatus(event)
	{
		this.getVendors1();
	}
	onchangeempstatus(event)
	{
		this.loadEmployeeData();
	}
	getVendors1 = function(){
		
		this.keyword1 = $('#keyword1').val();
		this.keyword2 = $('#keyword2').val();
		this.profileService.getVendors(this.selectedStorage.id,this.keyword1,this.keyword2).subscribe(response => {
              this.vendorList = response['vendorlist'];
			}
		);
	}
	getschedule_format = function(){
		this.profileService.getschedule_format().subscribe(response => {
			  this.getSchedulerList = response.scheduler_format;
			}
		);
	}
	
	loadEmployeeTaxData = function (){
		this.profileService.getEmployeesTax(this.selectedStorage.id).subscribe((response: any) =>{
		  if(response.employeetax.length > 0){
			this.employeeTaxData = response.employeetax;
		  }
		  else{
			this.employeeTaxData = '';
		  }
		});
	  }
	
	
	addEmployee(){
		this.submitted = false; 
	}
		

	department_form = new FormGroup({
		id: new FormControl(''),
		name: new FormControl('', [Validators.required]),
		color_code: new FormControl(this.depColor, [Validators.required]),
	});
	departmentsch_form = new FormGroup({
		id: new FormControl(''),
		day_format: new FormControl('', [Validators.required]),
		interval: new FormControl('', [Validators.required]),
	});

	category_form = new FormGroup({
		id: new FormControl(''),
		category_name: new FormControl('', [Validators.required]),
		status: new FormControl('', [Validators.required]),
	});
	  
	get department_f(){
		return this.department_form.controls;
	}
	get department_f_s(){
		return this.departmentsch_form.controls;
	}
	get category_f(){
		return this.category_form.controls;
	}
	  
	department_submit(){
		this.departmentSubmitted = true;

		if(this.department_form.invalid){
			return false;
		}
		this.profileService.createDepartment(this.selectedStorage.id,this.department_form.value.name,this.department_form.value.color_code,this.department_form.value.id).subscribe((response: any) =>{
			if(response.status == 1){
				//this.departmentSuccess = response.message;
				this.getDepartment();
				this.departmentSubmitted = false;
				this.department_form.controls['name'].setValue('');
				let element:HTMLElement = document.getElementById('closeDept') as HTMLElement;
				element.click();
				
			}
		});
	}
	departmentsch_submit(){
		this.departmentschSubmitted = true;

		if(this.departmentsch_form.invalid){
			return false;
		}
		this.profileService.createDepartmentsch(this.selectedStorage.id,this.departmentsch_form.value.day_format,this.departmentsch_form.value.interval,this.departmentsch_form.value.id).subscribe((response: any) =>{
			if(response.status == 1){
				//this.departmentSuccess = response.message;
				this.getschedule_format();
				this.departmentschSubmitted = false;
				this.departmentsch_form.controls['day_format'].setValue('');
				this.departmentsch_form.controls['interval'].setValue('');
				let element:HTMLElement = document.getElementById('closeDept1') as HTMLElement;
				element.click();
				
			}
		});
	}
	category_submit(){
		this.categorySubmitted = true;
		if(this.category_form.invalid){
			return false;
		}
		this.profileService.createcategory(this.selectedStorage.id,this.category_form.value.category_name,this.category_form.value.status,this.category_form.value.id).subscribe((response: any) =>{
			if(response.status == 1){
				//this.departmentSuccess = response.message;
				this.getCategories();
				this.categorySubmitted = false;
				this.category_form.controls['category_name'].setValue('');
				this.category_form.controls['status'].setValue('');
				let element:HTMLElement = document.getElementById('closeCat') as HTMLElement;
				element.click();
				
			}
		});
	}
	selectColor(event){
		const color = event.target.value;
		$('.cstm_color').attr("style", "background: #"+color+" !important");
		
	}

	getDepartment(){
		this.profileService.getDepartments(this.selectedStorage.id).subscribe((response: any) =>{
			this.departmentList = response.department;
		});
	}
	getCategories(){
		this.profileService.getcatData(this.selectedStorage.id).subscribe((response: any) =>{
			this.categoryList = response.categories;
		});
	}

	loadDepHeader()
	{
		this.depTableCols = [
			{ field: 'name', header: 'Name'},
			{ field: 'created_at', header: 'CREATED'},
			{ field: 'color_code', header: 'COLOR CODE'}
		];	
	}

	selectColorCode(color_code){
		this.department_form.controls['color_code'].setValue(color_code);
		this.depColor = color_code;
	}

	add_user_form = new FormGroup({
		id : new FormControl(''),
		first_name: new FormControl('', [Validators.required]),
		last_name: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required, Validators.email]),
		mobile_no: new FormControl('', [Validators.required, Validators.minLength(8)]),
		role: new FormControl('', Validators.required),
		role_vendor: new FormControl(''),
		storesmul: new FormControl('', Validators.required)
	});
	add_merge_vendor_form = new FormGroup({
		merge_v_from: new FormControl(this.vendor_merge_one['id']),
		merge_v_to: new FormControl('', [Validators.required])
		
	});

	add_costs_form = new FormGroup({
		id : new FormControl(''),
		cof_retail: new FormControl('', [Validators.required]),
		cof_wholesale: new FormControl('', [Validators.required]),
		cof_wire: new FormControl('', [Validators.required]),
		cof_weddings: new FormControl('', [Validators.required]),
		cof_payout: new FormControl('', [Validators.required]),
		cog_retail: new FormControl('', [Validators.required]),
		cog_wire: new FormControl('', [Validators.required]),
		cog_weddings: new FormControl('', [Validators.required]),
		cog_wholesale: new FormControl('', [Validators.required]),
		cog_payout: new FormControl('', [Validators.required]),
		labor: new FormControl('', [Validators.required]),
		
	});
	edit_vendor_form = new FormGroup({
		id : new FormControl(''),
		vendor_id : new FormControl(''),
		vendor_name: new FormControl('', [Validators.required]),
		category: new FormControl(''),
		account: new FormControl(''),
		contact_person: new FormControl(''),
		contact_number:new FormControl(''),
		alt_contact_number:new FormControl(''),
		addressline1: new FormControl(''),
		addressline2: new FormControl(''),
		city: new FormControl(''),
		state: new FormControl(''),
		zip: new FormControl(''),
		vendorlogin: new FormControl(''),
		vendornotes: new FormControl(''),
		status: new FormControl(''),
		//status: new FormControl('', [Validators.required]),
		
	});
	  
	get add_user_f(){
		return this.add_user_form.controls;
	}
	get add_cost_f(){
		return this.add_costs_form.controls;
	}
	get add_vendor_f(){
		return this.edit_vendor_form.controls;
	}

	get merge_vendor_f(){
		return this.add_merge_vendor_form.controls;
	}
	  
	edit_vendor_submit(){
		this.edit_vendor_submitted = true;
		if(this.edit_vendor_form.invalid){
			this.Userloading = false;
			return;
		}
		this.vendoralready='';
		this.Userloading = true;
		const vendor_name = this.edit_vendor_form.value.vendor_name;
		const vendor_id = this.edit_vendor_form.value.vendor_id;
		const status = this.edit_vendor_form.value.status;

		const category = this.edit_vendor_form.value.category;
		const account = this.edit_vendor_form.value.account;
		const contact_person = this.edit_vendor_form.value.contact_person;
		const contact_number = this.edit_vendor_form.value.contact_number;
		const alt_contact_number = this.edit_vendor_form.value.alt_contact_number;
		const addressline1 = this.edit_vendor_form.value.addressline1;
		const addressline2 = this.edit_vendor_form.value.addressline2;
		const city = this.edit_vendor_form.value.city;
		const state = this.edit_vendor_form.value.state;
		const zip = this.edit_vendor_form.value.zip;
		const vendorlogin = this.edit_vendor_form.value.vendorlogin;
		const vendornotes = this.edit_vendor_form.value.vendornotes;

		this.profileService.updatevendor(this.selectedStorage.id,vendor_id, vendor_name,status,category,account,contact_person,contact_number,alt_contact_number,addressline1,addressline2,city,state,zip,vendorlogin,vendornotes).subscribe((response: any) =>{
			this.Userloading = false;
			if(response.status == 1){
				this.messageToastService.sendMessage('success','Success','Vendor Updated !');
				this.getVendors1();
				let element:HTMLElement = document.getElementById('closevrndorModal') as HTMLElement;
				element.click();
			}
			if(response.status == 2){
				this.vendoralready = response['msg'];
			}
			else{
			}
			});
		
	}
	merge_vendor_submit()
	{
		this.merge_vendor_submitted = true;
		if(this.add_merge_vendor_form.invalid){
			this.MergeVendorloading = false;
			return;
		}
		this.MergeVendorloading = true;
		const merge_v_from = this.add_merge_vendor_form.value.merge_v_from;
		const merge_v_to = this.add_merge_vendor_form.value.merge_v_to;
		
		this.profileService.merge_vendor(merge_v_from,merge_v_to).subscribe((response: any) =>{
			this.MergeVendorloading = false;
			
			if(response.status == 1){
				this.getVendors1();
				let element:HTMLElement = document.getElementById('closeMergeModal') as HTMLElement;
				element.click();
			}
			
		});
		
	}
	add_user_submit(){
		this.add_user_submitted = true;
		if(this.add_user_form.invalid){
			this.Userloading = false;
			return;
		}

		this.Userloading = true;

		const email = this.add_user_form.value.email;
		const id = this.add_user_form.value.id;
		const first_name = this.add_user_form.value.first_name;
		const last_name = this.add_user_form.value.last_name;
		const mobile_no = this.add_user_form.value.mobile_no;
		const role_id = this.add_user_form.value.role;
		const role_vendor = this.add_user_form.value.role_vendor;
		const storesmul = this.add_user_form.value.storesmul;


		this.profileService.createUser(this.addedById,email, first_name,last_name,mobile_no,role_id,this.selectedStorage.id,id,role_vendor,storesmul).subscribe((response: any) =>{
			this.Userloading = false;
			
			if(response.status == 1){
				this.getUserList();
				let element:HTMLElement = document.getElementById('closeUserModal') as HTMLElement;
				element.click();
			}
			else{
				this.emailError = response.validationErrors.email;
			}
		});
	}
	
	getStorePercent(){
		this.profileService.getStoresPercent(this.selectedStorage.id).subscribe((response: any) =>{
			
			if(response.status == 'success')
			{
				this.cof_retailget = response.data.cof_retail;
				this.cof_wireget = response.data.cof_wire;
				this.cof_weddingsget = response.data.cof_weddings;
				this.cof_wholesaleget = response.data.cof_wholesale;
				this.cof_payoutget = response.data.cof_payout;
				this.cog_retailget = response.data.cog_retail;
				this.laborget = response.data.labor;
				this.cog_wireget = response.data.cog_wire;
				this.cog_weddingsget = response.data.cog_weddings;
				this.cog_wholesaleget = response.data.cog_wholesale;
				this.cog_payoutget = response.data.cog_payout;
			}
		});
	}
	
	add_costs(){
		this.add_cost_submitted = true;
		if(this.add_costs_form.invalid){
			this.loadingRoleForm = false;
			return;
		}

		this.loadingRoleForm = true;
	    //const id = this.add_costs_form.value.id;
		const cof_retail = this.add_costs_form.value.cof_retail;
		const cof_wholesale = this.add_costs_form.value.cof_wholesale;
		const cof_payout = this.add_costs_form.value.cof_payout;
		const cof_wire = this.add_costs_form.value.cof_wire;
		const cof_weddings = this.add_costs_form.value.cof_weddings;
		const cog_retail = this.add_costs_form.value.cog_retail;
		const cog_wire = this.add_costs_form.value.cog_wire;
		const cog_weddings = this.add_costs_form.value.cog_weddings;
		const cog_wholesale = this.add_costs_form.value.cog_wholesale;
		const cog_payout = this.add_costs_form.value.cog_payout;
		const labor = this.add_costs_form.value.labor;
		
		this.profileService.create_store_percent(this.selectedStorage.id,cof_retail,cof_wire,cof_weddings,cof_wholesale,cof_payout,cog_retail,cog_wire,cog_weddings,cog_wholesale,cog_payout,labor).subscribe((response: any) =>{
			this.loadingRoleForm = false;
			//this.messageToastService.sendMessage('success','Success','Calculations Updated !');
			if(response.status == 'success'){
				this.messageToastService.sendMessage('success','Success','Calculations Updated !');
				this.getStorePercent();
				//let element:HTMLElement = document.getElementById('closeUserModal') as HTMLElement;
				//element.click();
			}
			
		});
	}

	addUserModal(){
		this.add_user_submitted = false;
		this.add_user_form.reset();
		this.add_user_form.get("role").setValue("");
	}

	emailEnter(){
		this.emailError = '';
		//this.emailError1 = '';
	}

	getUserList(){
		this.profileService.getUserList(this.selectedStorage.id).subscribe((response: any) =>{
			this.loading = false;
			this.Userloading = false;
			this.pageLoaded = true;
			if(response.status == 1){
				this.userList = response.users;
			}
		});
	}
	getuserStores(){
		this.profileService.getuserStores().subscribe((response: any) =>{
			this.Stores = response.data;
		});
	}

	loadUserHeaders()
	{
	  this.userTableCols = [
		{ field: 'first_name', header: 'First Name' },
		{ field: 'last_name', header: 'Last Name' },
		{ field: 'email', header: 'Email' },
		{ field: 'mobile_no', header: 'Phone Number' },
		{ field: 'role_name', header: 'Role' },
		{ field: 'store_names', header: 'Stores' },
	  ];	
	}
	loadTaxHeaders()
	{
	  this.employeeTableTaxCols = [
		{ field: 'tax_name', header: 'Name' },
		{ field: 'tax_value', header: 'Rate' },
	  ];	
	}
	
	loadModules(){
        const currentUser = this.authenticationService.currentUserValue;
        if(currentUser)
        {
            return this.planService.getByUser(currentUser.user_id).subscribe((data: any) =>{
				const newModulesArr = data.modules;
				const profileEntry = {
					id: Number(data.modules.length)+1,
					name: "Profile",
					action: 'profile'
				}

				newModulesArr.push(profileEntry);
				this.modules = data.modules;
				const emptyArray = []
				for(let i = 0; i < data.modules.length; i++){
					this.permissions.removeAt(i)
					const control = 
						new FormGroup({
							id: new FormControl(data.modules[i].id),
							name: new FormControl(),
							slug: new FormControl(),
							view: new FormControl(''),
							add: new FormControl(''),
							edit: new FormControl(''),
							delete: new FormControl('')
						});							
					this.permissions.push(control);
					this.viewChecked[i] = '';
				}
            });
        }
	}
	
	add_role_form = new FormGroup({
		id: new FormControl(''),
		name: new FormControl('', [Validators.required]),
		permissions: new FormArray([
			new FormGroup({
				id: new FormControl(''),
				name: new FormControl(''),
				slug: new FormControl(''),
				view: new FormControl(''),
				add: new FormControl(''),
				edit: new FormControl(''),
				delete: new FormControl('')
			})
		])
	});

	permissions = this.add_role_form.get('permissions') as FormArray;

	get add_role_f(){
		return this.add_role_form.controls;
	}
	  
	add_role_submit(){
		this.loadingRoleForm = true;
		this.addRoleSubmitted = true;
		if(this.add_role_form.invalid){
			this.loadingRoleForm = false;
			return;
		}

		this.profileService.createUserRole(this.selectedStorage.id,this.add_role_form.value).subscribe((response: any) =>{
			this.loadingRoleForm = false;
			if(response.status == 1){
				this.getUserRoleList();
				let element:HTMLElement = document.getElementById('closeRoleModal') as HTMLElement;
				element.click();
			}
		});	
		
	}

	viewCheck(event,viewIndex){
		const check = event.target.checked;

		this.viewChecked[viewIndex] = check;
	}
	createRoleModal(){
		this.roleData = [];
		this.loadingRoleForm = false;
		this.addRoleSubmitted = false;
		this.add_role_form.reset();
		this.loadModules();
		let element:HTMLElement = document.getElementById('closeRoleModal') as HTMLElement;
				element.click();
	}

	getUserRoleList(){
		this.profileService.getUserRole(this.selectedStorage.id).subscribe((response: any) =>{
			if(response.status == 1){
				this.UserRoleList = response.result.data;
			}
		});
	}

	loadRoleHeaders()
	{
		this.roleTableCols = [
			{ field: 'name', header: 'Name'},
			{ field: 'created_at', header: 'CREATED'},
		];	
	}

	checkPremium(store_id){
		const premiumPackage = this.authGuard.premiumPackage(store_id);
		
		if(!premiumPackage){
		  this.premiumPlan = false;
		}
		else{
		  this.premiumPlan = true;
		}
	}
	permissionError(){
		Swal.fire({
			title: 'This feature is limited to Premium stores only',
			allowOutsideClick: true,
		  }).then((result) => {
			if (result.isConfirmed) {
			  //this.router.navigate(['/home']);
			}
		}); 
	}

	

	checkUserPermission(type){
		const hasPermission = this.userPermission;
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if(currentUser.isAdmin){
			return true;
		}
    	else if (typeof hasPermission === "undefined")
		{
			return true;
		}
		else if (hasPermission.includes(type))
		{
			return true;
		}
		return false; 
	}

	deletetaxConfirm(id){
		//this.profileService.deleteemployeeTax(id)
		//return false;
		Swal.fire({
			title: 'Are you sure want to remove?',
			text: 'You will not be able to recover this tax!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, keep it'
		  }).then((result) => {
			if (result.value) {
				this.profileService.deleteemployeeTax(id).subscribe((response: any) =>{
				this.messageToastService.sendMessage('success','Tax','Tax was deleted !');
				this.loadEmployeeTaxData();
			  });
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		  })
	  
	  }

	  editTax(id){
		this.profileService.getEmployeesTaxById(id).subscribe((response: any) =>{
			this.tax_form_data =response.data;
		});
	  }

		
		
	
	editVendor(id){
			this.profileService.getvendorbyid(id).subscribe((response: any) =>{
				this.vendor_form_data =response.data;
				
			});
		}
		deleteEmp(id){
			Swal.fire({
				title: 'Are you sure want to remove?',
				text: 'You will not be able to recover this employee!',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'No, keep it'
			  }).then((result) => {
				if (result.value) {
					this.profileService.deleteemployee(id).subscribe((response: any) =>{
						this.messageToastService.sendMessage('success','Employee','Employee was deleted !');
						this.loadEmployeeData();
				  	});
				} else if (result.dismiss === Swal.DismissReason.cancel) {
				}
			  })
		}

		editUser(id){
			this.profileService.getUserById(id).subscribe((response: any) =>{
				this.editUserData = response.user;
				this.add_user_form.controls['id'].setValue(this.editUserData['user_id']);
				this.add_user_form.controls['first_name'].setValue(this.editUserData['first_name']);
				this.add_user_form.controls['last_name'].setValue(this.editUserData['last_name']);
				this.add_user_form.controls['email'].setValue(this.editUserData['email']);
				this.add_user_form.controls['mobile_no'].setValue(this.editUserData['mobile_no']);
				this.add_user_form.controls['role'].setValue(this.editUserData['role_id']);
				this.add_user_form.controls['role_vendor'].setValue(this.editUserData['role_vendor']);
				this.selectedItems = response.stores;
			});
		}
		deleteUser(id){
			Swal.fire({
				title: 'Are you sure want to remove?',
				text: 'You will not be able to recover this user!',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'No, keep it'
			  }).then((result) => {
				if (result.value) {
					this.profileService.deleteUser(id).subscribe((response: any) =>{
						this.messageToastService.sendMessage('success','User','User was deleted !');
						this.getUserList();
				  	});
				} else if (result.dismiss === Swal.DismissReason.cancel) {
				}
			  })
		}

		editRole(id){
			this.store_role_id = '';
			this.store_role_name = '';
			this.profileService.getRole(id).subscribe((response: any) =>{
				this.roleData =  response.data;
				this.store_role_id = response.store_role['id'];
				this.store_role_name = response.store_role['name'];
			});
		}

		deleteRole(id){
			Swal.fire({
				title: 'Are you sure want to remove?',
				text: 'You will not be able to recover this role!',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'No, keep it'
			  }).then((result) => {
				if (result.value) {
					this.profileService.deleteRole(id).subscribe((response: any) =>{
						this.messageToastService.sendMessage('success','Role','Role was deleted !');
						this.getUserRoleList();
				  	});
				} else if (result.dismiss === Swal.DismissReason.cancel) {
				}
			  })
			
		}

		editDept(id){
			this.profileService.getDepartmentSingle(id).subscribe((response: any) =>{
				if(response.department){
					this.depColor = response.department['color_code'];
					this.department_form.controls['id'].setValue(response.department['id']);
					this.department_form.controls['name'].setValue(response.department['name']);
					this.department_form.controls['color_code'].setValue(response.department['color_code']);
				}
				
			});
		}
		editDepts(id){
			this.profileService.getDepartmentschSingle(id).subscribe((response: any) =>{
				if(response.timeformat){
					this.departmentsch_form.controls['id'].setValue(response.timeformat['id']);
					this.departmentsch_form.controls['day_format'].setValue(response.timeformat['day_format']);
					this.departmentsch_form.controls['interval'].setValue(response.timeformat['interval']);
				}
				
			});
		}
		editCat(id){
			this.categorylabel="Edit Category";
			this.profileService.getcatSingle(id).subscribe((response: any) =>{
				if(response.categories){
					this.categorylabel="Edit Category";
					this.category_form.controls['id'].setValue(response.categories['id']);
					this.category_form.controls['category_name'].setValue(response.categories['category_name']);
					this.category_form.controls['status'].setValue(response.categories['status']);
				}
				
			});
		}
		addDept(){
			this.department_form.controls['id'].setValue('');
			this.department_form.controls['name'].setValue('');
		}

		addCat(){
			this.categorylabel="Add New Category";
			this.category_form.controls['id'].setValue('');
			this.category_form.controls['category_name'].setValue('');
			this.category_form.controls['status'].setValue('');
		}

		deleteDept(id){
			Swal.fire({
				title: 'Are you sure want to remove?',
				text: 'You will not be able to recover this department!',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'No, keep it'
			  }).then((result) => {
				if (result.value) {
					this.profileService.deleteDepartment(id).subscribe((response: any) =>{
						if(response.status){
							this.messageToastService.sendMessage('success','Department','Department was deleted !');
							this.getDepartment();
						}
						else{
							this.messageToastService.sendMessage('error','Department',"This Department can't be deleted !");
						}
				  	});
				} else if (result.dismiss === Swal.DismissReason.cancel) {
				}
			  })
		}
		checkString(string,substring){
			if(typeof string !== "undefined"){
				if(string.includes(substring)){
					return true;
				}
			}
			return false;
		}
		ngAfterViewInit() {
			this.isLoading = false;
		}

		removeSlash(string){
			return string.replace(/\//g, "").replace('cost-of','');
		}

		cleanvendor()
		{
			this.edit_vendor_form.reset();
		}
		addVendor()
		{
			this.cleanvendor();
		}
		mergeVendor(id){
			this.profileService.getmergebyid(id).subscribe((response: any) =>{
				this.vendor_merge_one =response.data;
				this.vendor_merge_two =response.assign_vendor;
				if (this.vendor_merge_two.length > 0) {
					this.add_merge_vendor_form = new FormGroup({
						merge_v_from : new FormControl(this.vendor_merge_one['id']),
						merge_v_to: new FormControl(this.vendor_merge_two[0]['id'])
					});
				} else {
					// Handle the case where vendor_merge_two is empty
				}
			});
		}
		resizeTextarea(event): void {
			const textarea = event.target;
			textarea.style.height = 'auto'; // Reset the height to auto
			textarea.style.height = `${textarea.scrollHeight}px`; // Set the new height
		  }
		  get f() { return this.employeeForm.controls; }

		  //new code merge employee data

		  onSubmit() {
			this.submitted = true;
			if (this.employeeForm.invalid) {
				return;
			}
			this.profileService.createEmployee(this.selectedStorage.id, this.f.first_name.value, this.f.last_name.value, this.f.email.value, this.f.status.value, this.f.employee_type.value, this.f.employee_value.value, this.f.phone_number.value, this.f.id.value, this.f.storesmultiple.value, this.selectedValues, this.f.total_value_tax.value)
				.pipe()
				.subscribe(
					data => {
						let element: HTMLElement = document.getElementById('closeEmpModal') as HTMLElement;
						element.click();
						this.messageToastService.sendMessage('success', 'Employee Message', 'Employee was created!');
						this.loadEmployeeData();
						this.submitted = false;
						this.adduserEvent.emit('true');
						$('.employee_valuedev').hide();
					},
					error => {
						this.error = error;
						//this.loading = false;
					});
		}
	
	
		onOptionsSelected(value: string) {
			if (value != "") {
				$('.employee_valuedev').show();
			}
			else {
				$('.employee_valuedev').hide();
			}
		}
		
	
		onSelectChange(event: Event) {
			this.employee_tax_value = false;
			const target = event.target as HTMLSelectElement;
			const selectedValue = target.value;
			const selectedText = target.options[target.selectedIndex].text;
			const selectedTaxValue = parseFloat(target.options[target.selectedIndex].getAttribute('data-tax-value'));
			const employeeValue = parseFloat(this.f.employee_value.value);
			
			const isAlreadySelected = this.selectedValues.some(item => item.value === selectedValue);
			if (!isAlreadySelected) {
				const percentage = this.calculatePercentage(employeeValue, selectedTaxValue); // Calculate the percentage
				const option = { value: selectedValue, text: selectedText, taxValue: selectedTaxValue, percentage: percentage };
				
				this.selectedValues.push(option);
				this.runningTotal += percentage;
				this.optionInputValue = selectedValue;
				this.total_value_tax = (employeeValue + this.runningTotal).toFixed(2);
				$('.compensec').show();
	
			}
		}
		calculatePercentage(employeeValue: number, selectedTaxValue: number): number {
			return (selectedTaxValue / 100) * employeeValue;
		}
	
		removeItem(index: number) {
			this.selectedValues.splice(index, 1);
			this.recalculateTotalValueTax();
		}
		recalculateTotalValueTax() {
			
			const employeeValue = parseFloat(this.f.employee_value.value);
			this.runningTotal = 0;
			for (const option of this.selectedValues) {
				this.runningTotal += option.percentage;
			}

			this.total_value_tax = (employeeValue + this.runningTotal).toFixed(2);
			
		}
	
		onEmployeeValueChange() {
			$('.compensec').show();
			const employeeValue = parseFloat(this.f.employee_value.value);
			this.selectedValues.forEach(option => {
				option.percentage = this.calculatePercentage(employeeValue, option.taxValue);
			});
	
			this.runningTotal = this.selectedValues.reduce((total, option) => total + option.percentage, 0);
			this.total_value_tax = (employeeValue + this.runningTotal).toFixed(2);
		}

		editEmployee(id){
			$('.employee_valuedev').show();
			$('.compensec').show();
			let json = [];
			this.profileService.getempbyid(id).subscribe((response: any) =>{
				this.runningTotal =  response.data['employee_tax_value'] - response.data['employee_value'];
				this.total_value_tax = response.data['employee_tax_value'];
				this.employeeForm.controls['id'].setValue(response.data['id']);
				this.employeeForm.controls['first_name'].setValue(response.data['first_name']);
				this.employeeForm.controls['last_name'].setValue(response.data['last_name']);
				this.employeeForm.controls['email'].setValue(response.data['email']);
				this.employeeForm.controls['phone_number'].setValue(response.data['phone_number']);
				this.employeeForm.controls['employee_type'].setValue(response.data['employee_type']);
				this.employeeForm.controls['employee_value'].setValue(response.data['employee_value']);
				this.employeeForm.controls['status'].setValue(response.data['status']);
				this.selectedItemsemp = response.storeSubs;
				json = response.employee_tax;
				this.selectedValues = json;
			});
		}
}
