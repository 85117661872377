import { Component, OnInit, ViewChild } from '@angular/core';
import { StoreSubscriberService } from "../_services/storeSubscriber.service";
import { UtilsService } from "../_services/utils.service";
import { InventoryService } from "../_services/inventory.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {first} from "rxjs/operators";
import * as $ from 'jquery' 
declare  var jQuery:  any;
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AuthGuard } from "../_helpers/auth.guard";
import { Router } from '@angular/router';
class ImageSnippet {
  pending: boolean = false;
  status: string = 'init';

  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.less'],
})
export class InventoryComponent implements OnInit {
	@ViewChild('closeInvModal') closeInvModal;
	@ViewChild('closeVendorModal') closeVendorModal;
	checkedItemList : any;
	p_names:any;
	hideEdit : "";
	p_descs:any;
	store_ID:any;
	casesbtn: boolean = false;
	at_oneselect: boolean = false;
	vendornameval: boolean = false;
	categorynameval: boolean = false;
	imageUrl: string = '';
	dialogValues : any;
	role_vendor : any;
	total_cost: any;
	markupcal:any;
	vendoralready:any;
	vendorsubmitted = false;
	vendorloading = false;
	selectedFile: ImageSnippet;
	submittedFormItem: boolean = false;
	itemloading: boolean = false;
	isMasterSel: boolean = false;
	itemform: FormGroup;
	vendorModelForm: FormGroup;
	vendorList = [];
	categoryList = [];
	itemerror:'';
	storename: string;
	invModalTitle: string;
	keyword1: string;
	keyword2: string;
	keyword3: string;
	low_threshold: number;
	total_value:number
	t_cases: number;
	t_loose_items: number;
	selectedStorage: any;
	singleItem: any;
	inventoryTableCols: any[];
	cols: any[];
	inventoryData: any[];
	premiumPlan = false;
	userPermission = [];
	loading = true;
  constructor(
	private formBuilder: FormBuilder,
	private storeSubscriberService: StoreSubscriberService,
	private utilService: UtilsService,
	private inventoryService: InventoryService,
	private authGuard : AuthGuard,
	private router: Router,
  ) { 
	this.authGuard.userPermissions();   
	this.selectedFile = new ImageSnippet('', null);
	this.low_threshold = 0;
	this.total_value = 0;
	storeSubscriberService.subscribe(this,function (ref,store) {
      ref.receiveStorage(store);
    });
    let currentYear = this.utilService.GetCurrentYear();
	this.dialogValues = {'id':0,'product_name': '','vendor_name': '','category_name': '','unit_price': '','freight':'','product_code': '','case_pack': '','markup': '','description': '','srp':'','low_threshold': '', 'image':''};
  }

  ngOnInit() {
	 const permissions = this.authGuard.userPermissions();
    this.userPermission = permissions;
	document.body.classList.remove('loginbody');
	this.role_vendor = localStorage.getItem('role_vendor');
	this.invModalTitle = "Add New inventory Item";
	this.selectedStorage = JSON.parse(localStorage.getItem('selectedStorage'));
	this.checkPremium(this.selectedStorage.id);
	this.storename = this.selectedStorage.store_name;
	
	this.itemform = this.formBuilder.group({
		item_id: ['', Validators.required],
		product_name: ['', Validators.required],
		vendor_name: ['', Validators.required],
		category_name: ['', Validators.required],
		unit_price: ['', Validators.required],
		freight: [''],
		product_code: [''],
		case_pack: [''],
		description: [''],
		suggestedretailprice:[''],
		markup: ['', Validators.required],
  		srp: [''],
		low_threshold: [''],
	});

	this.vendorModelForm = this.formBuilder.group({
		v_name: ['', Validators.required],
		category: ['', Validators.required],
		account: ['', Validators.required],
		contact_person: ['', Validators.required],
		contact_number: ['', Validators.required],
		alt_contact_number: [''],
		addressline1: ['', Validators.required],
		addressline2: [''],
		city: ['', Validators.required],
		state: ['', Validators.required],
		zip: ['', Validators.required],
		vendorlogin: ['', Validators.required],
		vendornotes: ['', Validators.required],
		
	})
	this.loadHeaders();
	this.getVendors();
	this.getCategories();
	this.loadInventoryData();
	if(!this.premiumPlan){
		document.body.classList.add('loginbody');
	}
  }
	filterEvent(event)
	{
		if(event.keyCode == 13)
		{
			this.loadInventoryData();
		}
	}

	receiveStorage(storage){
		this.selectedStorage = storage;
		this.storename = this.selectedStorage.store_name;
		this.checkPremium(this.selectedStorage.id);
		this.loadHeaders();
		this.loadInventoryData();
	}
	loadInventoryData = function (){
		
		this.store_ID = localStorage.getItem('store_id');
		this.keyword1 = $('#keyword1').val();
		this.keyword2 = $('#keyword2').val();
		this.keyword3 = $('#keyword3').val();
		this.loading = false;
		//this.loading = true;
		this.inventoryService.getInventory(this.selectedStorage.id,this.keyword1,this.keyword2,this.keyword3).subscribe((response: any) =>{
			this.loading = false;
			this.total_value = response.total_value
			this.inventoryData = response.products;
			this.low_threshold = response.low_threshold;
		});
	}
	
	clearFilter()
	{
		$('#keyword1').val('');
		$('#keyword2').val('');
		$('#keyword3').val('');
		this.loadInventoryData();
	}
  loadHeaders()
  {
	this.inventoryTableCols = [
      { field: 'product_name', header: 'Product', width:'140' },
      { field: 'vendor_name', header: 'Vendor', width:'80' },
      { field: 'category_name', header: 'Category', width:'' },
      { field: 'product_code', header: 'Product #', width:'100' },
	  { field: 'case_pack', header: 'Case Pack', width:'60' },
      { field: 'cases', header: 'Cases', width:'60' },
      { field: 'loose', header: 'Loose', width:'60' },
      { field: 'qty', header: 'Qty', width:'' },
      { field: 'updated_att', header: 'Last Updated', width:'100' },
	  { field: 'unit', header: 'Cost Per Piece Incl Freight ', width:'120' },
      { field: 'value', header: 'Value', width:'' },
      { field: 'srp', header: 'Retail Price', width:'' },
	  
      
    ];	
  }
  
  get f() { return this.itemform.controls; }
  get vendors() { return this.vendorModelForm.controls; }
	
  keyUpFunction(event)
  {
	if(event.keyCode == 13)
	{
		this.onSubmit();
	}
  }
  opendescpopup(imageUrl: string,product_name:string,product_desc:string)
  {
	this.imageUrl = imageUrl;
	this.p_names = product_name;
	this.p_descs = product_desc;
	
  }
  openimgpopup(imageUrl: string)
  {
	this.imageUrl = imageUrl;
  }
	showEditInventoryItem(arrKey:number) {
		for(var i = 0; i < this.inventoryData.length; i++)
		{
			if(this.inventoryData[i].id == arrKey)
			{
				var arrKeyNum = i;
			}
		}
		this.invModalTitle = "Edit inventory Item";
		this.singleItem = this.inventoryData[arrKeyNum];
		this.dialogValues.id = this.singleItem.id;
		this.dialogValues.product_name = this.singleItem.product_name;
		this.dialogValues.product_code = this.singleItem.product_code;
		this.dialogValues.vendor_name = this.singleItem.vendor_id;
		this.dialogValues.category_name = this.singleItem.category_id;
		this.dialogValues.unit_price = this.singleItem.unit_price;
		this.dialogValues.markup = this.singleItem.mark_up;
		if(this.singleItem.mark_up!=null)
		{
			this.markupcal = this.singleItem.srp;
		}
		
		this.dialogValues.case_pack = this.singleItem.case_pack;
		this.dialogValues.description = this.singleItem.description;
		this.dialogValues.freight = this.singleItem.freight;
		this.dialogValues.srp = this.singleItem.srp;
		this.dialogValues.low_threshold = this.singleItem.low_threshold;
		this.dialogValues.image = this.singleItem.image;
		//$('#editaddinventory').click();
	}
	onSelectChange(selectedValue: string)
	{
		var unitPrice = parseFloat(this.dialogValues.unit_price);
		var FFreight = this.dialogValues.freight;
		if(FFreight =='' )
		 FFreight = 0;    
		var freight = parseFloat(FFreight);
		var markup = parseFloat(selectedValue);

		var total_cost = unitPrice + (freight / 100) * unitPrice;
		this.markupcal = markup*total_cost;
		this.markupcal = this.markupcal.toFixed(2);
		
		
	}
	addvendor()
	{
		this.closeInvModal.nativeElement.click();
		this.cleanvendor();
	}
	
	onSubmit() {
        this.submittedFormItem = true; 
		if(this.f.vendor_name.value=='')
		{
			this.vendornameval = true;
			this.itemloading = false;
			return;
		}
		if(this.f.category_name.value=='')
		{
			this.vendornameval = false;
			this.categorynameval = true;
			this.itemloading = false;
			return;
		}

        // stop here if form is invalid
        if (this.itemform.invalid) {
		    this.itemloading = false;
			 return;
        }
		
		
		if(this.f.markup.value=='' && this.f.srp.value=='')
		{
			this.itemloading = false;
			return;
		}
		
		this.vendornameval = false;
		this.categorynameval = false;
        this.itemloading = true;
        this.inventoryService.postinventory(this.selectedStorage.id, this.f.product_name.value, this.f.product_code.value, this.f.vendor_name.value, this.f.category_name.value, this.f.unit_price.value, this.f.markup.value, this.f.case_pack.value, this.f.description.value, this.f.freight.value, this.f.srp.value, this.f.low_threshold.value,this.selectedFile.file,this.f.item_id.value)
		.pipe(first())
		.subscribe(
		data => {
			this.loadInventoryData();
			this.itemloading = false;
			this.clean();
			this.closeInvModal.nativeElement.click();
		},
		error => {
			this.itemerror = error;
			this.itemloading = false;
		});
    }

	vendorSubmit() {
		
        this.vendorsubmitted = true; 
		
        if (this.vendorModelForm.invalid) {
            this.vendorloading = false;
            return;
        }
		
		this.vendoralready='';
		
        this.vendorloading = true;
		this.inventoryService.create_vendornew(this.selectedStorage.id, this.vendors.v_name.value, this.vendors.category.value, this.vendors.account.value, this.vendors.contact_person.value, this.vendors.contact_number.value, this.vendors.alt_contact_number.value, this.vendors.addressline1.value, this.vendors.addressline2.value, this.vendors.city.value, this.vendors.state.value, this.vendors.zip.value, this.vendors.vendorlogin.value, this.vendors.vendornotes.value)
        .pipe(first())
        .subscribe(
            data => {
				if(data['status']=='false')
				{
						this.vendoralready = data['msg'];
						this.vendorloading = false;
						this.vendorsubmitted = false;
						
				}
				else
				{
				this.vendoralready ="";
				this.cleanvendor();
				this.getVendors();
				this.closeInvModal.nativeElement.click();
				this.closeVendorModal.nativeElement.click();
				this.vendorloading = false;
				this.vendorsubmitted = false;
				}
			},
            error => {
			  
              this.vendorloading = false;
		});
    }
	cleanvendor()
	{
	 this.vendors.v_name.setValue('');
     this.vendors.category.setValue('');
     this.vendors.account.setValue('');
     this.vendors.contact_person.setValue('');
     this.vendors.contact_number.setValue('');
     this.vendors.alt_contact_number.setValue('');
     this.vendors.addressline1.setValue('');
	 this.vendors.addressline2.setValue('');
	 this.vendors.city.setValue('');
	 this.vendors.state.setValue('');
	 this.vendors.zip.setValue('');
	 this.vendors.vendorlogin.setValue('');
	 this.vendors.vendornotes.setValue('');
     
	}
	
	clean(){
        this.submittedFormItem = false; 
		this.categorynameval = false;
		this.invModalTitle = "Add New inventory Item";
		this.selectedFile = new ImageSnippet('', null);
		this.f.item_id.setValue(0);
		this.f.product_name.setValue('');
		this.f.product_code.setValue('');
		this.f.vendor_name.setValue('');
		this.f.category_name.setValue('');
		this.f.unit_price.setValue('');
		this.f.markup.setValue('');
		this.f.case_pack.setValue('');
		this.f.description.setValue('');
		this.f.freight.setValue('');
		this.f.srp.setValue('');
		this.f.low_threshold.setValue('');
		this.f.suggestedretailprice.setValue('');
		
		this.dialogValues.image = "";
	}
	
	clearImage()
	{
		this.dialogValues.image = '';
		this.selectedFile = new ImageSnippet('', null);
	}
	
	processFile(imageInput: any) {
		const file: File = imageInput.files[0];
		const reader = new FileReader();
		reader.addEventListener('load', (event: any) => {
		  this.selectedFile = new ImageSnippet(event.target.result, file);
		});
		reader.readAsDataURL(file);
	}
	
	receiveYearQuarter($event){
	}
	
	
	updatevalue(event,section: number, item_id: number,index)
	{
		this.t_cases = Number($('#cases_'+item_id).val());
		this.t_loose_items = Number($('#loose_items_'+item_id).val());
		if(section==1)
		{
			$('.spincases1-'+item_id).css('display','block');
		}
		else
		{
			$('.spinloose1-'+item_id).css('display','block');
		}
		if(event.keyCode == 13)
		{
			this.t_cases = Number($('#cases_'+item_id).val());
			this.t_loose_items = Number($('#loose_items_'+item_id).val());
			if(section == 1)
			{
				$('#cases_'+item_id).attr('disabled', 'true');
				$('.spincases-'+item_id).css('display','block');
			} else {
				$('#loose_items_'+item_id).attr('disabled', 'true');
				$('.spinloose-'+item_id).css('display','block');
			}
			this.updateInvItem(item_id);
		}
	}
	updatevalue1(event,section: number, item_id: number,index)
	{
		this.t_cases = Number($('#cases_'+item_id).val());
			this.t_loose_items = Number($('#loose_items_'+item_id).val());
			if(section == 1)
			{
				$('#cases_'+item_id).attr('disabled', 'true');
				$('.spincases-'+item_id).css('display','block');
			} else {
				$('#loose_items_'+item_id).attr('disabled', 'true');
				$('.spinloose-'+item_id).css('display','block');
			}
			this.updateInvItem(item_id);
	}
	
	updateInvItem(item_id: number) {
		this.inventoryService.updateinventory(this.selectedStorage.id, item_id, this.t_cases, this.t_loose_items)
		.pipe(first())
		.subscribe(
		data => {
			this.loadInventoryData();
		},
		error => {
		});
	}
	confirmDeleteItem() {
		this.checkedItemList = [];
		for (var i = 0; i < this.inventoryData.length; i++) {
			if(this.inventoryData[i].is_checked)
				this.checkedItemList.push(this.inventoryData[i].id);
		}
		if(this.checkedItemList.length)
		{
			this.checkedItemList = JSON.stringify(this.checkedItemList);
			this.inventoryService.deleteinventory(this.selectedStorage.id, this.checkedItemList)
			.pipe(first())
			.subscribe(
			data => {
				this.loadInventoryData();
			},
			error => {
			});
		}
	}
	
	
	checkall(values:any) {
		for (var i = 0; i < this.inventoryData.length; i++) {
			this.inventoryData[i].is_checked = this.isMasterSel;
		}
	}
	
	checkone() {
		this.isMasterSel = this.inventoryData.every(function(item:any) {
			return item.is_checked == true;
		})
	}

	deleteInventory(inventory_id){
		Swal.fire({
			title: 'Are you sure want to remove?',
			text: 'You will not be able to recover this item!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, keep it'
		  }).then((result) => {
			if (result.value) {
				this.checkedItemList = [];
				for (var i = 0; i < this.inventoryData.length; i++) {
					if(this.inventoryData[i].is_checked)
						this.checkedItemList.push(this.inventoryData[i].id);
				}
				if(this.checkedItemList.length <= 0)
				{
					this.checkedItemList.push(inventory_id);
				}
				this.checkedItemList = JSON.stringify(this.checkedItemList);
				/* return; */
				this.inventoryService.deleteinventory(this.selectedStorage.id, this.checkedItemList)
				.pipe(first())
				.subscribe(
					data => {
						this.loadInventoryData();
					},
					error => {
					}
				);
				
			 
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			 
			}
		  })
	}

	checkPremium(store_id){
		const current_url = this.router.url;
		const current_slug = current_url.split("/").pop().split('#')[0];
		if(current_slug == 'inventory'){
		  const premiumPackage = this.authGuard.premiumPackage(store_id);
		  if(!premiumPackage){
			this.premiumPlan = false;
			
		  }
		  else{
			this.premiumPlan = true;
		  }
		}
	}

	checkUserPermission(type){
		const hasPermission = this.userPermission;
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if(currentUser.isAdmin){
			return true;
		}
		else if (typeof hasPermission !== "undefined" && hasPermission.includes(type))
		{
			return true;
		}
		else{
			return true;
		}
		return false; 
		
	}
	ngOnDestroy() {
		document.body.classList.remove('loginbody');
		//this.ngOnInit();
	  } 

	  getVendors(){
		this.inventoryService.getVendors(this.selectedStorage.id)
        .pipe(first())
        .subscribe(
            data => {
			 this.vendorList = data['list'];
            }
		);
	}

	getCategories(){
		this.inventoryService.getCategories(this.selectedStorage.id)
        .pipe(first())
        .subscribe(
            data => {
				this.categoryList = data['list'];
			}
		);
	}
	onCategoryChange(value) {
		this.categorynameval = false;
	  }
	  onVendorChange(value)
	  {
		this.vendornameval = false;
	  }
	
}
