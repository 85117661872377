import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// import { MessageService } from "../_services/message.service";
import { StoreSubscriberService } from "../_services/storeSubscriber.service";
import { CostOfFreshService } from "../_services/costOfFresh.service";
import {utils} from "protractor";
import {UtilsService} from "../_services/utils.service";
import { ActivatedRoute } from '@angular/router';
import { Subject }            from 'rxjs';
import { AuthGuard } from "../_helpers/auth.guard";
@Component({
  selector: 'app-cost-of-fresh',
  templateUrl: './cost-of-fresh.component.html',
  styleUrls: ['./cost-of-fresh.component.less']
})



export class CostOfFreshComponent implements OnInit {
  emptyWeek = true;
  selectedStorage: any;
  store_ID:any;
  weeks: any[] = [];
  yearQuarter: any;
  costOf: string;
  duration: string;
  panelURL: string;
  target: any;
  cols: any[];
  title: string;
	pertitle:string;
  pertitle1:string;
  SumprojectionsRev = 0;
  Sumactual_sales = 0;
  Sumcal_cost_of_fresh = 0;
  Sumcost_of_fresh = 0;
  Sumpercentage = 0;
  Sumdifference = 0;
  loading = true;
  public myMath = Math;
  //dtOptions: DataTables.Settings = {};
  //dtTrigger: Subject<any> = new Subject();
  constructor(
      private costOfFreshService: CostOfFreshService,
      private storeSubscriberService: StoreSubscriberService,//service used to receive store from top bar stores combobox
      private utilService: UtilsService,
      private route: ActivatedRoute,
      private authGuard : AuthGuard,
      )
  {
      this.authGuard.userPermissions();
      storeSubscriberService.subscribe(this,function (ref,store) {
        ref.receiveStorage(store);
      });
      this.yearQuarter = {year : this.utilService.GetCurrentYear(), quarter: this.utilService.GetCurrentQuarter()};
  }

  ngOnInit() {
   /*  this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 1,
      processing: true
    }; */
    this.route.params.subscribe(params => {
      this.costOf = params['what'];
      if(this.costOf == 'fresh')
	  {
        this.title = 'Fresh';
        this.duration = 'WEEK';
        this.panelURL = 'week-panel';
		    this.pertitle = 'Over/Under Budget';
        this.pertitle1 = 'Fresh %';
      } else {
		  this.title = 'Hard Goods';
		  this.duration = 'MONTH';
		  this.panelURL = 'month-panel';
		  this.pertitle = 'ACTUAL C.O.H';
      this.pertitle1 = 'Percentage %';
	  }

      this.selectedStorage = JSON.parse(localStorage.getItem('selectedStorage'));
	    this.reloadData();
      this.loadHeaders();
    });
  }

  reloadData(){
    this.store_ID = localStorage.getItem('store_id');
    
    this.loading = true;
    this.costOfFreshService.getMasterOverviewWeekly_v2(this.costOf,this.selectedStorage.id,this.yearQuarter.year,this.yearQuarter.quarter,this.selectedStorage.created_at).subscribe((data: any) =>{
      this.weeks = data.master_overview_weekly;
      this.loading = false;
      
      if(this.weeks.length > 0)
      {
        this.emptyWeek = false;
        this.target = this.weeks[0].target;
        var weeks_data = this.weeks;
        var SumprojectionsRev = 0;
        var Sumactual_sales = 0;
        var Sumcal_cost_of_fresh = 0;
        var Sumcost_of_fresh = 0;
        var Sumpercentage = 0;
        var Sumdifference = 0;
        Object.keys(weeks_data).forEach(function(key) {
          SumprojectionsRev += Number(weeks_data[key].projectionsRev);
          Sumactual_sales += Number(weeks_data[key].actual_sales);
          Sumcal_cost_of_fresh += Number(weeks_data[key].cal_cost_of_fresh);
          Sumcost_of_fresh += Number(weeks_data[key].cost_of_fresh);
          Sumpercentage += Number(weeks_data[key].percentage);
          Sumdifference += Number(weeks_data[key].difference);
			
        });
        this.SumprojectionsRev = SumprojectionsRev;
        this.Sumactual_sales = Sumactual_sales;
        this.Sumcal_cost_of_fresh = Sumcal_cost_of_fresh;
        this.Sumcost_of_fresh = Sumcost_of_fresh;
        this.Sumpercentage = Sumpercentage;
        this.Sumdifference = Sumdifference;
      }
      else{
        this.SumprojectionsRev = 0;
        this.Sumactual_sales = 0;
        this.Sumcal_cost_of_fresh = 0;
        this.Sumcost_of_fresh = 0;
        this.Sumpercentage = 0;
        this.Sumdifference = 0;
      }
    })
  }

  receiveYearQuarter($event){
    this.yearQuarter = $event;
    this.reloadData();
  }
  receiveStorage(storage){
    this.selectedStorage = storage;
    this.reloadData();
  }

  loadHeaders(){
    this.cols = [
      { field: 'week_show', header: this.duration, width: 150 },
      { field: 'projectionsRev', header: 'PROJECTED SALES', width: 150  },
	    { field: 'cal_cost_of_fresh', header:  this.title + ' BUDGET ', width: 100  },
	    { field: 'difference', header: 'REMAINING BUDGET', width: 80  },
      { field: 'actual_sales', header: 'SALES', width: 100  },
      { field: 'cost_of_fresh', header: this.title + ' EXPENSE', width: 150  },
      { field: 'percentage', header: this.pertitle, width: 80  },
      { field: 'percentage1', header: this.pertitle1, width: 80  }
    
    ];
  }
  getFormattedPercentage(costOfFresh: number, actualSales: number): string {
    if (actualSales !== 0) {
      const percentage = (costOfFresh / actualSales) * 100;
      return percentage.toFixed(2); // Format with two decimal places
    } else {
      return '0.00'; // Handle division by zero or actualSales = 0
    }
  }


}
