import { Component, OnInit } from '@angular/core';
import { StoreSubscriberService } from "../../_services/storeSubscriber.service";
import { UtilsService } from "../../_services/utils.service";
import { WeekPanelService } from "../../_services/weekPanel.service";
import { SchedulerService } from "../../_services/scheduler.service";
import { CheckRole } from "../../_helpers/check-role";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MessageToastService } from '../../_services/messageToast.service';
import { MessageService } from "../../_services/message.service";
import { ProfileService } from "../../_services/profile.service";
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Subject } from "rxjs";
import { AuthGuard } from "../../_helpers/auth.guard";
import { Injectable, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.less']
})
export class SchedulerComponent implements OnInit {
  
  
  hours: any[];
  projected_value: number;
  sumemployessvalue:number
  laborCost:number;
  total_sum: number;
  minutes: any[];
  weekList: any[];
  weekNumber = '';
  weekYear = ''
  employees: any[];
  inventoryTableCols: any[];
  selectedStorage: any;
  yearQuarter: any;
  selectedWeekItem: any;
  store_ID:any;
  visibleDialogTarget: boolean = false;
  tfootshow: boolean = false;
  targetform: FormGroup;
  submittedFormTarget: boolean = false;
  loading: boolean = false;
  loadingprev: boolean = false;
  loadingclear: boolean = false;
  copyprev: number = 0;
  calFrom:any;
  diffCal:any;
  calTo:any;
  //------week resume
  //$scope.runningCOG = 0.00;
  weekTotal: number = 0;
  day_1_hrs: number = 0;
  day_2_hrs: number = 0;
  day_3_hrs: number = 0;
  day_4_hrs: number = 0;
  day_5_hrs: number = 0;
  day_6_hrs: number = 0;
  day_7_hrs: number = 0;
  day_total: number = 0;
  targetCOL: number = 0.00;
  projWeeklyRev: number = 0.00;
  scheduledPayroll: number = 0.00;
  differenceCol: number = 0.00;
  projectedPayrol: number = 0.00;

  title: string = "Finance View";
  displayList: boolean = true;
  employeeName = '';
  weekFrom = '';
  weekTo = '';
  breakTime = '';
  scheduleID = '';
  scheduleFormSubmit = false;
  customWeekError = '';
  departmentList = [];
  departmentListnew = [];
  colorCode = '';
  depName = '';
  employeeId = ''
  depId = '';
  scheduleDate = '';
  userSubmitted = false;
  addEmployeeClicked = false;
  getSchedulerList = [];
  selectedItems = [];
  resetFormSubject: Subject<boolean> = new Subject<boolean>();
  premiumPlan = false;
  userPermission = [];
  selectedDepartments: { [key: string]: boolean } = {};
  
  pageLoading = true;
  resetChildForm(){
    $("#schedulerForm").trigger('reset');
    this.resetFormSubject.next(true);
  }
  private onDestroy$ = new Subject<boolean>();
  constructor(
      private storeSubscriberService: StoreSubscriberService,//service used to receive store from top bar stores combobox
      private utilService: UtilsService,
      private weekPanelService: WeekPanelService,
      private schedulerService: SchedulerService,
	  private checkRole: CheckRole,
	  private formBuilder: FormBuilder,
	  private message: MessageToastService,
    private messageService: MessageService,
    private profileService: ProfileService,
    private router: Router,
    private authGuard : AuthGuard,
  )
  {
    storeSubscriberService.subscribe(this,function (ref,store) {
      ref.receiveStorage(store);
    });
    let currentYear = this.utilService.GetCurrentYear();
    this.yearQuarter = {year : currentYear, quarter: 1};
	}

  ngOnInit() {
    this.loadHeaders();
    window.onafterprint = function(){
      location.reload();
    }
    document.body.classList.remove('loginbody');
    
    this.authGuard.userPermissions();
    const permissions = this.authGuard.userPermissions();
    this.userPermission = permissions;
    this.getschedule_format();
   


  this.selectedStorage = JSON.parse(localStorage.getItem('selectedStorage'));
  this.checkPremium(this.selectedStorage.id);
  
	this.getWeeks();
	this.getEmplyees();
  
	this.targetform = this.formBuilder.group({
      target: ['', Validators.required],
    });

    this.messageService.getChangeDisplayModeData().subscribe(message => {
      this.displayList = message;
      if(this.displayList == true)
        this.title = "Finance View";
      else this.title = "Scheduler";
    });

    this.getDepartment();
    if(!this.premiumPlan){
      document.body.classList.add('loginbody');
    }
  }
  
  get t() { return this.targetform.controls; }

  receiveStorage(storage){
    this.selectedStorage = storage;
    this.getWeeks();
    this.getEmplyees();
    this.getDepartment();
    this.checkPremium(this.selectedStorage.id);

  }
  receiveYearQuarter($event){
    this.yearQuarter = $event;
    this.getWeeks();
  }

  getWeeks = function () {
    this.weekPanelService.getWeekList(this.yearQuarter.year).subscribe((response: any) => {
      this.weekList = response.weekList;
      this.weekNumber = response.weeknumber;
      this.weekYear = response.currentYear;
      console.log('week',response);
      this.weekPanelService.getSevenDays_v2(this.selectedStorage.id,this.weekYear,this.weekNumber).subscribe((response: any) =>{
       this.projected_value = response.projected_value;
       this.total_sum = response.total_sum;
      })
    });
  }

  

  getEmplyees = function () {
    this.store_ID = localStorage.getItem('store_id');
    this.pageLoading = true;
    this.weekPanelService.getEmplyees(this.yearQuarter.year, this.selectedStorage.id).subscribe((response: any) => {
      this.pageLoading = false;
      console.log('e',response.employees);
      this.employees = response.employees;
      let employeeData = response.employees;
      this.laborCost = response.laborCost.labor;
      this.sumemployessvalue = employeeData.reduce((acc, obj) => acc + obj.employee_value_total11, 0);
  
     
      this.loadingprev = false;
      this.setTableFooterTotal();
      });
  }
  getschedule_format = function(){
    this.schedulerService.time_format().subscribe(response => {
      this.hours = response.timeSlots
      }
		);
	}
  
  copyPrevSchedule = function () {
    Swal.fire({
			title: 'Are you sure want to copy?',
			text: 'You will not be able to recover this data!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, Copy it!',
			cancelButtonText: 'Cancel'
		  }).then((result) => {
			if (result.value) {
        this.loadingprev = true;
        this.copyprev = 1;
        this.weekPanelService.copyPrevWeek(this.yearQuarter.year, this.selectedStorage.id, this.employees).subscribe((response: any) => {
         
          this.schedulerService.updateScheduler(this.selectedStorage.id, response.employees,'1').subscribe(
            response=> {
            this.getEmplyees();
            
                  this.loading = false;
          },
                error => {
                  this.loading = false;
                }
          );
        });
        //this.saveSchedule();
      }
    });
  }
  clearSchedule = function () {
      Swal.fire({
        title: 'Are you sure want to clear?',
        text: 'You will not be able to recover this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Clear it!',
        cancelButtonText: 'Cancel'
		  }).then((result) => {
        if (result.value) {
          this.loadingclear = true;
          for (var i = 0; i < this.employees.length; i++) {
            for (var j = 0; j < this.employees[i].weekData.length; j++) {
              this.employees[i].weekData[j].from = 0;
              this.employees[i].weekData[j].to = 0;
              this.employees[i].weekData[j].hrs = 0;
              this.employees[i].weekData[j].department_id = '';
            }
          }
          this.saveSchedule();
          this.loadingclear = false;
          this.getEmplyees();
        }
      });
  }
  saveSchedule = function () {
    this.loading = true;
        this.schedulerService.updateScheduler(this.selectedStorage.id, this.employees,'2').subscribe(
              response=> {
				  this.getEmplyees();
				  
                this.loading = false;
				},
              error => {
                this.loading = false;
              }
        );
  }
  
  setTableFooterTotal = function ()
  {
	  this.day_1_hrs = this.day_2_hrs = this.day_3_hrs = this.day_4_hrs = this.day_5_hrs = this.day_6_hrs = this.day_7_hrs = 0;
	for (var i = 0; i < this.employees.length; i++) {
		this.weekTotal = 0;
		  for (var j = 0; j < this.employees[i].weekData.length; j++) {
        /* if (this.employees[i].weekData[j]) { */
          var weekDataHrs = this.employees[i].weekData[j].hrs;
          weekDataHrs  = typeof weekDataHrs !== 'undefined' ? weekDataHrs : 0;
          this.weekTotal = parseFloat(this.weekTotal)+parseFloat(weekDataHrs);
          if(j == 0)
            this.day_1_hrs = parseFloat(this.day_1_hrs)+parseFloat(weekDataHrs);
          if(j == 1)
            this.day_2_hrs = parseFloat(this.day_2_hrs)+parseFloat(weekDataHrs);
          if(j == 2)
            this.day_3_hrs = parseFloat(this.day_3_hrs)+parseFloat(weekDataHrs);
          if(j == 3)
            this.day_4_hrs = parseFloat(this.day_4_hrs)+parseFloat(weekDataHrs);
          if(j == 4)
            this.day_5_hrs = parseFloat(this.day_5_hrs)+parseFloat(weekDataHrs);
          if(j == 5)
            this.day_6_hrs = parseFloat(this.day_6_hrs)+parseFloat(weekDataHrs);
          if(j == 6)
            this.day_7_hrs = parseFloat(this.day_7_hrs)+parseFloat(weekDataHrs);
        /* } */
		  }
		this.employees[i].total = this.weekTotal.toFixed(2);
  }
  
  this.day_1_hrs = this.day_1_hrs.toFixed(2);
  this.day_2_hrs = this.day_2_hrs.toFixed(2);
  this.day_3_hrs = this.day_3_hrs.toFixed(2);
  this.day_4_hrs = this.day_4_hrs.toFixed(2);
  this.day_5_hrs = this.day_5_hrs.toFixed(2);
  this.day_6_hrs = this.day_6_hrs.toFixed(2);
  this.day_7_hrs = this.day_7_hrs.toFixed(2);
	this.day_total = parseFloat(this.day_1_hrs)+parseFloat(this.day_2_hrs)+parseFloat(this.day_3_hrs)+parseFloat(this.day_4_hrs)+parseFloat(this.day_5_hrs)+parseFloat(this.day_6_hrs)+parseFloat(this.day_7_hrs);
	this.tfootshow = true;
  }
  weekValueOnChangeFrom = function (value:any,childpos:number,mainpos:number) {
	  this.employees[mainpos].weekData[childpos].from = value;
	  this.calFrom = value;
	  if(this.calFrom != 0 && this.calTo != 0)
	  {
		  this.calFrom = this.calFrom.replace(':00','.00');
	    this.calFrom = this.calFrom.replace(':15','.25');
	    this.calFrom = this.calFrom.replace(':30','.50');
			this.calFrom = this.calFrom.replace(':45','.75');
		  this.calTo = this.employees[mainpos].weekData[childpos].to;
		  this.calTo = this.calTo.replace(':00','.00');
		  this.calTo = this.calTo.replace(':15','.25');
		  this.calTo = this.calTo.replace(':30','.50');
		  this.calTo = this.calTo.replace(':45','.75');
		  this.diffCal = parseFloat(this.calTo)-parseFloat(this.calFrom);
		  this.employees[mainpos].weekData[childpos].hrs = this.diffCal;
		  this.setTableFooterTotal();
	  }
  }
  
  weekValueOnChangeTo = function (value:any,childpos:number,mainpos:number) {
	  this.employees[mainpos].weekData[childpos].to = value;
	  
	  this.calFrom = this.employees[mainpos].weekData[childpos].from;
	  this.calTo = value;
	  if(this.calFrom != 0 && this.calTo != 0)
	  {
		  this.calFrom = this.calFrom.replace(':00','.00');
		  this.calFrom = this.calFrom.replace(':15','.25');
		  this.calFrom = this.calFrom.replace(':30','.50');
		  this.calFrom = this.calFrom.replace(':45','.75');
		  this.calTo = this.calTo.replace(':00','.00');
		  this.calTo = this.calTo.replace(':15','.25');
		  this.calTo = this.calTo.replace(':30','.50');
		  this.calTo = this.calTo.replace(':45','.75');
		  this.diffCal = parseFloat(this.calTo)-parseFloat(this.calFrom);
		  this.employees[mainpos].weekData[childpos].hrs = this.diffCal;
		  this.setTableFooterTotal();
	  }
  }
  
  settingStringFormatToWeekLisElements = function(weekList){
    for (let i = 0 ; i < weekList.length ; i++){
      var parts = weekList[i].date.split('-');
      var dateFormatted = new Date(parts[0], parts[1] - 1, parts[2]);
      this.weekList[i].dateObject = dateFormatted;
      this.weekList[i].stringFormat = this.utilService.getStringMonthDay(dateFormatted);
    }
  }

  getWeekDataFromServer = function () {
    this.getTargetCOL();
    this.getProjWeeklyRev();
    this.getScheduledPayroll();
  }

  getProjWeeklyRev = function () {
    this.schedulerService.getProjWeeklyRev(this.selectedStorage.id,this.selectedWeekItem).subscribe((response: any) =>{
      this.projWeeklyRev = response.proj_weekly_rev;
    });
  }
  calcProjectedPayRol = function () {
    return this.projectedPayrol = this.targetCOL * this.projWeeklyRev / 100;
  }
  getTargetCOL = function () {
    this.schedulerService.getTargetCOL(this.selectedStorage.id,this.selectedWeekItem).subscribe((response: any) =>{
      this.targetCOL = (response == null) ? 0 : response.target_percentage;
	  this.t.target.setValue(this.targetCOL);
    });
  }
  getScheduledPayroll = function () {
    this.schedulerService.getScheduledPayroll(this.selectedStorage.id,this.selectedWeekItem).subscribe((response: any) =>{
      this.scheduledPayroll= response.scheduled_payroll;
      this.calcDifferendeCOL();
    });
  }
  calcDifferendeCOL = function () {
    return this.differenceCol = this.projectedPayrol - this.scheduledPayroll;
  }
  
  get hasAcces() {
        if(this.checkRole.isRoot() || this.checkRole.isCompanyAdmin() || this.checkRole.isStoreManager())
		  return true;
		else return false;
	}
	
	updayeTarget(){
    this.submittedFormTarget = true;

    // stop here if form is invalid
    if (this.targetform.invalid) {
      return;
    }
	
	this.visibleDialogTarget = false;	
    
    this.schedulerService.updateTargetCOL(this.selectedStorage.id,this.selectedWeekItem,this.t.target.value)
        .subscribe(
            data => {
			  let response = data; 	
			  if(response.status=='error')
			      this.message.sendMessage('error', 'Schedule Message', response.errors);
			  else
			  {
				  this.message.sendMessage('success', 'Schedule Message', 'Target updated successfully !');
				  this.submittedFormTarget = false;
				  this.getTargetCOL();
			  }
			},
            error => {
			  
			  this.message.sendMessage('error', 'Schedule Message', error);
              //this.error = error;
        });
  }

  addShift(employeeIndex, weekIndex){
   // this.schedule_form.controls['id'].setValue(scheduler_id);
    this.scheduleFormSubmit = false;
    const employeeData = this.employees[employeeIndex];
    const weekData = this.employees[employeeIndex].weekData[weekIndex];

    this.employeeName = employeeData.first_name +' '+ employeeData.last_name;
    console.log(weekData);

    this.weekFrom = weekData.from == 0 ? '' : weekData.from;
    this.weekTo = weekData.to == 0 ? '' : weekData.to;
    this.breakTime = weekData.break_time == 0 ? '' : weekData.break_time;
    this.scheduleID = weekData.id == 0 ? '' : weekData.id;
    if(this.weekFrom !='' && this.weekTo !='')
    {
      this.schedulerService.time_convert_new(this.weekFrom,this.weekTo).subscribe(response => {
       this.weekFrom = response['convertedtime']['weekfrom'];
       this.weekTo = response['convertedtime']['weekto'];
        //this.weekFrom = response.weekfrom;
        }
      );
    }
    this.employeeId = employeeData.id;
    const shiftIndex = weekIndex+Number(0);
    //const shiftIndex = weekIndex+Number(2);
    const color_code = weekData.color_code;
    const department_id = weekData.department_id;
    if (color_code && department_id && typeof color_code !== 'undefined' && typeof department_id !== 'undefined'){
      this.colorCode = color_code;
      this.depId = department_id;

      for(let i = 0 ; i < this.departmentList.length ; i++){
        if(this.departmentList[i].id == department_id){
          this.depName = this.departmentList[i].name;
        }
      }
    }
    else{
      this.getDepartment();
    }

    this.scheduleDate = this.weekList[shiftIndex].date;
  }


  schedule_form = new FormGroup({
    id: new FormControl(''),
    week_from: new FormControl('', [Validators.required]),
    week_to: new FormControl('', [Validators.required]),
    break_time: new FormControl('', [Validators.required]),
  });
  
  get schedule_f(){
    return this.schedule_form.controls;
  }
  convertTimeToMinutes(time) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }
  normalizeTime(time) {
    let [hour, minute] = time.split(/:| /).map(Number);
    if (time.includes('PM') && hour < 12) {
      hour += 12;
    } else if (time.includes('AM') && hour == 12) {
      hour = 0;
    }
    return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
  }
  schedule_submit(){
    this.customWeekError = '';
    this.scheduleFormSubmit = true;
    var week_from = this.schedule_form.value.week_from;
    var week_to = this.schedule_form.value.week_to;
    var break_time = this.schedule_form.value.break_time;
    var id = this.schedule_form.value.id;
    const fromIs24HourFormat = !week_from.includes('AM') && !week_from.includes('PM');
    const toIs24HourFormat = !week_to.includes('AM') && !week_to.includes('PM');
		
    if(week_to != '' && week_from != ''){
     
      
        if (!fromIs24HourFormat) {
          week_from = this.normalizeTime(week_from);
          console.log('b',week_from);
        }
      
        if (!toIs24HourFormat) {
          week_to = this.normalizeTime(week_to);
        }
      
        const fromMinutes = this.convertTimeToMinutes(week_from);
        const toMinutes = this.convertTimeToMinutes(week_to);
       
    
    if(fromMinutes >= toMinutes){
        this.customWeekError = 'start time must be before end time'
      }
      else{
        if(this.schedule_form.invalid){
          return;
        }
       
       
       week_from = week_from.replace(':00','.00');
       week_from = week_from.replace(':15','.25');
       week_from = week_from.replace(':30','.50');
       week_from = week_from.replace(':45','.75');
       
       week_to = week_to.replace(':00','.00');
       week_to = week_to.replace(':15','.25');
       week_to = week_to.replace(':30','.50');
       week_to = week_to.replace(':45','.75');
       const total_hours = parseFloat(week_to)-parseFloat(week_from);
      
       this.customWeekError = '';
        

        var data = {
          id: id,
          week_from : week_from,
          week_to : week_to,
          total_hours : total_hours,
          depId : this.depId,
          employeeId : this.employeeId,
          scheduleDate : this.scheduleDate,
          break_time : break_time,
        }
        if(!this.depId){
          return false;
        }
        this.schedulerService.creaetScheduler(this.selectedStorage.id, data).subscribe(
          response=> {
            this.getEmplyees();
            this.loading = false;
            //this.weekFrom = '';
            //this.weekTo = '';
            let element:HTMLElement = document.querySelector('#close_sch_modal') as HTMLElement;
            element.click();
            var data = [];

          },
                error => {
                  this.loading = false;
                }
          );

      }
    }
  }

  // getDepartment(){
	// 	this.profileService.getDepartments(this.selectedStorage.id).subscribe((response: any) =>{
  //     this.departmentList = response.department;
  //     if(response.department.length > 0){
  //       const name = response.department[0].name;
  //       const color_code = response.department[0].color_code;
  //       this.depId = response.department[0].id;
  //       this.colorCode = color_code;
  //       this.depName = name;
  //     }
	// 	});
  // }
  getDepartment(){
    this.profileService.getDepartments(this.selectedStorage.id).subscribe((response: any) =>{
      this.departmentList = response.department;
    
      this.departmentList.forEach(data => {
        this.selectedDepartments[data.name] = true;
      });

      if(response.department.length > 0){
        const name = response.department[0].name;
        const color_code = response.department[0].color_code;
        this.depId = response.department[0].id;
        this.colorCode = color_code;
        this.depName = name;
      }
    });
}
  
  selectDep(depIndex){
    const departmentList = this.departmentList[depIndex];
    const name = departmentList.name;
    const color_code = departmentList.color_code;
    this.colorCode = color_code;
    this.depName = name;
    this.depId = departmentList.id;

  }

  navigateWithState(value) {
    let element:HTMLElement = document.querySelector('#close_sch_modal') as HTMLElement;
            element.click();
	}
  
  adduserEventHander($event: any) {
    if($event){
      this.getEmplyees();
    }
  }
  addEmployee(){
    this.addEmployeeClicked = true;
  }
  checkPremium(store_id){
    const current_url = this.router.url;
    const current_slug = current_url.split("/").pop().split('#')[0];
    if(current_slug == 'scheduler'){
      const premiumPackage = this.authGuard.premiumPackage(store_id);
      if(!premiumPackage){
        this.premiumPlan = false;
      }
      else{
        this.premiumPlan = true;
      }
    }
  }
  
  
  checkUserPermission(type){
		const hasPermission = this.userPermission;
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if(currentUser.isAdmin){
			return true;
		}
		else if (typeof hasPermission !== "undefined" && hasPermission.includes(type))
		{
			return true;
		}
		else{
			return true;
		}
		return false; 
		
	}
 

  printTable(){
   /*  var printContents = (<HTMLInputElement>document.querySelector('.greenHeaderTbl')).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents; */
    //window.print();
    /* var w = window.open();
    var html = $(".greenHeaderTbl").html();

    $(w.document.body).html(html);
     w.print(); */
     //window.open();
     var printContents = (<HTMLInputElement>document.querySelector('.greenHeaderTbl')).innerHTML;
     var originalContents = document.body.innerHTML;

     document.body.innerHTML = printContents;
    
     window.print();

     document.body.innerHTML = originalContents;
    
  }

  ngOnDestroy() {
    document.body.classList.remove('loginbody');
    //this.ngOnInit();
  } 
  loadHeaders()
  {
    this.inventoryTableCols = [
        { field: 'first_name', header: 'Name' },
        { field: 'departments', header: 'Dept' },
      ];	
  }
  // toggleSelection(data): void {
  //   console.log(data);
  //   this.getEmplyees();
  // }

  
  toggleSelection(data: any, event: any) {
    
    console.log(data);
    const isChecked = event.target.checked;
    if (isChecked) {
      
      console.log(`Checkbox for ${data.name} is checked.`);
    } else {
      
      console.log(`Checkbox for ${data.name} is unchecked.`);
    }
  }

  getFormattedPercentage(sumemployessvalue: number, actualSales: number): string {
    if (actualSales !== 0) {
      const percentage = (sumemployessvalue / actualSales) * 100;
      return percentage.toFixed(2); // Format with two decimal places
    } else {
      return '0.00'; // Handle division by zero or actualSales = 0
    }
  }

  


}
