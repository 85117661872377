import { Component, OnInit, NgZone } from '@angular/core';
import { AuthenticationService } from '@app/_services'; 
import { SalesService } from "../_services/sales.service";
import { UtilsService } from "../_services/utils.service";
import { AuthGuard } from "../_helpers/auth.guard";
import {TableModule} from 'primeng/table';
import {Observable} from "rxjs";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray ,ValidationErrors} from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.less']
})
export class CheckoutComponent implements OnInit {
  loadStripe() {
    

    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        (window as any)['Stripe'].setPublishableKey(this.authenticationService.stripe_key());
      };
      window.document.body.appendChild(s);
    }


  }
  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,//service used to receive store from top bar stores combobox
    private salesService: SalesService,
    private utilService: UtilsService,
    private route : Router,
    private authGuard : AuthGuard,
    private zone: NgZone,
    private router: ActivatedRoute
  ) { }
  ShowLoading=null;
  ShowPayNow=null;
  stateLoading = false;
  submitLoading = false;
  disabled_button = false;
  StripeCardEror:string;
  stateLoadingStore = [];
  cardSelected = false;
  states = [];
  packageDetail = [];
  States = [];
  countries=[];
  yearList=[];
  Cards = [];
  isValidFormSubmitted = null;
  submitted = null;
  selected_product:string='';
  license_count : string ='';
  package_type : string = 'M';
  total_price:number;
  product_price:number;
  form: FormGroup;
  ShowAddCardForm = null;
  ListCardDiv = null;
  userDetail = [];
  user_id: string = '';
  ngOnInit(): void {
    $(document).on("change",".stripe_id_card",function() {
      $(".stripe_id_card").not(this).prop('checked', false);
    });
    this.loadStripe();
    document.body.classList.remove('loginbody');
    this.authenticationService.getcountries().subscribe((data: any) => {
      this.countries = data.countries;
    });
    this.authenticationService.getYearList().subscribe((data: any) => {
      this.yearList = data;
    });

    const selected_product = localStorage.getItem('selected_product');
   
    const license_count = localStorage.getItem('license_count');
    const package_type = localStorage.getItem('package_type');
    const license_count_arr = localStorage.getItem('license_count_arr');
    this.selected_product = selected_product;
    this.license_count = license_count;
    
    this.package_type = package_type;
    const package_id = this.router.snapshot.params['id'];
    this.authenticationService.getProductPackageById(package_id).subscribe((data: any) => {
      if(data.status == '1'){
        this.packageDetail = data.productPackage;
        this.total_price = (package_type == 'Y' ? parseInt(data.productPackage.package_year_price)*parseInt(license_count) : parseInt(data.productPackage.package_month_price)*parseInt(license_count));

        this.product_price = package_type == 'Y' ? parseInt(data.productPackage.package_year_price) : parseInt(data.productPackage.package_month_price);
      }
       
    });
    this.user_id = localStorage.getItem('user_id');
    this.authenticationService.GetUserDetails().subscribe((data: any) => {
      if(data.status == '1' && data.profile){
        const country_id =  typeof data.profile[0] !== 'undefined' ? data.profile[0].country : '230';
        this.authenticationService.getRegions(country_id).subscribe(res => {
          this.states = res.regions;
        })
        this.userDetail = data.profile[0];
        this.form.controls.firstname.patchValue(data.profile[0].first_name);
        this.form.controls.lastname.patchValue(data.profile[0].last_name);
        this.form.controls.email.patchValue(data.profile[0].email);
        this.form.controls.mobile_no.patchValue(data.profile[0].mobile_no);
        if(data.profile[0].country != null){
          this.form.controls.country.patchValue(data.profile[0].country);
        }
        if(data.profile[0].state != null){
          this.form.controls.state.patchValue(data.profile[0].state);
        }
        this.form.controls.address.patchValue(data.profile[0].address);
        this.form.controls.zip.patchValue(data.profile[0].zip);
        this.form.controls.city.patchValue(data.profile[0].city);
      }
    }); 

    this.form = new FormGroup({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      mobile_no: new FormControl('',  [Validators.required, Validators.minLength(8)]),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      card_name: new FormControl('', [Validators.required]),
      card_number: new FormControl('', [Validators.required]),
      exp_month: new FormControl('', [Validators.required]),
      exp_year: new FormControl('', [Validators.required]),
      card_cvv: new FormControl('', [Validators.required]),
      save_card: new FormControl('1'),
      user_card_id: new FormControl(''),
    });
    if(localStorage.getItem('user_id') !== null){
      this.authenticationService.getCustomerCards().subscribe(res => {
        this.Cards = res.cards
         if(res.cards_count ==  0){
          this.ListCardDiv = false;
          this.ShowAddCardForm = true;
        }
          if(res.cards_count > 0){
            this.ListCardDiv = true;
            this.ShowAddCardForm = false; 
          }

      })
    }

  }

  changeState(event) {
    this.stateLoading = true;
    localStorage.setItem('country.id', event);
    this.authenticationService.getRegions(event).subscribe(res => {
      this.states = res.regions;
      this.stateLoading = false;
    })
    this.form.controls.state.patchValue("");
  }
  get f(){
    return this.form.controls;
  }
  submit(){
    this.submitLoading = true;
    
    this.submitted=true;
    this.disabled_button = true;
    //////console.log(this.form.value);
    if (this.form.invalid) {
      this.submitLoading = false;
      this.disabled_button = false;
      return;
    }
   // //console.log(this.form.value);return false;
    const CardNumber = this.form.get('card_number').value.trim();
    const Expirationdate = this.form.get('exp_month').value;
    const expYear = this.form.get('exp_year').value;
    const CVV = this.form.get('card_cvv').value.trim();
    const save_card = this.form.get('save_card').value;

    const packageDetail = {
      packageId:this.router.snapshot.params['id'],
      productId:this.selected_product,
      productPrice:this.product_price,
      licenseCount:this.license_count,
      packageType:this.package_type =='Y' ? 'year' : 'month',
    };

    //////console.log(CardNumber);
    //with new card
    if(!this.cardSelected){
    //do something
    if (!window['Stripe']) {
      alert('Oops! Stripe did not initialize properly.');
      return;
    }
    
     (<any>window).Stripe.card.createToken({
      number: CardNumber,
      exp_month: Expirationdate,
      exp_year: expYear,
      cvc: CVV,
    }, (status: number, response: any) => {
      const CustmoreData = JSON.parse(localStorage.getItem('CustmoreInformation'))
      // console.log('ddd');
      // console.log(CustmoreData); return false;
      
      this.zone.run(() => {
        if (status == 200) {
          this.StripeCardEror = '';
          const CustmoreData = this.form.value;
          this.authenticationService.custmoreRegisterFinal(CustmoreData, response.id, response.card.id, this.total_price, packageDetail, save_card).subscribe(
            resp => {
              this.submitLoading = false;
             if (resp.status == 1) {
             const store_id =  localStorage.getItem('store_id');
             const store_id_new = localStorage.getItem('store_id_new');
             if(store_id=='99999')
             {
              localStorage.setItem('store_id', store_id_new);
             }
            
              localStorage.setItem('user_id', resp.user_id);
              localStorage.setItem('email', resp.email);
              localStorage.setItem('currentUser', JSON.stringify(resp));
                const openSuccessModal = document.querySelector('.orderSuccessBtn');
                if (openSuccessModal instanceof HTMLElement) {
                  openSuccessModal.click();
                }
              } 
              else{
                this.StripeCardEror = resp.validationErrors.card;
              }
            }); 
        } else {
          this.submitLoading = false;
          this.disabled_button = false;
          this.StripeCardEror = response.error.message;
        }
      });
    }); 
    }
    else{
      const CustmoreData = this.form.value;
      this.authenticationService.custmoreRegisterFinal(CustmoreData, '', '', this.total_price, packageDetail).subscribe(
        resp => {
          this.submitLoading = false;
              if (resp.status == 1) {
                const store_id =  localStorage.getItem('store_id');
                const store_id_new = localStorage.getItem('store_id_new');
                if(store_id=='99999')
                {
                 localStorage.setItem('store_id', store_id_new);
                }
                localStorage.setItem('user_id', resp.user_id);
                localStorage.setItem('email', resp.email);
                localStorage.setItem('currentUser', JSON.stringify(resp));
                
                const openSuccessModal = document.querySelector('.orderSuccessBtn');
                if (openSuccessModal instanceof HTMLElement) {
                  openSuccessModal.click();
                }
              } 
              else{
               // this.StripeCardEror = resp.validationErrors.card;
              } 
        }); 
    }
  }
  selectCard(event){
    const card_id_value = event.target.value;
    this.StripeCardEror = '';
    const target = event.target.checked;
    if(target){
      this.form.get('user_card_id').patchValue(card_id_value);
      this.cardSelected = true;
      this.form.get('card_name').clearValidators();
      this.form.get('card_name').updateValueAndValidity();
      this.form.get('card_number').clearValidators();
      this.form.get('card_number').updateValueAndValidity();
      this.form.get('exp_month').clearValidators();
      this.form.get('exp_month').updateValueAndValidity();
      this.form.get('exp_year').clearValidators();
      this.form.get('exp_year').updateValueAndValidity();
      this.form.get('card_cvv').clearValidators();
      this.form.get('card_cvv').updateValueAndValidity();
    }
    else{
      this.form.get('user_card_id').patchValue("");
      this.cardSelected = false;
      this.form.get('card_name').setValidators([Validators.required]);
      this.form.get('card_name').updateValueAndValidity();
      this.form.get('card_number').setValidators([Validators.required]);
      this.form.get('card_number').updateValueAndValidity();
      this.form.get('exp_month').setValidators([Validators.required]);
      this.form.get('exp_month').updateValueAndValidity();
      this.form.get('exp_year').setValidators([Validators.required]);
      this.form.get('exp_year').updateValueAndValidity();
      this.form.get('card_cvv').setValidators([Validators.required]);
      this.form.get('card_cvv').updateValueAndValidity();
    }
    
  }



}
