import { Component, OnInit } from '@angular/core';
import { StoreSubscriberService } from "../_services/storeSubscriber.service";
import { UtilsService } from "../_services/utils.service";
import { InventoryService } from "../_services/inventory.service";
import * as $ from 'jquery' 
declare  var jQuery:  any;
@Component({
  selector: 'app-inventoryreport',
  templateUrl: './inventoryreport.component.html',
  styleUrls: ['./inventoryreport.component.less']
})
export class InventoryreportComponent implements OnInit {
	vendorArr : any;
	categoryArr : any;
	highStockArr : any;
	lowStockArr : any;
	vendorLoading: boolean = false;
	catLoading: boolean = false;
	highLoading: boolean = false;
	lowLoading: boolean = false;
	selectedStorage: any;
  constructor(
	private storeSubscriberService: StoreSubscriberService,
	private inventoryService: InventoryService,
  ) {
	  storeSubscriberService.subscribe(this,function (ref,store) {
      ref.receiveStorage(store);
    });
  }

  	ngOnInit(): void {
		document.body.classList.remove('loginbody');
	  	this.selectedStorage = JSON.parse(localStorage.getItem('selectedStorage'));
	  	this.getVendorInv();
	  	this.getCategoryInv();
	  	//this.getHighStockInv();
	 	this.getLowStockInv();
  	}
  
  	receiveStorage(storage){
		this.selectedStorage = storage;
		this.getVendorInv();
		this.getCategoryInv();
		//this.getHighStockInv();
		this.getLowStockInv();
	}

  getVendorInv = function ()
  {
	  //console.log(this.selectedStorage.id);
	this.inventoryService.getVendorInventory(this.selectedStorage.id).subscribe((response: any) =>{
		this.vendorArr = response.products;
	});
  }
  
  getCategoryInv = function ()
  {
	this.inventoryService.getCategoryInv(this.selectedStorage.id).subscribe((response: any) =>{
		this.categoryArr = response.products;
	});
  }
  
//   getHighStockInv = function ()
//   {
// 	this.inventoryService.getHighStockInv(this.selectedStorage.id).subscribe((response: any) =>{
// 		this.highStockArr = response.products;
// 	});
//   }
  
  getLowStockInv = function ()
  {
	this.inventoryService.getLowStockInv(this.selectedStorage.id).subscribe((response: any) =>{
		this.lowStockArr = response.products;
	});
  }

  receiveYearQuarter($event){
		this.getVendorInv();
	  	this.getCategoryInv();
	  	//this.getHighStockInv();
	  	this.getLowStockInv();
  }
}
