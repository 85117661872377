import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {retry, catchError} from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(
      private http: HttpClient
  ) { }
	
	getVendorInventory(store_idd): Observable<any> {
    var user_id = localStorage.getItem('user_id'); 
    var store_id = localStorage.getItem('store_id'); 
		return this.http.get(`${environment.apiUrl}/inventory/getvendorinv/${store_id}/${user_id}`);
	}
	
	getCategoryInv(store_idd): Observable<any> {
    var user_id = localStorage.getItem('user_id'); 
    var store_id = localStorage.getItem('store_id'); 
		return this.http.get(`${environment.apiUrl}/inventory/getcategoryinv/${store_id}/${user_id}`);
	}
	
	getHighStockInv(store_idd): Observable<any> {
    var user_id = localStorage.getItem('user_id'); 
    var store_id = localStorage.getItem('store_id'); 
		return this.http.get(`${environment.apiUrl}/inventory/gethighstockinv/${store_id}/${user_id}`);
	}
	
	getLowStockInv(store_idd): Observable<any> {
    var user_id = localStorage.getItem('user_id'); 
    var store_id = localStorage.getItem('store_id'); 
		return this.http.get(`${environment.apiUrl}/inventory/getlowstockinv/${store_id}/${user_id}`);
	}
	
  getInventory(store_idd,keyword1='',keyword2='',keyword3=''): Observable<any> {
    var user_id = localStorage.getItem('user_id');
    var store_id = localStorage.getItem('store_id');  
      return this.http.get(`${environment.apiUrl}/inventory/getinv/${store_id}/${user_id}?q1=${keyword1}&q2=${keyword2}&q3=${keyword3}`);
  }
	
  postinventory(store_idd,product_name,product_code,vendor_name,category_name,unit_price,markup,case_pack,description,freight,srp,low_threshold,image,item_id){
	  const formData = new FormData();
    var user_id = localStorage.getItem('user_id'); 
    var store_id = localStorage.getItem('store_id'); 
        //formData.append('image', image);
        formData.append('user_id', user_id);
        formData.append('product_name', product_name);
        formData.append('product_code', product_code);
        formData.append('vendor_name', vendor_name);
        formData.append('category_name', category_name);
        formData.append('unit_price', unit_price);
        formData.append('markup', markup);
        formData.append('case_pack', case_pack);
        formData.append('description', description);
        formData.append('freight', freight);
        formData.append('srp', srp);
        formData.append('store_id', store_id);
        formData.append('low_threshold', low_threshold);
        formData.append('image', image);
        formData.append('item_id', item_id);
        console.log(formData);

        let header = new HttpHeaders();

        header.set('Content-Type','multipart/form-data');
        
        return this.http.post(`${environment.apiUrl}/inventory/create/${store_id}`, formData,{headers: header});
  }
  
  updateinventory(store_idd,item_id,t_cases,t_loose_items){
    var store_id = localStorage.getItem('store_id'); 
	  const formData = new FormData();
        formData.append('cases', t_cases);
        formData.append('loose_items', t_loose_items);
        console.log(formData);

        let header = new HttpHeaders();

        header.set('Content-Type','multipart/form-data');
        
        return this.http.post(`${environment.apiUrl}/inventory/update/${store_id}/${item_id}`, formData,{headers: header});
  }
  
  deleteinventory(store_idd,checkedItemList){
    var store_id = localStorage.getItem('store_id'); 
        return this.http.post(`${environment.apiUrl}/inventory/delete/${store_id}`, {checkedItemList} );
  }

  getVendors(store_idd){
    var store_id = localStorage.getItem('store_id'); 
    var user_id = localStorage.getItem('user_id'); 
    return this.http.get(`${environment.apiUrl}/invoice/get_vendors/${store_id}/${user_id}`, {} );
}
create_vendornew(store_idd,v_name,category,account,contact_person,contact_number,alt_contact_number,addressline1,addressline2,city,state,zip,vendorlogin,vendornotes){
  var store_id = localStorage.getItem('store_id'); 
  var user_id = localStorage.getItem('user_id'); 
  return this.http.post(`${environment.apiUrl}/invoice/create_vendornew/${store_id}`, {user_id,v_name,category,account,contact_person,contact_number,alt_contact_number,addressline1,addressline2,city,state,zip,vendorlogin,vendornotes} );
}
getCategories(store_idd){
  var user_id = localStorage.getItem('user_id'); 
  var store_id = localStorage.getItem('store_id'); 
  return this.http.get(`${environment.apiUrl}/invoice/getcategories/${store_id}/${user_id}`, {} );
}


}
