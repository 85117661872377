import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { NgZone } from '@angular/core';

import { AuthenticationService } from '@app/_services'; 

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    confirmPasswordError:string = '';
    returnUrl: string;
    error = '';
    token = '';
    showHtml = false;
    emailError:string = '';
    passwordError:string = '';
    confirmError:string = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute, 
        private router: Router,
        private authenticationService: AuthenticationService,
        private zone: NgZone
    ) { 
        // redirect to home if already logged in

        this.route.queryParams.subscribe(params => {
            let token = params['token'];
            this.token  = token; // Print the parameter to the console. 
          });
          if(this.token){
            this.authenticationService.loginwithToken(this.token).subscribe(
              resp => {
                if(resp.status == 2){
                  this.showHtml = true;
                }else{
                    
                  this.showHtml = false;
                  this.emailError = '';
                  this.passwordError = '';
                  this.confirmError = '';
                  var checkout = localStorage.getItem('gotoCheckout')
                  window.location.href="home";
                //   this.authenticationService.getUnAllottedLicensesList().subscribe((res)=>{
                   
                //     })
                }
              }
            );
          }
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/home']);
        }
    }

    ngOnInit() {
    
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
         this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
	
	keyDownFunction(event)
	{
		if(event.keyCode == 13)
		{
			this.onSubmit();
		}
    }
    
    forgetpassword(){
    this.router.navigate(['/forget-password'])
    // this.zone.run(() => this.router.navigate(['/forget-password']));
    }

    onSubmit() {
        this.submitted = true; 

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            this.loading = false;
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.submitted = false; 
                    this.loading = false;
                  
                    if(data.status=='2')
                    {
                        this.confirmPasswordError = data.validationErrors.confirm;
                    }
                    else
                    {
                        this.confirmPasswordError="";
                        this.router.navigate(['/home'], { state:  { reloadPage: true }  });
                    }
                   
                });
    }
}
