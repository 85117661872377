import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {retry, catchError} from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
      private http: HttpClient
  ) { }
	
  getEmployees(store_id,keywordstatus=''): Observable<any> {
    var user_id = localStorage.getItem('user_id'); 
      return this.http.get(`${environment.apiUrl}/profile/getemp/${user_id}/${store_id}?q1=${keywordstatus}`);
  }
	 getEmployeesTax(store_id): Observable<any> {
    var user_id = localStorage.getItem('user_id'); 
      return this.http.get(`${environment.apiUrl}/profile/gettaxemp/${user_id}`);
  }

  getEmployeesTaxById(tax_id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/profile/getemployeetaxid/${tax_id}`);
  }
	
  createEmployee(store_id,first_name,last_name,email,status,employee_type,employee_value,phone_number,emp_id="",storesmul,tax_IDD,total_value_tax){
	  const formData = new FormData();
    var user_id = localStorage.getItem('user_id'); 
        //formData.append('image', image);
        // formData.append('first_name', first_name);
        // formData.append('last_name', last_name);
        // formData.append('email', email);
        // formData.append('status', status);
        // formData.append('employee_type', employee_type);
        // formData.append('employee_value', employee_value);
        // formData.append('phone_number', phone_number);
        // formData.append('emp_id', emp_id);
        // formData.append('storesmul', storesmul);
        //console.log(formData);
        //let header = new HttpHeaders();
        // header.set('Content-Type','multipart/form-data');
        
        //return this.http.post(`${environment.apiUrl}/profile/createemp/${user_id}`, formData,{headers: header});

        return this.http.post(`${environment.apiUrl}/profile/createemp/${user_id}`,{first_name,last_name,email,status,employee_type,employee_value,phone_number,emp_id,storesmul,tax_IDD,total_value_tax});

  }
  
  deleteinventory(store_id,checkedItemList){
        return this.http.post(`${environment.apiUrl}/inventory/delete/${store_id}`, {checkedItemList} );
  }

  getDepartments(store_id){
    var user_id = localStorage.getItem('user_id'); 
    return this.http.get(`${environment.apiUrl}/department/getData/${user_id}`);
  }

  getcatData(store_id){
    var user_id = localStorage.getItem('user_id'); 
    return this.http.get(`${environment.apiUrl}/department/getcatData/${user_id}`);
  }

  createDepartment(store_id,name, color_code, id=""){
    var user_id = localStorage.getItem('user_id');
    return this.http.post(`${environment.apiUrl}/department/create/${user_id}`,{color_code,name,id});
  }
  createDepartmentsch(store_id,day_format, interval, id=""){
    var user_id = localStorage.getItem('user_id');
    return this.http.post(`${environment.apiUrl}/department/createschtime/${user_id}`,{day_format,interval,id});
  }
  createcategory(store_id,name, status, id=""){
    var user_id = localStorage.getItem('user_id'); 
    return this.http.post(`${environment.apiUrl}/department/createcategory/${user_id}`,{name,status,id});
  }


  createUser(added_by1,email, first_name,last_name,mobile_no,role_id,store_id,id="",role_vendor,storesmul){
    var user_id = localStorage.getItem('user_id'); 
    var added_by = localStorage.getItem('user_id'); 
    if(id){
      var user_url = 'update_nuflorist_user';
    }
    else{
      var user_url = 'register_nuflorist_user';
    }

    return this.http.post(`${environment.apiUrl}/auth/${user_url}`,{added_by,email,first_name,last_name,mobile_no,role_id,store_id,id,role_vendor,storesmul,user_id});
  }

  getUserList(store_id){
    var user_id = localStorage.getItem('user_id'); 
    var store_ID = localStorage.getItem('store_id'); 
    return this.http.post(`${environment.apiUrl}/auth/get_nuflorist_user`,{user_id,store_ID});
  }
  
  createUserRole(store_id,form_data){
    var user_id = localStorage.getItem('user_id');
    return this.http.post(`${environment.apiUrl}/store_roles/create`,{form_data,user_id});
  }
	
	createTax(store_id,tax_name,tax_value,tax_id){
    var user_id = localStorage.getItem('user_id'); 
   return this.http.post(`${environment.apiUrl}/profile/createtax/${user_id}`,{tax_name,tax_value,tax_id});
  }

  getUserRole(store_id){
    var user_id = localStorage.getItem('user_id'); 
    return this.http.post(`${environment.apiUrl}/store_roles/get`,{user_id,store_id});
  }

	deleteemployeeTax(id)
  {
      return this.http.delete(`${environment.apiUrl}/profile/deleteemployeetax/${id}`);
  }
  
  getempbyid(id)
  {
      return this.http.get(`${environment.apiUrl}/profile/getempbyid/${id}`);
  }

  deleteemployee(id)
  {
      return this.http.delete(`${environment.apiUrl}/profile/deleteemployee/${id}`);
  }


  getUserById(user_id){
    return this.http.post(`${environment.apiUrl}/auth/get_nuflorist_user_single`,{user_id});
  }
  

  deleteUser(id)
  {
      return this.http.post(`${environment.apiUrl}/auth/delete_nuflorist_user`,{id});
  }  
  
  getRole(id)
  {
      return this.http.post(`${environment.apiUrl}/auth/get_nuflorist_user_role`,{id});
  }

  getDepartmentSingle(id){
    return this.http.get(`${environment.apiUrl}/department/getSingle/${id}`);
  }
  getDepartmentschSingle(id){
    return this.http.get(`${environment.apiUrl}/department/getSingletimeformat/${id}`);
  }

  deleteDepartment(id)
  {
      return this.http.post(`${environment.apiUrl}/department/delete`,{id});
  } 

  deleteRole(id)
  {
      return this.http.post(`${environment.apiUrl}/auth/delete_nuflorist_user_role`,{id});
  }
  getVendors(store_id,keyword1='',keyword2=''): Observable<any> {
    var user_id = localStorage.getItem('user_id'); 
      return this.http.get(`${environment.apiUrl}/profile/getvendors/${store_id}/${user_id}?q1=${keyword1}&q2=${keyword2}`);
  }
	 getvendorbyid(id)
  {
      return this.http.get(`${environment.apiUrl}/profile/getvendorbyid/${id}`);
  }
	updatevendor(store_id,vendor_id,vendor_name,status,category,account,contact_person,contact_number,alt_contact_number,addressline1,addressline2,city,state,zip,vendorlogin,vendornotes){
    var user_id = localStorage.getItem('user_id'); 
    return this.http.post(`${environment.apiUrl}/profile/updatevendorbyid`, {user_id,vendor_id,vendor_name,status,category,account,contact_person,contact_number,alt_contact_number,addressline1,addressline2,city,state,zip,vendorlogin,vendornotes} );
  }
	
	create_store_percent(store_id,cof_retail,cof_wire,cof_weddings,cof_wholesale,cof_payout,cog_retail,cog_wire,cog_weddings,cog_wholesale,cog_payout,labor){
    return this.http.post(`${environment.apiUrl}/store_percent/create`,{store_id,cof_retail,cof_wire,cof_weddings,cof_wholesale,cof_payout,cog_retail,cog_wire,cog_weddings,cog_wholesale,cog_payout,labor});
  }
	getStoresPercent(store_id){
    return this.http.post(`${environment.apiUrl}/store_percent/get`,{store_id});
  }
  getcatSingle(id){
    return this.http.get(`${environment.apiUrl}/department/getcatSingle/${id}`);
  }

  getuserStores(){
    var user_id = localStorage.getItem('user_id'); 
    return this.http.post(`${environment.apiUrl}/store_percent/getuserStores`,{user_id});
  }
  getmergebyid(id)
  {
      return this.http.get(`${environment.apiUrl}/profile/getmergebyid/${id}`);
  }

  merge_vendor(merge_v_from,merge_v_to){
    return this.http.post(`${environment.apiUrl}/profile/merge_Vendor`,{merge_v_from,merge_v_to});
  }

  getschedule_format(){
    var user_id = localStorage.getItem('user_id'); 
    return this.http.get(`${environment.apiUrl}/profile/getschedule_format/${user_id}`);
  }

	
}
