import { Component, ViewChild, OnInit } from '@angular/core';
import { StoreSubscriberService } from "../_services/storeSubscriber.service";
import { UtilsService } from "../_services/utils.service";
import { WeekPanelService } from "../_services/weekPanel.service";
import { ActivatedRoute } from '@angular/router';
import {first} from "rxjs/operators";
// import {DialogModule} from 'primeng/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ConfirmationService} from 'primeng/api';

import { MessageToastService } from "../_services/messageToast.service";
import {__param} from "tslib";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as $ from 'jquery';
declare  var jQuery:  any;
import { AuthGuard } from "../_helpers/auth.guard";
import { SalesService } from "../_services/sales.service";
@Component({
  selector: 'app-week-panel',
  templateUrl: './week-panel.component.html',
  styleUrls: ['./week-panel.component.less'],
  providers: [ConfirmationService]
})
export class WeekPanelComponent implements OnInit {
	@ViewChild('closeInvoiceModal') closeInvoiceModal;
  @ViewChild('closeVendorModal') closeVendorModal;
  selectedStorage: any;
  yearQuarter: any;
  monday: any;
  role_vendor: any;
  tuesday: any;
  vendoralready:any;
  wednesday: any;
  thursday: any;
  friday: any;
  vendorloading = false;
  saturday: any;
  sunday: any;
  dailyRevenueTotal: any;
  weekList: any[];
  selectedWeekItem: any;
  costOf: string;
  vendorsubmitted = false;
  visible:boolean = false;
  formloading:boolean = false;
  invoiceloading:boolean = false;
  visibleDialogNote: boolean = false;
  visibleVendor: boolean = false;
  visibleNoWeeks: boolean = false;
  title:string;
  dialogValues : any;
  sel_invoice_date : any;
  invoiceformsum: number;
  projected_value: number;
  fresh_budget: number;
  totalwithoutdel: number;
  total_actual_cost: number;
  actualfinalprice: number;
  projected_budget_value: number;
  actual_budget_value: number;
  actual_budget_value1: number;
  saleformsum: number;
  dayform: FormGroup;
  noteform: FormGroup;
  vendorform: FormGroup;
  vendorModelForm: FormGroup;
  submitted = false;
  submittedFormNote: boolean = false;
  submittedVendor: boolean = false;
  catLoading:boolean = false;
  error = '';
  yearSelected: string;
  noteyears : any[];
  allnotes: any[] = [];
  notesYearSelected : any[];
  oldNotes:any[];
  cols : any[];
  noteDelete : string = '';
  oldYear: any;
  showMore: boolean[] = Array(this.allnotes.length).fill(false); 

  //------invoices
  invoices: any[];
  invoiceTotal: number;
  invoicesTableCols: any[];
  invoiceNumber_add : any;
  invoiceName_add : string;
  invoiceTotal_add : any;

  //------week resume
  projWeeklyRev: number;
  target: number;
  yeartoselect: any;
  
  budgetRemaining : number;
  isMasterSel: boolean = false;
  invoiceAddEdit = '';
  invoiceAddEditBtn = '';
  WeekItemprev = "0";
  WeekItemnext = "0";
  note_id = '';
  edit_note = false;
  noteAddEdit = '';
  noteAddEditBtn = '';
  userPermission = [];
  pageLoading = true;
	invoiceTotalfresh = 0.00;
  vendorOpen = false;
	vendorName = "";
	selectedVendor = "";
	vendorList = [];
  categoryList = [];
  vendorError= '';
  actualBudget: number;
  from_to_date = "";
  constructor(
      private formBuilder: FormBuilder,
      private storeSubscriberService: StoreSubscriberService,//service used to receive store from top bar stores combobox
      private utilService: UtilsService,
      private weekPanelService: WeekPanelService,
      private route: ActivatedRoute,
      private messageToastService: MessageToastService,
      private confirmationService: ConfirmationService,
      private authGuard : AuthGuard,
      private salesService: SalesService,
  )
  {
      this.authGuard.userPermissions('fresh');   
      storeSubscriberService.subscribe(this,function (ref,store) {
        ref.receiveStorage(store);
      });

      var yq = JSON.parse(localStorage.getItem('yearQuarter'));
      let currentYear = (yq != undefined && yq.year != undefined) ? yq.year : this.utilService.GetCurrentYear();

      this.resetYearQuarter(currentYear);
      // this.yearQuarter = {year : currentYear, quarter: 1};
      this.yearSelected = this.yearQuarter.year;
      this.monday = {'id': -1, 'amt': 0.00};
      this.tuesday = {'id': -1, 'amt': 0.00};
      this.wednesday = {'id': -1, 'amt': 0.00};
      this.thursday = {'id': -1, 'amt': 0.00};
      this.friday = {'id': -1, 'amt': 0.00};
      this.saturday = {'id': -1, 'amt': 0.00};
      this.sunday = {'id': -1, 'amt': 0.00};
      this.dailyRevenueTotal = 0.00;
      this.selectedWeekItem = "3";
      this.invoiceTotal = 0.00;
	   this.selectedWeekItem = "3";
       this.WeekItemprev = "0";
       this.WeekItemnext = "0";
      this.projWeeklyRev = 0.00;
      this.target = 0.00;
      this.saleformsum = 0.00;
      this.invoiceformsum = 0.00;
      this.projected_value = 0.00;
      this.fresh_budget = 0.00;
      this.totalwithoutdel = 0.00;
      this.total_actual_cost = 0.00;
      this.actualfinalprice = 0.00;

      this.dialogValues = {'id':0,'retail': 0.00,'wire': 0.00,'delivery': 0.00,'widding_events': 0.00,'wholesale':0.00,'entered_date':''};
      
  }

  resetYearQuarter(year_)
  {
      this.yearQuarter = {year : year_, quarter: 1};
  }
  ngOnInit() {
    this.role_vendor = localStorage.getItem('role_vendor');
    const permissions = this.authGuard.userPermissions('fresh');
    this.userPermission = permissions;
    document.body.classList.remove('loginbody');
	(function ($) {
		$(document).ready(function(){
			$("#invoice_date").datepicker( {
				dateFormat : "yy-mm-dd"
			});
			
			var owl = $('.owl-carousel-icons2');
			owl.owlCarousel({
				loop: false,
				rewind: false,
				margin: 0,
				animateIn: 'fadeInDowm',
				animateOut: 'fadeOutDown',
				autoplayTimeout: 5000, // set value to change speed
				autoplayHoverPause: true,
				dots: false,
				nav: true,
				autoplay: false,
				responsiveClass: true,
				responsive: {
					0: {
						items: 1,
						nav: true
					},
					600: {
						items: 7,
						nav: true
					},
					1300: {
						items: 7,
						nav: true
					}
				}
			});
		});
	})(jQuery);
	//this.sel_invoice_date = $('#invoice_date').val();
    this.dayform = this.formBuilder.group({
      id: [''],
      entered_date: [''],
      retail: ['', Validators.required],
      wire: ['',  Validators.required],
      delivery: ['',Validators.required],
      widding_events: ['',Validators.required],
      wholesale: ['',Validators.required],
    });

    this.noteform = this.formBuilder.group({
      text: ['', Validators.required],
    });

    this.vendorform = this.formBuilder.group({
		id: [''],
		invoice_name: ['', Validators.required],
		invoice_number: ['', Validators.required],
		fresh: ['', Validators.required],
		goods: ['', Validators.required],
		suppliers: ['', Validators.required],
		//fresh: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
		//goods: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
		//suppliers: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
		invoice_date: ['', Validators.required],
		//delivery: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
		delivery: ['', Validators.required],
    });

    this.vendorModelForm = this.formBuilder.group({
      v_name: ['', Validators.required],
      category: ['', Validators.required],
      account: ['', Validators.required],
      contact_person: ['', Validators.required],
      contact_number: ['', Validators.required],
      alt_contact_number: [''],
      addressline1: ['', Validators.required],
      addressline2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      vendorlogin: ['', Validators.required],
      vendornotes: ['', Validators.required],
      });

    this.selectedStorage = JSON.parse(localStorage.getItem('selectedStorage'));
    this.route.params.subscribe(params => {
        this.selectedWeekItem = params['id'];
    });
    this.route.params.subscribe(params => {
      this.costOf = params['cost_of'];
    });
	this.getWeekDataFromServer();
   // this.getWeeks(); //commented on April 4, 2020
    this.loadInvoicesTableHeaders();
    this.loadHeaders();
    this.getVendors();
    this.getCategories();
	setInterval(() => {
		this.sel_invoice_date = $('#invoice_date').val();
  }, 100);
  /* console.log(this.calcDailyTotal());
  console.log(this.projected_value);
  this.budgetRemaining = this.calcDailyTotal() - this.projected_value; */
  }

  onRowNoteSelect(event) {
    //console.log(event.data.id);
    this.noteDelete = event.data.id;
  }

  onRowNoteUnselect(event)
  {
     this.noteDelete = '';
  }

  deleteInvoice(id)
  {
     this.confirmDeleteInvoice(id);
  }

  confirmDeleteInvoice(id:string) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete the invoice ?',
      accept: () => {
        this.weekPanelService.deleteInvoice(id).subscribe((response: any) =>{
          this.messageToastService.sendMessage('success','Vendor Message','One invoice was deleted !');
          this.getInvoices(1);
        });
      }
    });
  }

  deleteNote()
  {
    if(this.noteDelete == '')
      this.messageToastService.sendMessage('error','Note Message','Select a note to delete !');
    else
    {
       this.confirm();
    }
  }

  confirm() {
    this.confirmationService.confirm({
        message: 'Are you sure that you want to delete the note ?',
        accept: () => {
          this.weekPanelService.deleteNote(this.noteDelete).subscribe((response: any) =>{
            this.messageToastService.sendMessage('success','Note Message','One note was deleted !');
            this.getNotes();
          });
        }
    });
  }
  addvendor()
	{
		this.closeInvoiceModal.nativeElement.click();
	}


  loadHeaders(){
    this.cols = [
      { field: 'id', header: 'asd' },
      { field: 'text', header: 'asdsa' },
    ];
  }

  receiveYearQuarter($event){
    console.log("receive yearquarter")
    this.oldYear = this.yearQuarter.year;
    this.yearQuarter = $event;
    console.log(this.yearQuarter);
    this.getWeeks();
  }
  receiveStorage(storage){
    console.log("receive store")
    this.selectedStorage = storage;
    this.getWeeks();
    this.getWeekDataFromServer();
    this.loadInvoicesTableHeaders();
    this.loadHeaders();
    this.getVendors();
    this.getCategories();
  }

  calcDailyTotal = function () {
    this.dailyRevenueTotal =
        parseFloat(this.monday.amt) +
        parseFloat(this.tuesday.amt) +
        parseFloat(this.wednesday.amt) +
        parseFloat(this.thursday.amt) +
        parseFloat(this.friday.amt) +
        parseFloat(this.saturday.amt) +
        parseFloat(this.sunday.amt);
    return this.dailyRevenueTotal;
  }

  getSevenDays = function () {
    this.pageLoading = true;
	console.log("wk",this.selectedWeekItem,this.yearSelected);
	  this.WeekItemprev = this.selectedWeekItem - 1;
	  this.WeekItemnext = parseInt(this.selectedWeekItem) + 1;
	  
	
    this.weekPanelService.getSevenDays_v2(this.selectedStorage.id,this.yearSelected,this.selectedWeekItem).subscribe((response: any) =>{
      this.from_to_date = response.from_to_date;
    this.pageLoading = false;
		console.log('aaaa');
		console.log(response);
		console.log('aadddd');
		this.projected_value = response.projected_value;
		this.fresh_budget = response.fresh_budget.toFixed(2);
		this.totalwithoutdel = response.totalwithoutdel.toFixed(2);
		this.total_actual_cost = response.total_actual_cost.toFixed(2);
		 this.actualfinalprice = this.total_actual_cost - this.invoiceTotalfresh;
		this.projected_budget_value = ((20/ 100) * this.projected_value).toFixed(2);
        let seven_d_w = response.seven_days_week;
        console.log("pepe",seven_d_w)
        // console.log(seven_d_w)
        // console.log(seven_d_w);
        if (Array.isArray(seven_d_w) && seven_d_w.length > 0) {
          let mond = this.getDay(seven_d_w,"Monday");
          if(mond != undefined){
			this.monday = {
			  'id': mond.id,
			  'amt_formatted': '$' + this.formatMoney(mond.total),
			  'amt': parseFloat(mond.total),
			  'dates_dim_date': mond.sel_date_num,
			  'sel_day': mond.day_of_week,
			  'retail':mond.retail,
			  'wire':mond.wire,
			  'delivery':mond.delivery,
			  'widding_events':mond.widding_events,
        'wholesale':mond.wholesale,
			  'entered_date':mond.entered_date
			};
          }
          let tues = this.getDay(seven_d_w,"Tuesday");
          if(tues != undefined){
            this.tuesday = {
              'id': tues.id,
			  'amt_formatted': '$' + this.formatMoney(tues.total),
			  'amt': parseFloat(tues.total),
			  'dates_dim_date': tues.sel_date_num,
			  'sel_day': tues.day_of_week,
			  'retail':tues.retail,
			  'wire':tues.wire,
			  'delivery':tues.delivery,
			  'widding_events':tues.widding_events,
        'wholesale':tues.wholesale,
			  'entered_date':tues.entered_date
            };
          }
          let wed = this.getDay(seven_d_w,"Wednesday");
          if(wed != undefined){
            this.wednesday = {
              'id': wed.id,
			  'amt_formatted': '$' + this.formatMoney(wed.total),
			  'amt': parseFloat(wed.total),
			  'dates_dim_date': wed.sel_date_num,
			  'sel_day': wed.day_of_week,
			  'retail':wed.retail,
			  'wire':wed.wire,
			  'delivery':wed.delivery,
			  'widding_events':wed.widding_events,
        'wholesale':wed.wholesale,
			  'entered_date':wed.entered_date
            };
          }
          
          let thur = this.getDay(seven_d_w,"Thursday");
          if(thur != undefined){
            this.thursday = {
              'id': thur.id,
			  'amt_formatted': '$' + this.formatMoney(thur.total),
			  'amt': parseFloat(thur.total),
			  'dates_dim_date': thur.sel_date_num,
			  'sel_day': thur.day_of_week,
			  'retail':thur.retail,
			  'wire':thur.wire,
			  'delivery':thur.delivery,
			  'widding_events':thur.widding_events,
        'wholesale':thur.wholesale,
			  'entered_date':thur.entered_date
            };
          }
         
          let fri = this.getDay(seven_d_w,"Friday");
          if(fri != undefined){
            this.friday = {
              'id': fri.id,
			  'amt_formatted': '$' + this.formatMoney(fri.total),
			  'amt': parseFloat(fri.total),
			  'dates_dim_date': fri.sel_date_num,
			  'sel_day': fri.day_of_week,
			  'retail':fri.retail,
			  'wire':fri.wire,
			  'delivery':fri.delivery,
			  'widding_events':fri.widding_events,
        'wholesale':fri.wholesale,
			  'entered_date':fri.entered_date
            };
          }
          let sat = this.getDay(seven_d_w,"Saturday");
          if(sat != undefined){
			this.saturday = {
			  'id': sat.id,
			  'amt_formatted': '$' + this.formatMoney(sat.total),
			  'amt': parseFloat(sat.total),
			  'dates_dim_date': sat.sel_date_num,
			  'sel_day': sat.day_of_week,
			  'retail':sat.retail,
			  'wire':sat.wire,
			  'delivery':sat.delivery,
			  'widding_events':sat.widding_events,
        'wholesale':sat.wholesale,
			  'entered_date':sat.entered_date
			};
          }
          let sun = this.getDay(seven_d_w,"Sunday");
          if(sun != undefined){
            this.sunday = {
              'id': sun.id,
			  'amt_formatted': '$' + this.formatMoney(sun.total),
			  'amt': parseFloat(sun.total),
			  'dates_dim_date': sun.sel_date_num,
			  'sel_day': sun.day_of_week,
			  'retail':sun.retail,
			  'wire':sun.wire,
			  'delivery':sun.delivery,
			  'widding_events':sun.widding_events,
        'wholesale':sun.wholesale,
			  'entered_date':sun.entered_date
            };
          }
          this.calcDailyTotal();
        
        } else {
          this.clearSevenDays();
        }
    })
  }

  getDay(seven_d_w, stringday){
    let day = seven_d_w.find(element => element.day_of_week == stringday);
    return day;
  }

  getWeeks = function (year?: any) {
    var year = (arguments.length == 0 || year == undefined) ? this.yearQuarter.year : arguments[0];

    this.weekPanelService.getWeeks(year).subscribe((response: any) =>{
      console.log(response);
    this.weekList = response.weeks;
    //this.from_to_date = response.from_to_date;  
    if(this.weekList != undefined && this.weekList.length == 0) {
      this.yeartoselect = ""+this.oldYear;
      this.showDialogNoWeeks();
    }
    else
      this.selectedWeekItem = this.weekList[0].id;
      console.log("selected",this.selectedWeekItem)
      this.getWeekDataFromServer();
    });

  }

  getInvoices = function(check:number){
      this.weekPanelService.getInvoices_v2(this.costOf,this.selectedStorage.id,this.selectedWeekItem,this.yearQuarter.year).subscribe((response: any) =>{
        this.invoices = response.invoices;
		(function ($) {
			$(document).ready(function(){
				if(check == 0)
				{
					$('#invoicelist').DataTable({
						language: {
							searchPlaceholder: 'Search...',
							sSearch: '',
							lengthMenu: '_MENU_ items/page',
						},
						data:response.invoices
					});
				} else {
					$('#invoicelist').DataTable().clear().rows.add(response.invoices).draw();
				}
			});
		})(jQuery);
        this.calcInvoiceTotal();
      });
	  
  }

  getNotes = function(){
    this.weekPanelService.getNotes_v2(this.selectedStorage.id,this.selectedWeekItem,this.yearQuarter.year).subscribe((response: any) =>{
		this.noteyears = response.result.years;
		this.allnotes = response.result.notes;
      this.notesYearSelected = response.result.noteYearSelected;
      this.oldNotes = response.result.oldNotes;
    });
  }

  calcInvoiceTotal = function () {
    this.invoiceTotal = this.invoiceTotalfresh  = 0.00;
    for (var i = 0; i < this.invoices.length; i++) {
      //this.invoiceTotal += parseFloat(this.invoices[i]['cost_of'].replace('$',''));
		 //console.log(parseFloat(this.invoices[i]['cost_of'].replace('$','')))
		 var dollarremove= this.invoices[i]['cost_of'].replace('$','');
      dollarremove= parseFloat(dollarremove.replace(',',''));
      this.invoiceTotal += dollarremove;
		
		 var dollarremovefresh= this.invoices[i]['invoice']['fresh'].replace('$','');
        dollarremovefresh= parseFloat(dollarremovefresh.replace(',',''));
        this.invoiceTotalfresh += dollarremovefresh;
		 this.actualfinalprice = this.total_actual_cost - this.invoiceTotalfresh;
		
		
		
    }
	  
	  this.actual_budget_value1 = this.invoiceTotalfresh.toFixed(2)/this.projected_budget_value *100;
	 	
          console.log(this.actual_budget_value1);
			
    return this.invoiceTotal;
  }

  formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - (+i)).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
  }

  clearSevenDays = function () {
    this.monday = {'id': -1, 'amt': 0.00};
    this.tuesday = {'id': -1, 'amt': 0.00};
    this.wednesday = {'id': -1, 'amt': 0.00};
    this.thursday = {'id': -1, 'amt': 0.00};
    this.friday = {'id': -1, 'amt': 0.00};
    this.saturday = {'id': -1, 'amt': 0.00};
    this.sunday = {'id': -1, 'amt': 0.00};
    this.dailyRevenueTotal = 0.00;
   // this.saveDays_btnDisable = true;
  }

  getWeekDataFromServer() {
    this.getSevenDays();
    //this.getProjWeeklyRev();
    this.getInvoices(0);
    //this.getTarget();
    this.getNotes();
  }

  loadInvoicesTableHeaders(){
    this.invoicesTableCols = [
      { field: 'invoice_name', header: 'Vendor Name' },
      { field: 'invoice_number', header: 'Invoice Number' },
      { field: 'total', header: 'Fresh Total' },
      //{ field: 'actions', header: 'Actions' }
    ];
  }
	
  createInvoice = function () {
    this.submittedVendor = true;
    this.invoiceloading = true;

    if (this.vendorform.invalid) {
      this.invoiceloading = false;
      return;
    }
    const vendorMessage = this.v.id.value != '' ? 'An invoice was updated !' : 'An invoice was created !';
    this.weekPanelService.createInvoice_v2(this.selectedStorage.id, this.v.invoice_name.value, this.v.invoice_number.value, this.v.fresh.value, this.v.goods.value, this.v.invoice_date.value, this.v.delivery.value, this.v.suppliers.value, this.v.id.value)
        .pipe(first())
        .subscribe(
            data => {
              this.messageToastService.sendMessage('success','Vendor Message',vendorMessage);
			  this.cleanInv();
              this.getInvoices(1);
              this.calcInvoiceTotal();
              this.submittedVendor = false;
              this.closeInvoiceModal.nativeElement.click();
              this.invoiceloading = false;
				
            },
            error => {
              this.submittedVendor = false;
              this.visibleVendor = false;
              this.invoiceloading = false;
              console.log(error);
              // this.error = error;
              // this.loading = false;
            });
  }

  getProjWeeklyRev = function () {
    this.weekPanelService.getProjWeeklyRev(this.selectedStorage.id,this.selectedWeekItem).subscribe((response: any) =>{
      this.projWeeklyRev = response.proj_weekly_rev;
      console.log(response)
    });
  }
  getTarget = function () {
    this.weekPanelService.getTarget(this.costOf,this.selectedStorage.id).subscribe((response: any) =>{

      this.target = (this.costOf == 'fresh') ? response['target_cof'] : ((this.costOf == 'goods') ? response['target_cog'] : 0.00) ;
	});
  }

  getTargetInMoney = function () {
    return this.calcDailyTotal() * (this.target / 100);
  }

  showEditDay(title:string) {
    this.title = title;
	  this.submitted = false;
    if(title=='Monday')
    {
        this.dialogValues.id = this.monday.id;
        this.dialogValues.retail = this.monday.retail;
        this.dialogValues.wire = this.monday.wire;
        this.dialogValues.delivery = this.monday.delivery;
        this.dialogValues.widding_events = this.monday.widding_events;
        this.dialogValues.wholesale = this.monday.wholesale;
        this.dialogValues.entered_date = this.monday.entered_date;
		this.saleformsum = parseFloat(this.monday.retail)+parseFloat(this.monday.wire)+parseFloat(this.monday.delivery)+parseFloat(this.monday.widding_events)+parseFloat(this.monday.wholesale);
    }
    else if(title=='Tuesday')
    {
        this.dialogValues.id = this.tuesday.id;
        this.dialogValues.retail = this.tuesday.retail;
        this.dialogValues.wire = this.tuesday.wire;
        this.dialogValues.delivery = this.tuesday.delivery;
        this.dialogValues.widding_events = this.tuesday.widding_events;
        this.dialogValues.wholesale = this.tuesday.wholesale;
        this.dialogValues.entered_date = this.tuesday.entered_date;
		this.saleformsum = parseFloat(this.tuesday.retail)+parseFloat(this.tuesday.wire)+parseFloat(this.tuesday.delivery)+parseFloat(this.tuesday.widding_events)+parseFloat(this.tuesday.wholesale);
    }
    else if(title=='Wednesday')
    {
        this.dialogValues.id = this.wednesday.id;
        this.dialogValues.retail = this.wednesday.retail;
        this.dialogValues.wire = this.wednesday.wire;
        this.dialogValues.delivery = this.wednesday.delivery;
		this.dialogValues.widding_events = this.wednesday.widding_events;
    this.dialogValues.wholesale = this.wednesday.wholesale;
		this.dialogValues.entered_date = this.wednesday.entered_date;
		this.saleformsum = parseFloat(this.wednesday.retail)+parseFloat(this.wednesday.wire)+parseFloat(this.wednesday.delivery)+parseFloat(this.wednesday.widding_events) +parseFloat(this.wednesday.wholesale);
    }
    else if(title=='Thursday')
    {
        this.dialogValues.id = this.thursday.id;
        this.dialogValues.retail = this.thursday.retail;
        this.dialogValues.wire = this.thursday.wire;
        this.dialogValues.delivery = this.thursday.delivery;
		this.dialogValues.widding_events = this.thursday.widding_events;
    this.dialogValues.wholesale = this.thursday.wholesale;
		this.dialogValues.entered_date = this.thursday.entered_date;
		this.saleformsum = parseFloat(this.thursday.retail)+parseFloat(this.thursday.wire)+parseFloat(this.thursday.delivery)+parseFloat(this.thursday.widding_events)+parseFloat(this.thursday.wholesale);
    }
    else if(title=='Friday')
    {
        this.dialogValues.id = this.friday.id;
        this.dialogValues.retail = this.friday.retail;
        this.dialogValues.wire = this.friday.wire;
        this.dialogValues.delivery = this.friday.delivery;
		this.dialogValues.widding_events = this.friday.widding_events;
    this.dialogValues.wholesale = this.friday.wholesale;
		this.dialogValues.entered_date = this.friday.entered_date;
		this.saleformsum = parseFloat(this.friday.retail)+parseFloat(this.friday.wire)+parseFloat(this.friday.delivery)+parseFloat(this.friday.widding_events)+parseFloat(this.friday.wholesale);
    }
    else if(title=='Saturday')
    {
        this.dialogValues.id = this.saturday.id;
        this.dialogValues.retail = this.saturday.retail;
        this.dialogValues.wire = this.saturday.wire;
        this.dialogValues.delivery = this.saturday.delivery;
		this.dialogValues.widding_events = this.saturday.widding_events;
    this.dialogValues.wholesale = this.saturday.wholesale;
		this.dialogValues.entered_date = this.saturday.entered_date;
		this.saleformsum = parseFloat(this.saturday.retail)+parseFloat(this.saturday.wire)+parseFloat(this.saturday.delivery)+parseFloat(this.saturday.widding_events) +parseFloat(this.saturday.wholesale);
    }
    else if(title=='Sunday')
    {
        this.dialogValues.id = this.sunday.id;
        this.dialogValues.retail = this.sunday.retail;
        this.dialogValues.wire = this.sunday.wire;
        this.dialogValues.delivery = this.sunday.delivery;
		this.dialogValues.widding_events = this.sunday.widding_events;
    this.dialogValues.wholesale = this.sunday.wholesale;
		this.dialogValues.entered_date = this.sunday.entered_date;
		this.saleformsum = parseFloat(this.sunday.retail)+parseFloat(this.sunday.wire)+parseFloat(this.sunday.delivery)+parseFloat(this.sunday.wholesale);
    }
    this.visible = true;
  }

	keyUpFunction(event)
	{
		if(event.keyCode == 13)
		{
			this.updateDay();
		}
		this.setSaleForSum();
		//this.saleformsum = parseFloat(this.saleformsum);
	}
    
	setSaleForSum(){
		this.saleformsum = 0;
		if(this.f.retail.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.retail.value);
		if(this.f.wire.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.wire.value);
		if(this.f.widding_events.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.widding_events.value);
      if(this.f.wholesale.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.wholesale.value);
		if(this.f.delivery.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.delivery.value);
	}
	
	keyUpInvoiceFunction(event)
	{
		//this.v.invoice_date.setValue($('#invoice_date').val());
		if(event.keyCode == 13)
		{
			this.createInvoice();
		}
		this.setInvoiceSum();
	}
	
	setInvoiceSum(){
		this.invoiceformsum = 0;
		if(this.v.fresh.value != "")
			this.invoiceformsum = this.invoiceformsum+parseFloat(this.v.fresh.value);
		if(this.v.goods.value != "")
			this.invoiceformsum = this.invoiceformsum+parseFloat(this.v.goods.value);
		if(this.v.suppliers.value != "")
			this.invoiceformsum = this.invoiceformsum+parseFloat(this.v.suppliers.value);
		if(this.v.delivery.value != "")
			this.invoiceformsum = this.invoiceformsum+parseFloat(this.v.delivery.value);
	}
	
	cleanInv(){
	 this.v.id.setValue('');
	 this.v.invoice_name.setValue('');
     this.v.invoice_number.setValue('');
     this.v.fresh.setValue('');
     this.v.goods.setValue('');
     this.v.invoice_date.setValue('');
     this.v.delivery.setValue('');
     this.v.suppliers.setValue('');
     this.invoiceformsum = 0;
	}
  get f() { return this.dayform.controls; }

  get n() { return this.noteform.controls; }

  get v() { return this.vendorform.controls; }
  get vendors() { return this.vendorModelForm.controls; }

  updateDay(){
    this.formloading = true;
    this.submitted = true;
    if (this.dayform.invalid) {
      this.formloading = false;
      return;
    }
    this.error = '';
    
    this.weekPanelService.updateDay_v2(this.selectedStorage.id,this.f.id.value, this.f.entered_date.value, this.f.retail.value, this.f.wire.value, this.f.delivery.value, this.f.widding_events.value,this.f.wholesale.value)
        .pipe(first())
        .subscribe(
            data => {
				this.dayform.controls['retail'].setValue('');
				this.dayform.controls['wire'].setValue('');
				this.dayform.controls['widding_events'].setValue('');
        this.dayform.controls['wholesale'].setValue('');
				this.dayform.controls['delivery'].setValue('');
              this.getSevenDays();
              this.visible = false;
              this.formloading = false;
              this.submitted = false;
              this.messageToastService.sendMessage('success','Day Message','A day was updated !');
              //this.router.navigate([this.returnUrl]);
              this.getProjWeeklyRev();
            },
            error => {
              this.visible = false;
              this.formloading = false;
              console.log(error)
              /*this.error = error;
              this.loading = false;*/
            });
  }

  createNote(){
    this.submittedFormNote = true;

    if (this.noteform.invalid) {
      //this.loading = false;
      return;
    }

    console.log(this.note_id);
    if(this.note_id == ''){
      this.weekPanelService.createNote_v2(this.selectedStorage.id,this.selectedWeekItem,this.yearQuarter.year,this.n.text.value)
          .pipe(first())
          .subscribe(
              data => {
                this.getNotes();
                this.visibleDialogNote = false;
          this.n.text.setValue('');
          
                this.messageToastService.sendMessage('success','Note Message','One note was created !');
              },
              error => {
                this.visibleDialogNote = false;
                console.log(error)
                /*this.error = error;
                this.loading = false;*/
          });
    }
    else{
      this.weekPanelService.updateNote_v2(this.note_id,this.n.text.value)
          .pipe(first())
          .subscribe(
              data => {
                this.getNotes();
                this.visibleDialogNote = false;
          this.n.text.setValue('');
                this.messageToastService.sendMessage('success','Note Message','Note was updated !');
              },
              error => {
                this.visibleDialogNote = false;
                console.log(error)
                /*this.error = error;
                this.loading = false;*/
          });
    }
  }

  showDialogNoWeeks(){
    this.visibleNoWeeks = true;
  }
  onNoWeeksDialogOk(){
    this.yeartoselect = this.oldYear;
    this.getWeeks(this.oldYear);
    this.resetYearQuarter(this.oldYear);
    this.visibleNoWeeks = false;
  }

  calcBudgetRemaining(){
    return this.getTargetInMoney() - this.invoiceTotal;
  }


  



  checkall(values:any) {
		for (var i = 0; i < this.invoices.length; i++) {
			this.invoices[i].is_checked = this.isMasterSel;
    }
    console.log(this.invoices);
	}
	
	checkone() {
		this.isMasterSel = this.invoices.every(function(item:any) {
			return item.is_checked == true;
		})
  }
  
  showEditInvoice(invoice_index){
    console.log(invoice_index);
    console.log(this.invoices[invoice_index]);
    this.invoiceAddEdit = 'Edit';
    this.invoiceAddEditBtn = 'Update';
    
    const invoice_id = this.invoices[invoice_index].invoice.id;
    const invoice_name = this.invoices[invoice_index].invoice.invoice_name;
    const vendor_id = this.invoices[invoice_index].invoice.vendor_id;
    const invoice_number = this.invoices[invoice_index].invoice.invoice_number;
    const fresh = parseFloat(this.invoices[invoice_index].invoice.fresh);
    const goods = parseFloat(this.invoices[invoice_index].invoice.goods);
    const invoice_date = this.invoices[invoice_index].invoice.invoice_date;
    const delivery = parseFloat(this.invoices[invoice_index].invoice.delivery);
    const suppliers = parseFloat(this.invoices[invoice_index].invoice.supplies);
    this.v.id.setValue(invoice_id);
    this.v.invoice_name.setValue(vendor_id);
    this.v.invoice_number.setValue(invoice_number);
    this.v.fresh.setValue(fresh);
    this.v.goods.setValue(goods);
    this.v.invoice_date.setValue(invoice_date);
    this.v.delivery.setValue(delivery);
    this.v.suppliers.setValue(suppliers);
    this.invoiceformsum = Number(Number(fresh) + Number(goods) + Number(delivery) + Number(suppliers));
    //this.createInvoice();
  }


  deleteInvoiceConfirm(invoice_id){
    console.log(invoice_id);
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this invoice!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.weekPanelService.deleteInvoice_v2(invoice_id).subscribe((response: any) =>{
          this.messageToastService.sendMessage('success','Vendor Message','One invoice was deleted !');
          this.getInvoices(1);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  addInvoice(){
    this.invoiceAddEdit = 'Enter';
    this.invoiceAddEditBtn = 'Save';
    this.cleanInv();
  }

  closeInvoice(){
    this.submittedVendor = false;
    this.invoiceloading = false;
  }




  addNote(){
    this.submittedFormNote = false;
    this.note_id = '';
    this.n.text.setValue('');
    this.edit_note = false;
    this.noteAddEdit = 'Create';
    this.noteAddEditBtn = 'Save';
  }


  editNote(id,text){
    this.submittedFormNote = false;
    this.note_id = id;
    this.n.text.setValue(text);
    this.edit_note = true;
    this.noteAddEdit = 'Edit';
    this.noteAddEditBtn = 'Update';
  }


  deleteNoteConfirm(id){
    console.log(id);
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this note!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.weekPanelService.deleteNoteIf(id).subscribe((response: any) =>{
          this.messageToastService.sendMessage('success','Note Message','One note was deleted !');
          this.getNotes();
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  archiveNote(note_id, status){
    status = status == '1' ? '0' : '1';
    if(status == '1'){
      this.weekPanelService.archiveNoteIf(note_id,status).subscribe((response: any) =>{
        this.messageToastService.sendMessage('success','Note Message','One note was unarchived !');
        this.getNotes();
      });
    }
    else{
      Swal.fire({
        title: 'Are you sure want to archive?',
      // text: 'You will not be able to recover this note!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, archive it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.weekPanelService.archiveNoteIf(note_id,status).subscribe((response: any) =>{
            this.messageToastService.sendMessage('success','Note Message','One note was archived !');
            this.getNotes();
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      })
    }
  }

  pinNote(note_id, status){
    status = status == '1' ? '0' : '1';
    console.log(note_id);
    const note_message = status == '1' ? 'One note was pinned!' : 'One note was unpinned!';
    this.weekPanelService.pinNoteIf(note_id,status).subscribe((response: any) =>{
      this.messageToastService.sendMessage('success','Note Message',note_message);
      this.getNotes();
    });
  }
  /* checkUserPermission(type){
		const hasPermission = this.userPermission;
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if(currentUser.isAdmin){
			return true;
		}
		else if (hasPermission.includes(type))
		{
			return true;
		}
		return false; 
  } */

  checkUserPermission(type){
		const hasPermission = this.userPermission;
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if(currentUser.isAdmin){
			return true;
		}
		else if (typeof hasPermission !== "undefined" && hasPermission.includes(type))
		{
			return true;
		}
		else{
			return true;
		}
		return false; 
		
	}
 
      

  openVendorInput(){
		this.vendorError = "";
		console.log('opened');
	  this.vendorOpen = true;
	}

	closeVendorInput(){
		this.vendorError = "";
		console.log('closed');
		this.vendorOpen = false;
	}

	saveVendor(){
		console.log(this.vendorName);
		if(this.vendorName != ''){
			this.salesService.createVendor(this.selectedStorage.id, this.vendorName)
			.pipe(first())
			.subscribe(
				data => {
					this.getVendors();
					this.selectedVendor = data['vendor_id'];
					this.vendorform.get("invoice_name").setValue(this.selectedVendor);
					this.vendorOpen = false;
					
				});
		}
		else{
			this.vendorError = "Vendor Name is required";
		}
	}

	enterVendorName(input){
		this.vendorName = input;
		this.vendorError = "";
		console.log(input);
	}
	

	getVendors(){
		this.salesService.getVendors(this.selectedStorage.id)
        .pipe(first())
        .subscribe(
            data => {
              this.vendorList = data['list'];
            }
		);
	}

	closeVendorForm(){
		this.vendorError = "";
		this.vendorOpen = false;
  }
  calActualBudget(){
    //5 percent of Actual Sales
	  this.actualBudget = this.calcDailyTotal()*20/100;
	  return this.formatMoney(this.actualBudget);
  }
  getCategories(){
		this.salesService.getCategories(this.selectedStorage.id)
        .pipe(first())
        .subscribe(
            data => {
				this.categoryList = data['list'];
			}
		);
	}
  cleanvendor()
	{
	 this.vendors.v_name.setValue('');
     this.vendors.category.setValue('');
     this.vendors.account.setValue('');
     this.vendors.contact_person.setValue('');
     this.vendors.contact_number.setValue('');
     this.vendors.alt_contact_number.setValue('');
     this.vendors.addressline1.setValue('');
	 this.vendors.addressline2.setValue('');
	 this.vendors.city.setValue('');
	 this.vendors.state.setValue('');
	 this.vendors.zip.setValue('');
	 this.vendors.vendorlogin.setValue('');
	 this.vendors.vendornotes.setValue('');
     
	}

  vendorSubmit() {
		
    this.vendorsubmitted = true; 
    if(this.vendors.category.value=='')
    {
      this.catLoading = true;
        return;
    }
    if (this.vendorModelForm.invalid) {
        this.vendorloading = false;
        return;
    }
   
this.catLoading = false;
this.vendoralready='';
    this.vendorloading = true;
this.salesService.create_vendornew(this.selectedStorage.id, this.vendors.v_name.value, this.vendors.category.value, this.vendors.account.value, this.vendors.contact_person.value, this.vendors.contact_number.value, this.vendors.alt_contact_number.value, this.vendors.addressline1.value, this.vendors.addressline2.value, this.vendors.city.value, this.vendors.state.value, this.vendors.zip.value, this.vendors.vendorlogin.value, this.vendors.vendornotes.value)
    .pipe(first())
    .subscribe(
        data => {
    if(data['status']=='false')
    {
        this.vendoralready = data['msg'];
        this.vendorloading = false;
        this.vendorsubmitted = false;
    }
    else
    {
      this.getVendors();
    this.vendoralready ="";
    this.cleanvendor();
    this.closeInvoiceModal.nativeElement.click();
    this.closeVendorModal.nativeElement.click();
    this.vendorloading = false;
    this.vendorsubmitted = false;
    }
  },
        error => {
     
          this.vendorloading = false;
});
}

toggleReadMore(index: number) {
  this.showMore[index] = !this.showMore[index];
}
resizeTextarea(event): void {
  const textarea = event.target;
  textarea.style.height = 'auto'; // Reset the height to auto
  textarea.style.height = `${textarea.scrollHeight}px`; // Set the new height
  }

}
