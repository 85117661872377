import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {retry, catchError} from 'rxjs/operators';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class WeekPanelService{

  constructor(
      private http: HttpClient
  ) { }

  getSevenDays(store_id, week_id): Observable<any> {
     return this.http.get(`${environment.apiUrl}/daily_revenue/seven_days_week/${store_id}/${week_id}`);
  }
  
  getMonthSale(store_id, sel_year, month_id, store_at): Observable<any> {
     return this.http.get(`${environment.apiUrl}/daily_revenue/month_days_v2/${store_id}/${sel_year}/${month_id}/${store_at}`);
  }
  
  getSevenDays_v2(store_id, sel_year, week_id): Observable<any> {
     return this.http.get(`${environment.apiUrl}/daily_revenue/seven_days_week_v2/${store_id}/${sel_year}/${week_id}`);
  }
  
  getWeeks(year): Observable<any> {
    return this.http.get(`${environment.apiUrl}/week/week_by_year_v2/${year}`);
  }
  
  getEmplyees(year, store_id, copyprev): Observable<any> {
    var user_id = localStorage.getItem('user_id'); 
    return this.http.get(`${environment.apiUrl}/week/getemplyees/${year}/${store_id}/${user_id}`);
  }
  copyPrevWeek = function (year,store_id,employees) {
        return this.http.post(`${environment.apiUrl}/week/copyprevweek/${year}/${store_id}`, {employees} );
    }
  getWeekList(year): Observable<any> {
    return this.http.get(`${environment.apiUrl}/week/getWeekList/${year}`);
  }
  
  getInvoices(cost_of,store_id, week_id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/invoice/all/${cost_of}/${store_id}/${week_id}`);
  }
  getInvoices_v2(cost_of,store_id, week_id,sel_year): Observable<any> {
    return this.http.get(`${environment.apiUrl}/invoice/all_v2/${cost_of}/${store_id}/${week_id}/${sel_year}`);
  }
  getNotes(store_id, week_id, year): Observable<any> {
    return this.http.get(`${environment.apiUrl}/note/all/${store_id}/${week_id}/${year}`);
  }
  getNotes_v2(store_id, week_id, year): Observable<any> {
    return this.http.get(`${environment.apiUrl}/note/all_v2/${store_id}/${week_id}/${year}`);
  }
  getNotesMonthly(store_id, month_id, year): Observable<any> {
    return this.http.get(`${environment.apiUrl}/note/allmonthly/${store_id}/${month_id}/${year}`);
  }
  createInvoice(cost_of, invoice_number,invoice_name,total,store_id,week_id) {
    return this.http.post(`${environment.apiUrl}/invoice/create`,
        {
          "invoice_number":  invoice_number,
          "invoice_name":  invoice_name,
          "total":  total,
          "store_id": store_id,
          "week_id": week_id,
          "cost_of": cost_of,
        });

  }
  createInvoice_v2(store_id,invoice_name,invoice_number,fresh,goods,invoice_date,delivery,suppliers,id) {
    return this.http.post(`${environment.apiUrl}/invoice/create_v2/${store_id}`, {invoice_name,invoice_number,fresh,goods,invoice_date,delivery,suppliers,id} );

  }
  deleteInvoice(id)
  {
      return this.http.delete(`${environment.apiUrl}/invoice/delete/${id}`);
  }


  deleteInvoice_v2(id)
  {
      return this.http.delete(`${environment.apiUrl}/invoice/delete_v2/${id}`);
  }
  getProjWeeklyRev(store_id, week_id) : Observable<any> {
    return this.http.get(`${environment.apiUrl}/weekly_projection_percent_revenue/proj_weekly_revenue/${store_id}/${week_id}`);
  }
  getTarget(cost_of,store_id) : Observable<any> {
    return this.http.get(`${environment.apiUrl}/weekly_projection_percent_costs/target/${cost_of}/${store_id}`);
  }

  updateDay(id,merchandise,wire,delivery){
      return this.http.put(`${environment.apiUrl}/daily_revenue/update/${id}`, {merchandise,wire,delivery} );
  }
	
  updateDay_v2(store_id, id,entered_date,retail,wire,delivery,widding_events,wholesale){
    var user_id = localStorage.getItem('user_id'); 
      return this.http.put(`${environment.apiUrl}/daily_revenue/update_v2/${store_id}/${id}/${user_id}`, {entered_date,retail,wire,delivery,widding_events,wholesale} );
  }
  
  deleteNote(id)
  {
    return this.http.delete(`${environment.apiUrl}/note/delete/${id}`);
  }
  
  deleteNoteIf(id)
  {
    return this.http.delete(`${environment.apiUrl}/note/delete_ifnote/${id}`);
  }
  
  archiveNoteIf(id,status)
  {
    return this.http.post(`${environment.apiUrl}/note/archive_ifnote/${id}`,
        {
          "status": status,
        }
    );
  }
  
  pinNoteIf(id,status)
  {
    return this.http.post(`${environment.apiUrl}/note/pin_ifnote/${id}`,
        {
          "pin": status,
        }
    );
  }

  createNote(store_id,week_id,year,text)
  {
    return this.http.post(`${environment.apiUrl}/note/create`,
        {
          "store_id": store_id,
          "week_id": week_id,
          "year": year,
          "text":text
        });
  }
	createNote_v2(store_id,week_id,year,text)
  {
    return this.http.post(`${environment.apiUrl}/note/create_v2`,
        {
          "store_id": store_id,
          "week_id": week_id,
          "year": year,
          "text":text
        });
  }

  updateNote_v2(note_id,text){
    return this.http.post(`${environment.apiUrl}/note/update_v2/`+note_id,
    {
      "note_id": note_id,
      "text":text
    });
  }
}
