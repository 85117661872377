// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//var api_url = 'http://127.0.0.1:8000/api';
//var image_path = 'http://127.0.0.1:8000/image';


//var api_url = 'https://iflorist.io/iflorist-backend/public/api';
//var image_path = 'https://iflorist.io/iflorist-backend/public/image';
var api_url = 'https://dev.iflorist.io/ifsadmin/public/api';
var image_path = 'https://dev.iflorist.io/ifsadmin/public/image';
export const environment = {
    
    production: false,
    apiUrl: api_url,
    imagePath: image_path,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
