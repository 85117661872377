import { Component, ViewChild, OnInit } from '@angular/core';
import { StoreSubscriberService } from "../_services/storeSubscriber.service";
import { UtilsService } from "../_services/utils.service";
import { WeekPanelService } from "../_services/weekPanel.service";
import { ActivatedRoute } from '@angular/router';
import {first} from "rxjs/operators";
// import {DialogModule} from 'primeng/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ConfirmationService} from 'primeng/api';

import { MessageToastService } from "../_services/messageToast.service";
import {__param} from "tslib";

import * as $ from 'jquery';
declare  var jQuery:  any;
import { AuthGuard } from "../_helpers/auth.guard";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SalesService } from "../_services/sales.service";
@Component({
  selector: 'app-month-panel',
  templateUrl: './month-panel.component.html',
  styleUrls: ['./month-panel.component.less'],
  providers: [ConfirmationService]
})
export class MonthPanelComponent implements OnInit {
	@ViewChild('closeInvoiceModal') closeInvoiceModal;
  @ViewChild('closeVendorModal') closeVendorModal;
  UpkeyIndex: number;
  projectedBudget: number;
  differper:number;
  role_vendor: any;
  hardgoodbudget:number;
  total_actualcost_h:number;
  vendorsubmitted = false;
  catLoading:boolean = false;
  actualfinalprice:number;
  selectedStorage: any;
  yearQuarter: any;
  categoryList = [];
  monthsale: any;
  vendoralready:any;
  dailyRevenueTotal: any;
  vendorloading = false;
  dailyRevenueTotalwithoudel: any;
  weekList: any[];
  vendorModelForm: FormGroup;
  selectedMonthItem: any;
  costOf: string;
  visible:boolean = false;
  formloading:boolean = false;
  invoiceloading:boolean = false;
  visibleDialogNote: boolean = false;
  visibleVendor: boolean = false;
  visibleNoWeeks: boolean = false;
  title:string;
  dialogValues : any;
  sel_invoice_date : any;
  invoiceformsum: number;
  projected_value: number;
  saleformsum: number;
  dayform: FormGroup;
  noteform: FormGroup;
  vendorform: FormGroup;
  submitted = false;
  submittedFormNote: boolean = false;
  submittedVendor: boolean = false;
  error = '';
  yearSelected: string;
  noteyears : any[];
  allnotes : any[] = [];
  notesYearSelected : any[];
  oldNotes:any[];
  cols : any[];
  noteDelete : string = '';
  oldYear: any;
 
  //------invoices
  invoices: any[];
  invoiceTotal: number;
  invoicesTableCols: any[];
  invoiceNumber_add : any;
  invoiceName_add : string;
  invoiceTotal_add : any;

  //------week resume
  projWeeklyRev: number;
  target: number;
  yeartoselect: any;
  note_id = '';
  edit_note = false;
  invoiceTotalgoods = 0.00;
  isMasterSel: boolean = false;
  invoiceAddEdit = '';
  invoiceAddEditBtn = '';
  noteAddEdit = '';
  noteAddEditBtn = '';
  userPermission = [];
  loading = true;
  showMore: boolean[] = Array(this.allnotes.length).fill(false); 
  vendorOpen = false;
	vendorName = "";
	selectedVendor = "";
	vendorList = [];
  vendorError= '';
  actualBudget: number;
  from_to_date = '';
  constructor(
      private formBuilder: FormBuilder,
      private storeSubscriberService: StoreSubscriberService,//service used to receive store from top bar stores combobox
      private utilService: UtilsService,
      private weekPanelService: WeekPanelService,
      private route: ActivatedRoute,
      private messageToastService: MessageToastService,
      private confirmationService: ConfirmationService,
      private authGuard : AuthGuard,
      private salesService: SalesService,
  )
  {
      this.authGuard.userPermissions('goods');   
      storeSubscriberService.subscribe(this,function (ref,store) {
        ref.receiveStorage(store);
      });

      var yq = JSON.parse(localStorage.getItem('yearQuarter'));
      let currentYear = (yq != undefined && yq.year != undefined) ? yq.year : this.utilService.GetCurrentYear();

      this.resetYearQuarter(currentYear);
      // this.yearQuarter = {year : currentYear, quarter: 1};
      this.yearSelected = this.yearQuarter.year;
      this.dailyRevenueTotal = 0.00;
      this.dailyRevenueTotalwithoudel = 0.00;
      this.selectedMonthItem = "0";
      this.invoiceTotal = 0.00;
      this.hardgoodbudget = 0.00;
      this.projWeeklyRev = 0.00;
      this.target = 0.00;
      this.saleformsum = 0.00;
      this.invoiceformsum = 0.00;
      this.projected_value = 0.00;
      this.projectedBudget = 0.00;
      this.total_actualcost_h = 0.00;
      this.actualfinalprice = 0.00;

      this.dialogValues = {'index':0, 'id':0,'retail': 0.00,'wire': 0.00,'delivery': 0.00,'widding_events': 0.00,'wholesale':0.00,'entered_date':''};
  }

  resetYearQuarter(year_)
  {
      this.yearQuarter = {year : year_, quarter: 1};
  }
  ngOnInit() {
    const permissions = this.authGuard.userPermissions('goods');
    this.userPermission = permissions;
    document.body.classList.remove('loginbody');
    this.role_vendor = localStorage.getItem('role_vendor');
	//this.sel_invoice_date = $('#invoice_date').val();
    this.dayform = this.formBuilder.group({
      index: [''],
      id: [''],
      entered_date: [''],
      retail: ['', Validators.required],
      wire: ['',  Validators.required],
      delivery: ['',Validators.required],
      widding_events: ['',Validators.required],
      wholesale: ['',Validators.required],
    });

    this.noteform = this.formBuilder.group({
      text: ['', Validators.required],
    });

    this.vendorform = this.formBuilder.group({
    id: [''],
		invoice_name: ['', Validators.required],
		invoice_number: ['', Validators.required],
		fresh: ['', Validators.required],
		goods: ['', Validators.required],
		suppliers: ['', Validators.required],
		//fresh: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
		//goods: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
		//suppliers: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
		invoice_date: ['', Validators.required],
		//delivery: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
		delivery: ['', Validators.required],
    });

    this.vendorModelForm = this.formBuilder.group({
      v_name: ['', Validators.required],
      category: ['', Validators.required],
      account: ['', Validators.required],
      contact_person: ['', Validators.required],
      contact_number: ['', Validators.required],
      alt_contact_number: [''],
      addressline1: ['', Validators.required],
      addressline2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      vendorlogin: ['', Validators.required],
      vendornotes: ['', Validators.required],
      });

    this.selectedStorage = JSON.parse(localStorage.getItem('selectedStorage'));
    this.route.params.subscribe(params => {
        this.selectedMonthItem = params['id'];
    });
    this.route.params.subscribe(params => {
      this.costOf = params['cost_of'];
    });
	this.getMonthDataFromServer();
   // this.getWeeks(); //commented on April 4, 2020
    this.loadInvoicesTableHeaders();
    this.loadHeaders();
    this.getVendors();
    this.getCategories();
	setInterval(() => {
		this.sel_invoice_date = $('#invoice_date').val();
	}, 100);
  }

  onRowNoteSelect(event) {
    ////console.log(event.data.id);
    this.noteDelete = event.data.id;
  }

  onRowNoteUnselect(event)
  {
     this.noteDelete = '';
  }

  deleteInvoice(id)
  {
     this.confirmDeleteInvoice(id);
  }

  confirmDeleteInvoice(id:string) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete the invoice ?',
      accept: () => {
        this.weekPanelService.deleteInvoice(id).subscribe((response: any) =>{
          this.messageToastService.sendMessage('success','Vendor Message','One invoice was deleted !');
          this.getInvoices(1);
        });
      }
    });
  }

  deleteNote()
  {
    if(this.noteDelete == '')
      this.messageToastService.sendMessage('error','Note Message','Select a note to delete !');
    else
    {
       this.confirm();
    }
  }

  confirm() {
    this.confirmationService.confirm({
        message: 'Are you sure that you want to delete the note ?',
        accept: () => {
          this.weekPanelService.deleteNote(this.noteDelete).subscribe((response: any) =>{
            this.messageToastService.sendMessage('success','Note Message','One note was deleted !');
            this.getNotes();
          });
        }
    });
  }

  loadHeaders(){
    this.cols = [
      { field: 'id', header: 'asd' },
      { field: 'text', header: 'asdsa' },
    ];
  }

  receiveYearQuarter($event){
    //console.log("receive yearquarter")
    this.oldYear = this.yearQuarter.year;
    this.yearQuarter = $event;
    //console.log(this.yearQuarter);
    //this.getWeeks();
  }
  receiveStorage(storage){
    //console.log("receive store")
    this.selectedStorage = storage;
    this.getMonthDataFromServer();
    this.loadInvoicesTableHeaders();
    this.loadHeaders();
    this.getVendors();
    this.getCategories();
    //this.getWeeks();
  }
	calcDailyTotal = function()
	{
    this.dailyRevenueTotal = 0.00;
    if(typeof this.monthsale !== 'undefined'){
      for (var i = 0; i < this.monthsale.length; i++) {
        this.dailyRevenueTotal = this.dailyRevenueTotal+parseFloat(this.monthsale[i].total.replace('$',''));
		  
      }
    }
		
		return this.dailyRevenueTotal;
	}
	
  getMonth = function () {
    this.loading = true; 
    this.weekPanelService.getMonthSale(this.selectedStorage.id,this.yearSelected,this.selectedMonthItem,this.selectedStorage.created_at).subscribe((response: any) =>{
		console.log('zzz');
		 console.log(response);
		console.log('zzz');
      this.loading = false; 
      this.from_to_date = response.from_to_date; 
	this.monthsale = response.days_month;
      this.dailyRevenueTotal = response.dailyRevenueTotal;
       this.hardgoodbudget  = response.hardgoodbudget;
      this.dailyRevenueTotalwithoudel = response.dailyRevenueTotalwithoudel;
	 this.dailyRevenueTotal11 = response.dailyRevenueTotal;
      this.projected_value = response.projected_value;
      this.total_actualcost_h = response.total_actualcost_h.toFixed(2);
		this.actualfinalprice = this.total_actualcost_h - this.invoiceTotalgoods;
		
		(function ($) {
			$(document).ready(function(){
				$("#invoice_date").datepicker( {
					dateFormat : "yy-mm-dd"
				});
				
				var owl = $('.owl-carousel-icons2');
				owl.owlCarousel({
					loop: false,
					rewind: false,
					margin: 0,
					animateIn: 'fadeInDowm',
					animateOut: 'fadeOutDown',
					autoplayTimeout: 5000, // set value to change speed
					autoplayHoverPause: true,
					dots: false,
					nav: true,
					autoplay: false,
					responsiveClass: true,
					responsive: {
						0: {
							items: 1,
							nav: true
						},
						600: {
							items: 7,
							nav: true
						},
						1300: {
							items: 7,
							nav: true
						}
					}
				});
			});
		})(jQuery);
	})
  }

  getInvoices = function(check:number){
      this.weekPanelService.getInvoices_v2(this.costOf,this.selectedStorage.id,this.selectedMonthItem,this.yearQuarter.year).subscribe((response: any) =>{
        this.invoices = response.invoices;
		  console.log(response);
		(function ($) {
			$(document).ready(function(){
				if(check == 0)
				{
					$('#invoicelist').DataTable({
						language: {
							searchPlaceholder: 'Search...',
							sSearch: '',
							lengthMenu: '_MENU_ items/page',
						},
						data:response.invoices
					});
				} else {
					$('#invoicelist').DataTable().clear().rows.add(response.invoices).draw();
				}
			});
		})(jQuery);
        this.calcInvoiceTotal();
      });
	  
  }

  getNotes = function(){
    this.weekPanelService.getNotesMonthly(this.selectedStorage.id,this.selectedMonthItem,this.yearQuarter.year).subscribe((response: any) =>{
		this.noteyears = response.result.years;
		this.allnotes = response.result.notes;
      this.notesYearSelected = response.result.noteYearSelected;
      this.oldNotes = response.result.oldNotes;
    });
  }

  calcInvoiceTotal = function () {
    this.invoiceTotal = this.invoiceTotalgoods = 0.00;
    for (var i = 0; i < this.invoices.length; i++) {
      //this.invoiceTotal += parseFloat(this.invoices[i]['cost_of'].replace('$',''));
	  var dollarremove= this.invoices[i]['cost_of'].replace('$','');
      dollarremove= parseFloat(dollarremove.replace(',',''));
      this.invoiceTotal += dollarremove;
		
		 var dollarremovegoods= this.invoices[i]['invoice']['goods'].replace('$','');
        dollarremovegoods= parseFloat(dollarremovegoods.replace(',',''));
        this.invoiceTotalgoods += dollarremovegoods;
		 this.actualfinalprice = this.total_actualcost_h - this.invoiceTotalgoods;
	}
	 
    return this.invoiceTotal;
  }

  formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - (+i)).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      //console.log(e)
    }
  }

  getMonthDataFromServer() {
    this.getMonth();
    //this.getProjWeeklyRev();
    this.getInvoices(0);
    //this.getTarget();
    this.getNotes();
  }

  loadInvoicesTableHeaders(){
    this.invoicesTableCols = [
      { field: 'invoice_name', header: 'Vendor Name' },
      { field: 'invoice_number', header: 'Invoice Number' },
      { field: 'total', header: 'HG Total' },
      { field: 'invoice_date', header: 'Date' }
    ];
  }
	
  createInvoice = function () {
    this.submittedVendor = true;
    this.invoiceloading = true;

    if (this.vendorform.invalid) {
      this.invoiceloading = false;
      return;
    }
    

    const vendorMessage = this.v.id.value != '' ? 'An invoice was updated !' : 'An invoice was created !';
    this.weekPanelService.createInvoice_v2(this.selectedStorage.id, this.v.invoice_name.value, this.v.invoice_number.value, this.v.fresh.value, this.v.goods.value, this.v.invoice_date.value, this.v.delivery.value, this.v.suppliers.value, this.v.id.value)
        .pipe(first())
        .subscribe(
            data => {
              this.messageToastService.sendMessage('success','Vendor Message',vendorMessage);
			  this.cleanInv();
              this.getInvoices(1);
              this.calcInvoiceTotal();
              this.submittedVendor = false;
              this.closeInvoiceModal.nativeElement.click();
              this.invoiceloading = false;
            },
            error => {
              this.submittedVendor = false;
              this.visibleVendor = false;
              this.invoiceloading = false;
              //console.log(error);
              // this.error = error;
              // this.loading = false;
            });
  }

  getProjWeeklyRev = function () {
    this.weekPanelService.getProjWeeklyRev(this.selectedStorage.id,this.selectedMonthItem).subscribe((response: any) =>{
      this.projWeeklyRev = response.proj_weekly_rev;
      //console.log(response)
    });
  }
  getTarget = function () {
    this.weekPanelService.getTarget(this.costOf,this.selectedStorage.id).subscribe((response: any) =>{

      this.target = (this.costOf == 'fresh') ? response['target_cof'] : ((this.costOf == 'goods') ? response['target_cog'] : 0.00) ;
	});
  }

  getTargetInMoney = function () {
    return this.calcDailyTotal() * (this.target / 100);
  }

  showEditDay(keyIndex:number) {
    this.dialogValues.retail = 0;
		this.dialogValues.wire = 0;
		this.dialogValues.delivery = 0;
		this.dialogValues.widding_events = 0;
    this.dialogValues.wholesale = 0;


		this.title = this.monthsale[keyIndex].day_of_week;
		this.dialogValues.index = keyIndex;
		this.dialogValues.id = this.monthsale[keyIndex].id;
		this.dialogValues.retail = this.monthsale[keyIndex].retail;
		this.dialogValues.wire = this.monthsale[keyIndex].wire;
		this.dialogValues.delivery = this.monthsale[keyIndex].delivery;
		this.dialogValues.widding_events = this.monthsale[keyIndex].widding_events;
    this.dialogValues.wholesale = this.monthsale[keyIndex].wholesale;
		this.dialogValues.entered_date = this.monthsale[keyIndex].entered_date;
		this.saleformsum = parseFloat(this.monthsale[keyIndex].retail)+parseFloat(this.monthsale[keyIndex].wire)+parseFloat(this.monthsale[keyIndex].delivery)+parseFloat(this.monthsale[keyIndex].widding_events)+parseFloat(this.monthsale[keyIndex].wholesale);
    
		this.visible = true;
  }

	keyUpFunction(event)
	{
		if(event.keyCode == 13)
		{
			this.updateDay();
		}
		this.setSaleForSum();
		//this.saleformsum = parseFloat(this.saleformsum);
	}
    
	setSaleForSum(){
		this.saleformsum = 0;
		if(this.f.retail.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.retail.value);
		if(this.f.wire.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.wire.value);
		if(this.f.widding_events.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.widding_events.value);
    if(this.f.wholesale.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.wholesale.value);
		if(this.f.delivery.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.delivery.value);
	}
	
	keyUpInvoiceFunction(event)
	{
		//this.v.invoice_date.setValue($('#invoice_date').val());
		if(event.keyCode == 13)
		{
			this.createInvoice();
		}
		this.setInvoiceSum();
	}
	
	setInvoiceSum(){
		this.invoiceformsum = 0;
		if(this.v.fresh.value != "")
			this.invoiceformsum = this.invoiceformsum+parseFloat(this.v.fresh.value);
		if(this.v.goods.value != "")
			this.invoiceformsum = this.invoiceformsum+parseFloat(this.v.goods.value);
		if(this.v.suppliers.value != "")
			this.invoiceformsum = this.invoiceformsum+parseFloat(this.v.suppliers.value);
		if(this.v.delivery.value != "")
			this.invoiceformsum = this.invoiceformsum+parseFloat(this.v.delivery.value);
	}
	
	cleanInv(){
    this.v.id.setValue('');
    this.v.invoice_name.setValue('');
    this.v.invoice_number.setValue('');
    this.v.fresh.setValue('');
    this.v.goods.setValue('');
    this.v.invoice_date.setValue('');
    this.v.delivery.setValue('');
    this.v.suppliers.setValue('');
    this.invoiceformsum = 0;
	}
  get f() { return this.dayform.controls; }

  get n() { return this.noteform.controls; }

  get v() { return this.vendorform.controls; }
  get vendors() { return this.vendorModelForm.controls; }
  updateDay(){
    this.formloading = true;
    this.submitted = true;
    if (this.dayform.invalid) {
      this.formloading = false;
      return;
    }
    this.error = '';
    
    this.weekPanelService.updateDay_v2(this.selectedStorage.id,this.f.id.value, this.f.entered_date.value, this.f.retail.value, this.f.wire.value, this.f.delivery.value, this.f.widding_events.value,this.f.wholesale.value)
        .pipe(first())
        .subscribe(
            data => {
				this.UpkeyIndex = this.f.index.value;
				this.monthsale[this.UpkeyIndex].retail = this.f.retail.value;
				this.monthsale[this.UpkeyIndex].wire = this.f.wire.value;
				this.monthsale[this.UpkeyIndex].delivery = this.f.delivery.value;
				this.monthsale[this.UpkeyIndex].widding_events = this.f.widding_events.value;
        this.monthsale[this.UpkeyIndex].wholesale = this.f.wholesale.value;
				this.monthsale[this.UpkeyIndex].total = '$'+this.formatMoney(this.saleformsum);
              //this.getMonth();
              this.visible = false;
              this.formloading = false;
              this.messageToastService.sendMessage('success','Day Message','A day was updated !');
              //this.router.navigate([this.returnUrl]);
              this.getProjWeeklyRev();
            },
            error => {
              this.visible = false;
              this.formloading = false;
              //console.log(error)
              /*this.error = error;
              this.loading = false;*/
            });
  }

  createNote(){
    this.submittedFormNote = true;

    if (this.noteform.invalid) {
      //this.loading = false;
      return;
    }

    //console.log(this.note_id);
    if(this.note_id == ''){
      this.weekPanelService.createNote_v2(this.selectedStorage.id,this.selectedMonthItem,this.yearQuarter.year,this.n.text.value)
          .pipe(first())
          .subscribe(
              data => {
                this.getNotes();
                this.visibleDialogNote = false;
          this.n.text.setValue('');
                this.messageToastService.sendMessage('success','Note Message','One note was created !');
              },
              error => {
                this.visibleDialogNote = false;
                //console.log(error)
                /*this.error = error;
                this.loading = false;*/
          });
    }
    else{
      this.weekPanelService.updateNote_v2(this.note_id,this.n.text.value)
          .pipe(first())
          .subscribe(
              data => {
                this.getNotes();
                this.visibleDialogNote = false;
          this.n.text.setValue('');
                this.messageToastService.sendMessage('success','Note Message','Note was updated !');
              },
              error => {
                this.visibleDialogNote = false;
                //console.log(error)
                /*this.error = error;
                this.loading = false;*/
          });
    }
  }

  showDialogNoWeeks(){
    this.visibleNoWeeks = true;
  }
  onNoWeeksDialogOk(){
    this.yeartoselect = this.oldYear;
    //this.getWeeks(this.oldYear);
    this.resetYearQuarter(this.oldYear);
    this.visibleNoWeeks = false;
  }

  calcBudgetRemaining(){
    return this.getTargetInMoney() - this.invoiceTotal;
  }
  
  calProjectedBudget(){
    //5 percent of Projected Sales
	  this.projectedBudget = this.projected_value*5/100;
	  return this.formatMoney(this.projectedBudget);
  }



  /* editNote(id,text){
    this.submittedFormNote = false;
    this.note_id = id;
    this.n.text.setValue(text);
    this.edit_note = true;
  }
  

  addNote(){
    this.submittedFormNote = false;
    this.note_id = '';
    this.n.text.setValue('');
    this.edit_note = false;
  } */

  checkall(values:any) {
		for (var i = 0; i < this.invoices.length; i++) {
			this.invoices[i].is_checked = this.isMasterSel;
    }
    //console.log(this.invoices);
	}
	
	checkone() {
		this.isMasterSel = this.invoices.every(function(item:any) {
			return item.is_checked == true;
		})
  }
  
  showEditInvoice(invoice_index){
    //console.log(invoice_index);
    //console.log(this.invoices[invoice_index]);
    this.invoiceAddEdit = 'Edit';
    this.invoiceAddEditBtn = 'Update';
    
    const invoice_id = this.invoices[invoice_index].invoice.id;
    const invoice_name = this.invoices[invoice_index].invoice.invoice_name;
    const vendor_id = this.invoices[invoice_index].invoice.vendor_id;
    const invoice_number = this.invoices[invoice_index].invoice.invoice_number;
    const fresh = parseFloat(this.invoices[invoice_index].invoice.fresh);
    const goods = parseFloat(this.invoices[invoice_index].invoice.goods);
    const invoice_date = this.invoices[invoice_index].invoice.invoice_date;
    const delivery = parseFloat(this.invoices[invoice_index].invoice.delivery);
    const suppliers = parseFloat(this.invoices[invoice_index].invoice.supplies);
    this.v.id.setValue(invoice_id);
    this.v.invoice_name.setValue(vendor_id);
    this.v.invoice_number.setValue(invoice_number);
    this.v.fresh.setValue(fresh);
    this.v.goods.setValue(goods);
    this.v.invoice_date.setValue(invoice_date);
    this.v.delivery.setValue(delivery);
    this.v.suppliers.setValue(suppliers);
    this.invoiceformsum = Number(Number(fresh) + Number(goods) + Number(delivery) + Number(suppliers));
    //this.createInvoice();
  }


  deleteInvoiceConfirm(invoice_id){
    //console.log(invoice_id);
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this invoice!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.weekPanelService.deleteInvoice_v2(invoice_id).subscribe((response: any) =>{
          this.messageToastService.sendMessage('success','Vendor Message','One invoice was deleted !');
          this.getInvoices(1);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  addInvoice(){
    this.invoiceAddEdit = 'Enter';
    this.invoiceAddEditBtn = 'Save';
    this.cleanInv();
  }

  closeInvoice(){
    this.submittedVendor = false;
    this.invoiceloading = false;
  }

  addNote(){
    this.submittedFormNote = false;
    this.note_id = '';
    this.n.text.setValue('');
    this.edit_note = false;
    this.noteAddEdit = 'Create';
    this.noteAddEditBtn = 'Save';
  }


  editNote(id,text){
    this.submittedFormNote = false;
    this.note_id = id;
    this.n.text.setValue(text);
    this.edit_note = true;
    this.noteAddEdit = 'Edit';
    this.noteAddEditBtn = 'Update';
  }


  deleteNoteConfirm(id){
    //console.log(id);
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this note!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.weekPanelService.deleteNoteIf(id).subscribe((response: any) =>{
          this.messageToastService.sendMessage('success','Note Message','One note was deleted !');
          this.getNotes();
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  archiveNote(note_id, status){
    status = status == '1' ? '0' : '1';
    if(status == '1'){
      this.weekPanelService.archiveNoteIf(note_id,status).subscribe((response: any) =>{
        this.messageToastService.sendMessage('success','Note Message','One note was unarchived !');
        this.getNotes();
      });
    }
    else{
      Swal.fire({
        title: 'Are you sure want to archive?',
      // text: 'You will not be able to recover this note!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, archive it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.weekPanelService.archiveNoteIf(note_id,status).subscribe((response: any) =>{
            this.messageToastService.sendMessage('success','Note Message','One note was archived !');
            this.getNotes();
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      })
    }
  }

  pinNote(note_id, status){
    status = status == '1' ? '0' : '1';
    ////console.log(note_id);
    const note_message = status == '1' ? 'One note was pinned!' : 'One note was unpinned!';
    this.weekPanelService.pinNoteIf(note_id,status).subscribe((response: any) =>{
      this.messageToastService.sendMessage('success','Note Message',note_message);
      this.getNotes();
    });
  }

  /* checkUserPermission(type){
		const hasPermission = this.userPermission;
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if(currentUser.isAdmin){
			return true;
		}
		else if (hasPermission.includes(type))
		{
			return true;
		}
		return false; 
  } */

  checkUserPermission(type){
		const hasPermission = this.userPermission;
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if(currentUser.isAdmin){
			return true;
		}
		else if (typeof hasPermission !== "undefined" && hasPermission.includes(type))
		{
			return true;
		}
		else{
			return true;
		}
		return false; 
		
	}
  
  openVendorInput(){
		this.vendorError = "";
		//console.log('opened');
		this.vendorOpen = true;
	}

	closeVendorInput(){
		this.vendorError = "";
		//console.log('closed');
		this.vendorOpen = false;
	}

	saveVendor(){
		//console.log(this.vendorName);
		if(this.vendorName != ''){
			this.salesService.createVendor(this.selectedStorage.id, this.vendorName)
			.pipe(first())
			.subscribe(
				data => {
					this.getVendors();
					this.selectedVendor = data['vendor_id'];
					this.vendorform.get("invoice_name").setValue(this.selectedVendor);
					this.vendorOpen = false;
				});
		}
		else{
			this.vendorError = "Vendor Name is required";
		}
	}

	enterVendorName(input){
		this.vendorName = input;
		this.vendorError = "";
		//console.log(input);
	}
	

	getVendors(){
		this.salesService.getVendors(this.selectedStorage.id)
        .pipe(first())
        .subscribe(
            data => {
              this.vendorList = data['list'];
            }
		);
	}

	closeVendorForm(){
		this.vendorError = "";
		this.vendorOpen = false;
  }
  
  calActualBudget(){
    //5 percent of Actual Sales
	  //this.actualBudget = this.calcDailyTotal()*5/100;
	 
	   var percntage= this.invoiceTotalgoods;
	   var calcbudgtt= parseFloat(this.calProjectedBudget().replace(',',''));
	  this.actualBudget = percntage / calcbudgtt * 100
	  var differper = calcbudgtt - this.invoiceTotalgoods;
	  this.differper = differper;
	  return this.actualBudget.toFixed(2);
  }
  getCategories(){
		this.salesService.getCategories(this.selectedStorage.id)
        .pipe(first())
        .subscribe(
            data => {
				this.categoryList = data['list'];
			}
		);
	}
  cleanvendor()
	{
	 this.vendors.v_name.setValue('');
     this.vendors.category.setValue('');
     this.vendors.account.setValue('');
     this.vendors.contact_person.setValue('');
     this.vendors.contact_number.setValue('');
     this.vendors.alt_contact_number.setValue('');
     this.vendors.addressline1.setValue('');
	 this.vendors.addressline2.setValue('');
	 this.vendors.city.setValue('');
	 this.vendors.state.setValue('');
	 this.vendors.zip.setValue('');
	 this.vendors.vendorlogin.setValue('');
	 this.vendors.vendornotes.setValue('');
     
	}

  vendorSubmit() {
		
    this.vendorsubmitted = true; 
    if(this.vendors.category.value=='')
    {
      this.catLoading = true;
        return;
    }
    if (this.vendorModelForm.invalid) {
        this.vendorloading = false;
        return;
    }

this.vendoralready='';
    this.vendorloading = true;
this.salesService.create_vendornew(this.selectedStorage.id, this.vendors.v_name.value, this.vendors.category.value, this.vendors.account.value, this.vendors.contact_person.value, this.vendors.contact_number.value, this.vendors.alt_contact_number.value, this.vendors.addressline1.value, this.vendors.addressline2.value, this.vendors.city.value, this.vendors.state.value, this.vendors.zip.value, this.vendors.vendorlogin.value, this.vendors.vendornotes.value)
    .pipe(first())
    .subscribe(
        data => {
    if(data['status']=='false')
    {
        this.vendoralready = data['msg'];
        this.vendorloading = false;
        this.vendorsubmitted = false;
    }
    else
    {
    this.vendoralready ="";
    this.cleanvendor();
    this.getVendors();
    this.closeInvoiceModal.nativeElement.click();
    this.closeVendorModal.nativeElement.click();
    this.vendorloading = false;
    this.vendorsubmitted = false;
    }
  },
        error => {
     
          this.vendorloading = false;
});
}
toggleReadMore(index: number) {
  this.showMore[index] = !this.showMore[index];
}
resizeTextarea(event): void {
  const textarea = event.target;
  textarea.style.height = 'auto'; // Reset the height to auto
  textarea.style.height = `${textarea.scrollHeight}px`; // Set the new height
  }


}
