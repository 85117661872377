import { Component, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import {PlanService} from '../_services';
import { SalesService } from "../_services/sales.service";
import * as echarts from 'echarts';

import { User } from '@app/_models';
import { UserService, AuthenticationService } from '@app/_services';
import {UtilsService} from "../_services/utils.service";
import {StoreSubscriberService} from "@app/_services/storeSubscriber.service";
import {CostOfFreshService} from "@app/_services/costOfFresh.service";
import {SchedulerService} from "@app/_services/scheduler.service";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { MessageToastService } from '../_services/messageToast.service';
import { MessageService } from "../_services/message.service";
import { Router } from '@angular/router';
import { AuthGuard } from "../_helpers/auth.guard";
import * as $ from 'jquery';
declare  var jQuery:  any;

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent implements OnInit {
	@ViewChild('closeSaleModal') closeSaleModal;
	@ViewChild('closeInvoiceModal') closeInvoiceModal;
	@ViewChild('closeVendorModal') closeVendorModal;
    loading = false;
    plans: any;
    sel_invoice_date: any;
	role_vendor: any;
	store_ID:any;
	vendoralready:any;
    sel_sales_date: any;
    loaded: boolean = true;
    showModalBox: boolean = false;
	showModalBox1: boolean = false;
    error: boolean;
    yearQuarter: any;
    selectedStorage: any;

    //sales section
    salesChart: any;
    projWeeklyRevQuarter: number;
    weeks: any[];
    actualSalesTotal: number;
    actualSalesByWeek: number[];
    projectedSalesByWeek: number[];

    //cost of hard goods section
    cogChart: any;
    actualCogByWeek: number[];
    projectedCogByWeek: number[];
    actualCogTotal: number;
    projectionsCog: number;
    weeksCog: any[];

    //cost of fresh section
    cofChart: any;
    actualCofByWeek: number[];
    projectedCofByWeek: number[];
    actualCofTotal: number;
    projectionsCof: number;
    weeksCof: any[];

    //cost of labor section
    colChart: any;
    actualColByWeek: number[];
    projectedColByWeek: number[];
    actualColTotal: number;
    projectionsCol: number;
    weeksCol: any[];

    modules: any;
	
	saleForm: FormGroup;
    formloading = false;
    submitted = false;
    returnUrl: string;
    saleerror = '';
	salesuccess = '';
	saleformsum: number;
	invoiceformsum: number;
	retail: number;
	wire: number;
	delivery: number;
	wholesale: number;
	widding_events: number;
	todaysale: any[];
	
	invoiceloading = false;
	vendorloading = false;
    invsubmitted = false;
	vendorsubmitted = false;
    //returnUrl: string;
    invoiceerror = '';
	invoicesuccess = '';
	
	chartdata5: any;
	option5: any;
	chart5: any;
	barChart5: any;
	
	chartdata2: any;
	option2: any;
	dashboardchart2: any;
	bardashboardchart2: any;
	
	chartdata3: any;
	option3: any;
	dashboardchart3: any;
	bardashboardchart3: any;
	entered_date = 0.00;
	chartdata4: any;
	option4: any;
	dashboardchart4: any;
	bardashboardchart4: any;
	loginLoading = true;
	projectedSalesByWeekCof : number[];
	projectedSalesByWeekCog : number[];
	projectedSalesByWeekCol : number[];
	weekName = [];
	userPermission = [];
	pageLoading = true;
	vendorOpen = false;
	vendorName = "";
	selectedVendor = "";
	vendorList = [];
	categoryList = [];
	vendorError= '';
	premiumPlan = false;
    constructor(
		private formBuilder: FormBuilder,
		private storeSubscriberService: StoreSubscriberService,//service used to receive store from top bar stores combobox
        private userService: UserService,
        private utilService: UtilsService,
        private planService:PlanService,
        private authenticationService: AuthenticationService,
        private salesService: SalesService,
        private costOfFreshService: CostOfFreshService,
        private schedulerService: SchedulerService,
		private message: MessageToastService,
		private messageService: MessageService,
		private route : Router,
		private authGuard : AuthGuard,
    ) {
        storeSubscriberService.subscribe(this,function (ref,store) {
            ref.receiveStorage(store);
        });
        this.yearQuarter = {year :this.utilService.GetCurrentYear(), quarter: this.utilService.GetCurrentQuarter()};
		
        //sales
        this.projWeeklyRevQuarter = 0.00;
        this.actualSalesTotal = 0.00;
        this.actualSalesByWeek = new Array();
        this.projectedSalesByWeek = new Array();

        //Cog
        this.projectionsCog = 0.00;
        this.actualCogTotal = 0.00;
        this.actualCogByWeek = new Array();
        this.projectedCogByWeek = new Array();
        this.projectedSalesByWeekCog = new Array();

        //Cof
        this.projectionsCof = 0.00;
        this.actualCofTotal = 0.00;
        this.actualCofByWeek = new Array();
        this.projectedCofByWeek = new Array();
		this.projectedSalesByWeekCof = new Array();

        //Col
        this.projectionsCol = 0.00;
        this.actualColTotal = 0.00;
        this.actualColByWeek = new Array();
        this.projectedColByWeek = new Array();
        this.projectedSalesByWeekCol = new Array();
		
		this.saleformsum = 0.00;
		this.invoiceformsum = 0.00;
		this.retail = 0.00;
		this.wire = 0.00;
		this.widding_events = 0.00;
		this.delivery = 0.00;
		this.wholesale =0.00;
		
		////console.log(history.state.reloadPage);
		
		if(history.state.reloadPage){
			this.loginLoading = true;
			window.location.reload();
		}
		/* else{
			this.loginLoading = false;
		} */
    }

    ngOnInit() {
		this.role_vendor = localStorage.getItem('role_vendor');
		
		const permissions = this.authGuard.userPermissions();
		this.userPermission = permissions;
		this.selectedStorage = JSON.parse(localStorage.getItem('selectedStorage'));
		
		if(this.selectedStorage.id != ''){
			this.loginLoading = false;
		}
		else{
			this.loginLoading = true;
		}
		
		this.salesService.getStoresPercent(this.selectedStorage.id).subscribe((response: any) =>{
			
			if(response.status == 'false')
			{
				const cof_retail ="0.2";
		        const cof_wire = "0.2";
				const cof_weddings = "0.14";
				const cog_retail = "0.05";
				const cog_wire = "0.05";
				const cog_weddings = "0.05";
		
		this.salesService.create_store_percent(this.selectedStorage.id,cof_retail,cof_wire,cof_weddings,cog_retail,cog_wire,cog_weddings).subscribe((response: any) =>{
		   });
			}
		});
	   ////////console.log('123-'+this.selectedStorage.id);
        document.body.classList.remove('loginbody'); 

        //TO LOAD THE BAR CHART CORRECTLY WITH JQUERY
        (function ($) {
          $(document).ready(function(){
			  $("#invoice_date").datepicker( {dateFormat : "yy-mm-dd"});
			   $("#sales_date").datepicker( {dateFormat : "yy-mm-dd"});
               
                });
        })(jQuery);
		
		this.saleForm = this.formBuilder.group({
			saleModelForm : this.formBuilder.group({
				retail: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
				wire: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
				widding_events: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
				delivery: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
				wholesale: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
				sales_date: ['', Validators.required],
			}),
			invoiceModelForm : this.formBuilder.group({
				invoice_name: ['', Validators.required],
				invoice_number: ['', Validators.required],
				fresh: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
				goods: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
				suppliers: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
				invoice_date: ['', Validators.required],
				delivery: ['', [Validators.required,Validators.pattern(/^\d*\.?\d{0,2}$/)]],
			}),
			vendorModelForm : this.formBuilder.group({
				v_name: ['', Validators.required],
				category: ['', Validators.required],
				account: ['', Validators.required],
				contact_person: ['', Validators.required],
				contact_number: ['', Validators.required],
				alt_contact_number: [''],
				addressline1: ['', Validators.required],
				addressline2: [''],
				city: ['', Validators.required],
				state: ['', Validators.required],
				zip: ['', Validators.required],
				vendorlogin: ['', Validators.required],
				vendornotes: ['', Validators.required],
				
			})
		});
		this.checkWeekProjection();
		this.reloadTodaySale();
		
		setInterval(() => {
			this.sel_invoice_date = $('#invoice_date').val();
		}, 100);
		setInterval(() => {
			this.sel_sales_date = $('#sales_date').val();
		}, 100);
		this.getWeekNames();
		this.getVendors();
		this.getCategories();
		this.checkPremium(this.selectedStorage.id);
	}
	
	checkWeekProjection(){
		this.salesService.checkWeekProjection(this.selectedStorage.id).subscribe((data: any) =>{
			if(!data.status)
			{
				if(this.store_ID=="90000")
				{
					console.log('aaa');
					this.showModalBox = false;
				}
				else if(this.store_ID=="99999")
				{
					console.log('bbb');
				  this.showModalBox1 = true;
				  this.showModalBox = false;
				}
				else
				{
					console.log('ccc');
				  this.showModalBox1 = false;
				  this.showModalBox = true;
				}
			}
		});
	}
	
	reloadTodaySale(){
		this.salesService.getTodaySale(this.selectedStorage.id).subscribe((data: any) =>{
			this.todaysale = data;
			//////console.log(this.todaysale);
			this.setTodaySale(this.todaysale);
		});
	}

	setTodaySale(tsale)
	{
		if(tsale){
			this.retail = tsale.retail;
			this.wire = tsale.wire;
			this.widding_events = tsale.widding_events;
			this.delivery = tsale.delivery;
			this.wholesale = tsale.wholesale
			this.entered_date = tsale.entered_date;
		}
		
		this.f.retail.setValue(this.retail);
		this.f.wire.setValue(this.wire);
		this.f.widding_events.setValue(this.widding_events);
		this.f.delivery.setValue(this.delivery);
		this.f.wholesale.setValue(this.wholesale);
		this.f.sales_date.setValue(this.entered_date);
		
		this.setSaleForSum();
	}

	get f() { return (<FormGroup>this.saleForm.get('saleModelForm')).controls; }
	get inv() { return (<FormGroup>this.saleForm.get('invoiceModelForm')).controls; }
	get vendors() { return (<FormGroup>this.saleForm.get('vendorModelForm')).controls; }
	
	get saleModelForm() {
		return this.saleForm.get('saleModelForm');
	}
	
	get invoiceModelForm() {
		return this.saleForm.get('invoiceModelForm');
	}

	get vendorModelForm()
	{
		return this.saleForm.get('vendorModelForm');
	}
	
	keyUpFunction(event)
	{
		if(event.keyCode == 13)
		{
			this.onSubmit();
		}
		this.setSaleForSum();
		//this.saleformsum = parseFloat(this.saleformsum);
	}
    
	setSaleForSum(){
		this.saleformsum = 0;
		if(this.f.retail.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.retail.value);
		if(this.f.wire.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.wire.value);
		if(this.f.widding_events.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.widding_events.value);
		if(this.f.delivery.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.delivery.value);
		if(this.f.wholesale.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.wholesale.value);
	}
	
	keyUpInvoiceFunction(event)
	{
		if(event.keyCode == 13)
		{
			this.invSubmit();
		}
		this.setInvoiceSum();
	}
	
	setInvoiceSum(){
		this.invoiceformsum = 0;
		if(this.inv.fresh.value != "")
			this.invoiceformsum = this.invoiceformsum+parseFloat(this.inv.fresh.value);
		if(this.inv.goods.value != "")
			this.invoiceformsum = this.invoiceformsum+parseFloat(this.inv.goods.value);
		if(this.inv.suppliers.value != "")
			this.invoiceformsum = this.invoiceformsum+parseFloat(this.inv.suppliers.value);
		if(this.inv.delivery.value != "")
			this.invoiceformsum = this.invoiceformsum+parseFloat(this.inv.delivery.value);
	}
	
	addPrefix(value:string)
	{
		//////console.log(value);
		let valueTemp = value.replace('$','');
		this.f.target_percentage.setValue('$'+valueTemp);
	}
	
  
	onSubmit() {
		this.sel_sales_date = $('#sales_date').val();
        this.submitted = true; 

        // stop here if form is invalid
        if ((<FormGroup>this.saleForm.get('saleModelForm')).invalid) {
            this.formloading = false;
            return;
        }
		
		this.saleerror = '';
		this.salesuccess = '';
        this.formloading = true;
		this.salesService.createSale(this.selectedStorage.id, this.f.retail.value, this.f.wire.value, this.f.widding_events.value,this.f.wholesale.value,this.f.delivery.value,this.f.sales_date.value)
        .pipe(first())
        .subscribe(
            data => {
				//////console.log(data)
				this.clean();
				this.closeSaleModal.nativeElement.click();
				this.formloading = false;
				//this.saleformsum = 0;
				this.submitted = false;
				this.getSales();
				this.getCog();
				this.getCof();
			},
            error => {
			  this.saleerror = error;
              this.formloading = false;
		});
    }
	
	clean(){
	
	}
	
	cleanInv(){
	 this.inv.invoice_name.setValue('');
     this.inv.invoice_number.setValue('');
     this.inv.fresh.setValue('');
     this.inv.goods.setValue('');
     this.inv.invoice_date.setValue('');
     this.inv.delivery.setValue('');
     this.inv.suppliers.setValue('');
     this.invoiceformsum = 0.00;
	}

	cleanvendor()
	{
	 this.vendors.v_name.setValue('');
     this.vendors.category.setValue('');
     this.vendors.account.setValue('');
     this.vendors.contact_person.setValue('');
     this.vendors.contact_number.setValue('');
     this.vendors.alt_contact_number.setValue('');
     this.vendors.addressline1.setValue('');
	 this.vendors.addressline2.setValue('');
	 this.vendors.city.setValue('');
	 this.vendors.state.setValue('');
	 this.vendors.zip.setValue('');
	 this.vendors.vendorlogin.setValue('');
	 this.vendors.vendornotes.setValue('');
     
	}
	
    invSubmit() {
		this.sel_invoice_date = $('#invoice_date').val();
        this.invsubmitted = true; 
		
        if ((<FormGroup>this.saleForm.get('invoiceModelForm')).invalid) {
            this.invoiceloading = false;
            return;
        }
		
		this.invoiceerror = '';
		this.invoicesuccess = '';
        this.invoiceloading = true;
		this.salesService.createInvoice(this.selectedStorage.id, this.inv.invoice_name.value, this.inv.invoice_number.value, this.inv.fresh.value, this.inv.goods.value, this.inv.invoice_date.value, this.inv.delivery.value, this.inv.suppliers.value)
        .pipe(first())
        .subscribe(
            data => {
				//////console.log(data)
				this.cleanInv();
				this.getSales();
				this.getCog();
				this.getCof();
				this.closeInvoiceModal.nativeElement.click();
				this.invoiceloading = false;
				this.invsubmitted = false;
			},
            error => {
			  this.invoiceerror = error;
             
              this.invoiceloading = false;
		});
    }
	
	initActualSalesByWeekArray(){
        this.actualSalesByWeek = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00];
        this.projectedSalesByWeek = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00];
    }
    initGogArray(){
        this.actualCogByWeek = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00];
        this.projectedCogByWeek = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00];
    }
    initGofArray(){
        this.actualCofByWeek = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00];
        this.projectedCofByWeek = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00];
    }
    initColArray(){
        this.actualColByWeek = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00];
        this.projectedColByWeek = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00];
    }
    showSalesChart()
    {
		/*******New Start Sales*****/
		
		this.chartdata5 = [
		{
		  name: 'Projected Sales',
		  type: 'bar',
		  data: this.projectedSalesByWeek
		},
		{
		  name: 'Actual Sales',
		  type: 'bar',
		  data: this.actualSalesByWeek
		}
	];

	this.option5 = {
		grid: {
		 top: '6',
			  right: '0',
			  bottom: '17',
			  left: '25',
			  containLabel: true
		},
		tooltip: {
				show: true,
				showContent: true,
				//alwaysShowContent: true,
				triggerOn: 'mousemove',
				trigger: 'axis',
				axisPointer:
				{
					label: {
						show: false,
					}
				}
			},
			xAxis: {
			  data: this.weekName,
			  axisLine: {
				lineStyle: {
				  color: 'rgba(119, 119, 142, 0.2)'
				}
			  },
			  axisLabel: {
				fontSize: 10,
				color: '#77778e',
				formatter: function (value, index) {
					return index+1;
				}
			  },
			},
		yAxis: {
				type : 'value',
				
			  splitLine: {
				lineStyle: {
				  color: 'rgba(119, 119, 142, 0.2)'
				}
			  },
			  axisLine: {
				lineStyle: {
				  color: 'rgba(119, 119, 142, 0.2)'
				}
			  },
			  axisLabel: {
				fontSize: 10,
				color: '#77778e',
				formatter: function (value, index) {
					if(value >= 1000)
					{
						value = value/1000;
						value = value+'K';
					}
					/* if(value <= 10){
						value = value*10;
					} */
					return value;
				}
			  }
			},
		series: this.chartdata5,
		color:[ '#24D3EB', '#049CB0']
	};
		this.chart5 = document.getElementById('echart99');
			if(this.chart5){
				this.barChart5 = echarts.init(this.chart5);
				this.barChart5.setOption(this.option5);
			}
		
		/*******New Start Sales*****/
		
		////console.log(this.projectedSalesByWeek);
    }

    showCogChart()
    {
		/******New Cost of hard Goods****/
		this.chartdata2 = [
		{
		  name: 'Projected Cost',
		  type: 'bar',
		  data: this.projectedSalesByWeekCog
		},
		{
		  name: 'Actual Cost',
		  type: 'bar',
		  data: this.actualCogByWeek
		}
	];

	this.option2 = {
		grid: {
		   top: '6',
		   right: '0',
		   bottom: '17',
		   left: '25',
		   containLabel: true
		},
		tooltip: {
				show: true,
				showContent: true,
				//alwaysShowContent: true,
				triggerOn: 'mousemove',
				trigger: 'axis',
				axisPointer:
				{
					label: {
						show: false,
					}
				}
			},
		 xAxis: {
			  data: this.weekName,
			  axisLine: {
				lineStyle: {
				  color: 'rgba(119, 119, 142, 0.2)'
				}
			  },
			  axisLabel: {
				fontSize: 10,
				color: '#77778e',
				formatter: function (value, index) {
					return index+1;
				}
			  }
			},
		yAxis: {
			  splitLine: {
				lineStyle: {
				  color: 'rgba(119, 119, 142, 0.2)'
				}
			  },
			  axisLine: {
				lineStyle: {
				  color: 'rgba(119, 119, 142, 0.2)'
				}
			  },
			  axisLabel: {
				fontSize: 10,
				color: '#77778e',
				formatter: function (value, index) {
					if(value >= 1000)
					{
						value = value/1000;
						value = value+'K';
					}
					
					return value;
				}
			  }
			},
		series: this.chartdata2,
		color:[ '#D5A0FF', '#9D0ECF']
	};
		this.dashboardchart2 = document.getElementById('dashboardchart2');
			if(this.dashboardchart2){
				this.bardashboardchart2 = echarts.init(this.dashboardchart2);
				this.bardashboardchart2.setOption(this.option2);
			}
		/******New Cost of hard Goods****/
		
    }
    showCofChart()
    {
		/****New Cost Of Fresh Start***/
		
		 this.chartdata3 = [
		{
		  name: 'Projected Cost',
		  type: 'bar',
		  data: this.projectedSalesByWeekCof
		},
		{
		  name: 'Actual Cost',
		  type: 'bar',
		  data: this.actualCofByWeek
		}
	];

	this.option3 = {
		grid: {
		   top: '6',
		   right: '0',
		   bottom: '17',
		   left: '25',
		   containLabel: true
		},
		tooltip: {
				show: true,
				showContent: true,
				//alwaysShowContent: true,
				triggerOn: 'mousemove',
				trigger: 'axis',
				axisPointer:
				{
					label: {
						show: false,
					}
				}
			},
		 xAxis: {
			  data: this.weekName,
			  axisLine: {
				lineStyle: {
				  color: 'rgba(119, 119, 142, 0.2)'
				}
			  },
			  axisLabel: {
				fontSize: 10,
				color: '#77778e',
				formatter: function (value, index) {
					return index+1;
				}
			  }
			},
		yAxis: {
			  splitLine: {
				lineStyle: {
				  color: 'rgba(119, 119, 142, 0.2)'
				}
			  },
			  axisLine: {
				lineStyle: {
				  color: 'rgba(119, 119, 142, 0.2)'
				}
			  },
			  axisLabel: {
				fontSize: 10,
				color: '#77778e',
				formatter: function (value, index) {
					if(value >= 1000)
					{
						value = value/1000;
						value = value+'K';
					}
					/* if(value <= 10){
						value = value*10;
					} */
					return value;
				}
			  }
			},
		series: this.chartdata3,
		color:[  '#FFBE9A', '#FD813C']
	};
	this.dashboardchart3 = document.getElementById('dashboardchart3');
		//////console.log(this.dashboardchart3);
		if(this.dashboardchart3){
			this.bardashboardchart3 = echarts.init(this.dashboardchart3);
			this.bardashboardchart3.setOption(this.option3);
		}
		
	
        
    }

    showColChart()
    {
		
		/*******New Cost of Labour Start*****/
		
		 this.chartdata4 = [
		{
		  name: 'Projected Cost',
		  type: 'bar',
		  data: this.projectedSalesByWeekCol
		},
		{
		  name: 'Actual Cost',
		  type: 'bar',
		  data: this.actualColByWeek
		}
	];

	this.option4 = {
		grid: {
		   top: '6',
		   right: '0',
		   bottom: '17',
		   left: '25',
		   containLabel: true
		},
		tooltip: {
				show: true,
				showContent: true,
				//alwaysShowContent: true,
				triggerOn: 'mousemove',
				trigger: 'axis',
				axisPointer:
				{
					label: {
						show: false,
					}
				}
			},
		 xAxis: {
			  data: this.weekName,
			  axisLine: {
				lineStyle: {
				  color: 'rgba(119, 119, 142, 0.2)'
				}
			  },
			  axisLabel: {
				fontSize: 10,
				color: '#77778e',
				formatter: function (value, index) {
					return index+1;
				}
			  }
			},
			yAxis: {
			  splitLine: {
				lineStyle: {
				  color: 'rgba(119, 119, 142, 0.2)'
				}
			  },
			  axisLine: {
				lineStyle: {
				  color: 'rgba(119, 119, 142, 0.2)'
				}
			  },
			  axisLabel: {
				fontSize: 10,
				color: '#77778e',
				formatter: function (value, index) {
					if(value >= 1000)
					{
						value = value/1000;
						value = value+'K';
					}
					/* if(value <= 10){
						value = value*10;
					} */
					return value;
				}
			  }
			},
		series: this.chartdata4,
		color:[  '#67F48E', '#2AA34C']
	};
	this.dashboardchart4 = document.getElementById('dashboardchart4');
		if(this.dashboardchart4){
			this.bardashboardchart4 = echarts.init(this.dashboardchart4);
			this.bardashboardchart4.setOption(this.option4);
		}
		
		
    }

    receiveYearQuarter($event){
        this.yearQuarter = $event;
        this.getSales();
        this.getCog();
		this.getCof();
		this.getWeekNames();
    }
    receiveStorage(storage){
        this.selectedStorage = storage;
        this.getSales();
        this.getCog();
		this.getCof();
		this.getVendors();
		this.getCategories();
		this.checkPremium(this.selectedStorage.id);
    }

    getSales()
    {
		this.loading = true;
		this.pageLoading = true;
		
			this.salesService.getSales_v2(this.selectedStorage.id,this.yearQuarter.year,this.yearQuarter.quarter,this.selectedStorage.created_at).subscribe((response: any) =>{
				this.weeks = response.weeks;
				setTimeout(()=>{ 
					this.calcActualSalesTotal();
					this.getProjectedSales();
					this.pageLoading = false;
				}, 500);
			});
        
    }
    getCog(){
		this.loading = true;
		this.getProjectedSalesAllApi();
		this.weeksCog = [];
		 
			this.costOfFreshService.getMasterOverviewWeekly_v2('goods',this.selectedStorage.id,this.yearQuarter.year,this.yearQuarter.quarter,this.selectedStorage.created_at).subscribe((data: any) =>{
				//console.log(this.weeksCog);
				this.weeksCog = data.master_overview_weekly;
				setTimeout(()=>{
					this.calcActualCogTotal();
					this.showCogChart();
				}, 2000);
			})
		
    }
    getCof(){
		this.loading = true;
		this.getProjectedSalesAllApi();
		
			this.costOfFreshService.getMasterOverviewWeekly_v2('fresh',this.selectedStorage.id,this.yearQuarter.year,this.yearQuarter.quarter,this.selectedStorage.created_at).subscribe((data: any) =>{
				//console.log(data.master_overview_weekly);
				this.weeksCof = data.master_overview_weekly;
				setTimeout(()=>{
					this.calcActualCofTotal();
					this.showCofChart();
					this.getCol();
				}, 500);
			})
		
    }
    getCol(){
		this.loading = true;
		this.getProjectedSalesAllApi();
		 
			/* this.schedulerService.getScheduledPayrollByQuarter(this.yearQuarter.year,this.selectedStorage.id,this.yearQuarter.quarter).subscribe((data: any) =>{
				this.weeksCol = data.scheduled_payroll_array;
				
				setTimeout(()=>{
					this.calcActualColTotal();
					this.showColChart();
				},500)
			}) */

			this.costOfFreshService.getMasterOverviewWeekly_v2('fresh',this.selectedStorage.id,this.yearQuarter.year,this.yearQuarter.quarter,this.selectedStorage.created_at).subscribe((data: any) =>{
				//console.log(data.master_overview_weekly);
				this.weeksCol = data.master_overview_weekly;
				setTimeout(()=>{
					this.calcActualColTotal();
					this.showColChart();
					//this.getCol();
				}, 500);
			})
		
	}
	
	calculateProjectedSalesCof(){
		const cofSales = [];
			////console.log(this.projectedSalesByWeek);
			for(let i = 0; i < this.projectedSalesByWeek.length; i++){
				const partialValue = 20;
				const new_value = (( partialValue / 100) * this.projectedSalesByWeek[i]).toFixed(2);
				cofSales.push(new_value);
			}
			////console.log(cofSales);
			this.projectedSalesByWeekCof = cofSales;
	}

	getProjectedSalesAllApi(){
		this.projectedSalesByWeekCof = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00];
		this.projectedSalesByWeekCog = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00];
		this.projectedSalesByWeekCol = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00];
		//api to get project sales by week for fresh
		this.salesService.getProjWeeklyRevQuarter_v2(this.selectedStorage.id,this.yearQuarter.year,this.yearQuarter.quarter).subscribe((response: any) =>{
			const all_projected_sales = response.all_projected_sales;
			var cofSales = [];
			var cogSales = [];
			var colSales = [];
			for(let i = 0; i < all_projected_sales.length; i++){
				
				const cofValue = 20;
				const cogValue = 5;
				const colValue = 25;
				const cof_value = (( cofValue / 100) * all_projected_sales[i]).toFixed(2);
				const cog_value = (( cogValue / 100) * all_projected_sales[i]).toFixed(2);
				const col_value = (( colValue / 100) * all_projected_sales[i]).toFixed(2);
				cofSales.push(cof_value);
				cogSales.push(cog_value);
				colSales.push(col_value);
			}
			this.projectedSalesByWeekCof = cofSales;
			this.projectedSalesByWeekCog = cogSales;
			this.projectedSalesByWeekCol = colSales;
			
		});
	}

    /**
     * This function is just getProjWeeklyRevQuarter
     */
    getProjectedSales(){
        this.salesService.getProjWeeklyRevQuarter_v2(this.selectedStorage.id,this.yearQuarter.year,this.yearQuarter.quarter).subscribe((response: any) =>{
            this.projWeeklyRevQuarter = response.proj_weekly_rev_quarter;
            this.projectedSalesByWeek = response.all_projected_sales;
			this.showSalesChart();
			//this.calculateProjectedSalesCof();
			
			});
    }
    calcActualSalesTotal(){
        this.actualSalesTotal = 0.00;
        this.initActualSalesByWeekArray();
        for (let i = 0; i < this.weeks.length; i++) {
			let total = this.weeks[i].totalDelivery + this.weeks[i].totalWire + this.weeks[i].totalRetail + this.weeks[i].totalWidding_events + this.weeks[i].totalWholesale;
			//total = total.toFixed(2);
            this.actualSalesTotal += total;
            //////console.log(this.actualSalesTotal);
            this.actualSalesByWeek[(this.weeks[i].week - (13 * (this.yearQuarter.quarter - 1)))-1] = total.toFixed(2);
        }
		////////console.log(this.actualSalesByWeek);
    }

    calcActualCogTotal(){
        this.actualCogTotal = this.projectionsCog = 0.00;
        this.initGogArray();
        for (let i = 0; i < this.weeksCog.length; i++) {
            let total = this.weeksCog[i].cost_of_fresh;
            this.actualCogTotal += total;
            //this.projectionsCog += Number(this.weeksCog[i].actual_weekly_revenue * this.weeksCog[i].target / 100 );
            this.actualCogByWeek[(this.weeksCog[i].week - (13 * (this.yearQuarter.quarter - 1)))-1] = total.toFixed(2);
            //this.projectedCogByWeek[(this.weeksCog[i].week_number - (13 * (this.yearQuarter.quarter - 1)))-1] = Number(this.weeksCog[i].actual_weekly_revenue * this.weeksCog[i].target / 100 );
        }
    }
    calcActualCofTotal(){
        this.actualCofTotal = this.projectionsCof = 0.00;
        this.initGofArray();
        for (let i = 0; i < this.weeksCof.length; i++) {
			let total = this.weeksCof[i].cost_of_fresh;
			////console.log(total);
            this.actualCofTotal += total;
            //this.projectionsCof += Number(this.weeksCof[i].actual_weekly_revenue * this.weeksCof[i].target / 100 );
			this.actualCofByWeek[(this.weeksCof[i].week - (13 * (this.yearQuarter.quarter - 1)))-1] = total.toFixed(2);
			
			////console.log(this.actualCofByWeek);
            //this.projectedCofByWeek[(this.weeksCof[i].week_number - (13 * (this.yearQuarter.quarter - 1)))-1] = Number(this.weeksCof[i].actual_weekly_revenue * this.weeksCof[i].target / 100 );
		}
		//console.log(this.actualCofByWeek);
		
    }

    calcActualColTotal(){
		this.loading = false;
        this.actualColTotal = this.projectionsCol = 0.00;
        this.initColArray();
        //console.log(this.weeksCol)
        for (let i = 0; i < this.weeksCol.length; i++) {
			//console.log(this.weeksCol[i].emp_total_cost_tax);
            let total = this.weeksCol[i].emp_total_cost_tax;
            this.actualColTotal += total;
			//this.actualColByWeek[(this.weeksCol[i].week_number - (13 * (this.yearQuarter.quarter - 1)))-1] = total.toFixed(2);

			this.actualColByWeek[(this.weeksCol[i].week - (13 * (this.yearQuarter.quarter - 1)))-1] = total.toFixed(2);
			
			//console.log(this.actualColByWeek);
        }
        this.projectionsCol = this.projectionsCof;
        this.projectedColByWeek = this.projectedCofByWeek;
	}
	
	getWeekNames(){
		this.salesService.getWeekSales_v2(this.yearQuarter.year,this.yearQuarter.quarter).subscribe((response: any) =>{
			if(response.weeks.length > 0){
				this.weekName = response.weeks;
			}
			else{
				this.weekName = ['1', '2', '3', '4', '5', '6', '7', '8', '9','10','11','12','13'];
			}
		});
	}


	checkUserPermission(type){
		const hasPermission = this.userPermission;
		const currentUser = this.authenticationService.currentUserValue;
		if(currentUser.isAdmin){
			return true;
		}
		else if (typeof hasPermission !== "undefined" && hasPermission.includes(type))
		{
			return true;
		}
		else{
			return true;
		}
		return false; 
		
	}

	openVendorInput(){
		this.vendorError = "";
		//console.log('opened');
		this.vendorOpen = true;
	}

	closeVendorInput(){
		this.vendorError = "";
		//console.log('closed');
		this.vendorOpen = false;
	}

	saveVendor(){
		//console.log(this.vendorName);
		if(this.vendorName != ''){
			this.salesService.createVendor(this.selectedStorage.id, this.vendorName)
			.pipe(first())
			.subscribe(
				data => {
					this.getVendors();
					this.selectedVendor = data['vendor_id'];
					this.invoiceModelForm.get("invoice_name").setValue(this.selectedVendor);
					this.vendorOpen = false;
				},
				error => {
				this.invoiceerror = error;
				this.invoiceloading = false;
			});
		}
		else{
			this.vendorError = "Vendor Name is required";
		}
	}

	addvendor()
	{
		this.closeInvoiceModal.nativeElement.click();
	}

	vendorSubmit() {
		
        this.vendorsubmitted = true; 
		
        if ((<FormGroup>this.saleForm.get('vendorModelForm')).invalid) {
            this.vendorloading = false;
            return;
        }
		
		this.vendoralready='';
		this.invoiceerror = '';
		this.invoicesuccess = '';
        this.vendorloading = true;
		this.salesService.create_vendornew(this.selectedStorage.id, this.vendors.v_name.value, this.vendors.category.value, this.vendors.account.value, this.vendors.contact_person.value, this.vendors.contact_number.value, this.vendors.alt_contact_number.value, this.vendors.addressline1.value, this.vendors.addressline2.value, this.vendors.city.value, this.vendors.state.value, this.vendors.zip.value, this.vendors.vendorlogin.value, this.vendors.vendornotes.value)
        .pipe(first())
        .subscribe(
            data => {
				if(data['status']=='false')
				{
						this.vendoralready = data['msg'];
						this.vendorloading = false;
						this.vendorsubmitted = false;
				}
				else
				{
				this.vendoralready ="";
				this.cleanvendor();
				this.getSales();
				this.getVendors();
				this.getCog();
				this.getCof();
				this.closeInvoiceModal.nativeElement.click();
				this.closeVendorModal.nativeElement.click();
				this.vendorloading = false;
				this.vendorsubmitted = false;
				}
			},
            error => {
			  this.invoiceerror = error;
             
              this.vendorloading = false;
		});
    }
	

	enterVendorName(input){
		this.vendorName = input;
		this.vendorError = "";
		//console.log(input);
	}
	

	getVendors(){
		this.salesService.getVendors(this.selectedStorage.id)
        .pipe(first())
        .subscribe(
            data => {
				//console.log(data);
				this.vendorList = data['list'];
			}
		);
	}
	getCategories(){
		this.salesService.getCategories(this.selectedStorage.id)
        .pipe(first())
        .subscribe(
            data => {
				this.categoryList = data['list'];
			}
		);
	}

	closeVendorForm(){
		this.vendorError = "";
		this.vendorOpen = false;
	}

	checkPremium(store_id){
		this.store_ID = localStorage.getItem('store_id');
		const premiumPackage = this.authGuard.premiumPackage(store_id);
		if(!premiumPackage){
		  this.premiumPlan = false;
		}
		else{
		  this.premiumPlan = true;
		}
	}
	resizeTextarea(event): void {
		const textarea = event.target;
		textarea.style.height = 'auto'; // Reset the height to auto
		textarea.style.height = `${textarea.scrollHeight}px`; // Set the new height
	  }
}
