import { Component, OnInit } from '@angular/core';
import { StoreSubscriberService } from "../_services/storeSubscriber.service";
import { SalesService } from "../_services/sales.service";
import { UtilsService } from "../_services/utils.service";
import { AuthGuard } from "../_helpers/auth.guard";
import {TableModule} from 'primeng/table';
import {Observable} from "rxjs";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
// import {DialogModule} from 'primeng/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import * as echarts from 'echarts';
import ECharts = echarts.ECharts;
import EChartOption = echarts.EChartOption;
import { Router } from '@angular/router';

import * as $ from 'jquery' 
import { Route } from '@angular/compiler/src/core';

declare  var jQuery:  any;

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.less']
})
export class SalesComponent implements OnInit {
	dayform: FormGroup;
	submitted = false;
	error = '';
  selectedStorage: any;
  yearQuarter: any;
  weeks: any[];
  cols:any;
  colsSubtable:any;
  loading: boolean;
  formloading: boolean;
  clonedDays: { [s: string]: any; } = {};
  lineChartData : any;
  pieChartData : any;
  projWeeklyRevQuarter: number;
  actualSalesTotal: number;
  selWeek: number;
  saleformsum: number;
  quarterfirstsales: number;
  quartersecondsales: number;
  quarterthirdsales: number;
  quarterforthsales: number;
  actualSalesByWeek: number[];
  projectedSalesByWeek: number[];
	dialogValues : any;
	visible:boolean = false;
	chartdata:any;
	chartdata44:any;
	option44:any;
	option55:any;
	saleschartbox:any;
  barsaleschartbox4:any;
	saleschartbox5:any;
  barsaleschartbox5:any;
  store_ID:any;
  week_show = '';
  week_prefix = '';
  week_sales = [];
  userPermission = [];
  pageLoading = true;
  constructor(
	  private formBuilder: FormBuilder,
      private storeSubscriberService: StoreSubscriberService,//service used to receive store from top bar stores combobox
      private salesService: SalesService,
      private utilService: UtilsService,
      private route : Router,
      private authGuard : AuthGuard,
  ) {
    this.authGuard.userPermissions();
    const permissions = this.authGuard.userPermissions();
    this.userPermission = permissions;
    storeSubscriberService.subscribe(this,function (ref,store) {
      ref.receiveStorage(store);
    });
    let currentYear = this.utilService.GetCurrentYear();
    this.yearQuarter = /*{year : currentYear, quarter: 1}*/JSON.parse(localStorage.getItem('yearQuarter'));
    this.projWeeklyRevQuarter = 0.00;
    this.actualSalesTotal = 0.00;
    this.saleformsum = 0.00;
    this.selWeek = 0;
	this.quarterfirstsales = 0.00;
	this.quartersecondsales = 0.00;
	this.quarterthirdsales = 0.00;
	this.quarterforthsales = 0.00;
    this.actualSalesByWeek = new Array();
    this.projectedSalesByWeek = new Array();
	this.dialogValues = {'id':0,'retail': 0.00,'wire': 0.00,'delivery': 0.00,'wholesale': 0.00,'widding_events': 0.00,'entered_date':''};
  }

  initActualSalesByWeekArray(){ 
    this.actualSalesByWeek = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00];
    this.projectedSalesByWeek = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00];
  }

  receiveYearQuarter($event){
    this.yearQuarter = $event;
    this.getSales();
	  this.getQuarterSale();
	  this.getYearlySales();
    // this.getProjectedSales();
  }

  receiveStorage(storage){
    this.selectedStorage = storage;
    this.getSales();
	  this.getQuarterSale();
	  this.getYearlySales();
	}

  ngOnInit() {
    document.body.classList.remove('loginbody');
    this.loading = true;
    this.formloading = false;
    this.selectedStorage = JSON.parse(localStorage.getItem('selectedStorage'));
    this.loadHeaders();
	
      $(function() {
        'use strict';
      /******sales*****/
        
        /******sales*****/
      });
	  this.getQuarterSale();
	  this.dayform = this.formBuilder.group({
		id: [''],
		entered_date: [''],
		retail: ['', Validators.required],
		wire: ['',  Validators.required],
		delivery: ['',Validators.required],
		widding_events: ['',Validators.required],
    wholesale: ['',Validators.required],
      });
  }

  showLineChart(){
	  
	  /********/
	  this.chartdata = [
		{
		  name: 'Actual Sales',
		  type: 'bar',
		  data: this.actualSalesByWeek
		},
		{
		  name: 'Projected Sales',
		  type: 'bar',
		  data: this.projectedSalesByWeek
		}
	];

	this.option55 = {
		grid: {
		   top: '6',
		   right: '0',
		   bottom: '17',
		   left: '50',
		},
		 xAxis: {
            data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept','Oct','Nov','Dec'],
            axisLine: {
            lineStyle: {
              color: 'rgba(119, 119, 142, 0.2)'
            }
            },
            axisLabel: {
            fontSize: 10,
            color: '#77778e'
            }
          },
		tooltip: {
			show: true,
			showContent: true,
			alwaysShowContent: true,
			triggerOn: 'mousemove',
			trigger: 'axis',
			axisPointer:
			{
				label: {
					show: false,
				}
			}
		},
		yAxis: {
            splitLine: {
				lineStyle: {
					color: 'rgba(119, 119, 142, 0.2)'
				}
            },
            axisLine: {
				lineStyle: {
					color: 'rgba(119, 119, 142, 0.2)'
				}
            },
            axisLabel: {
				fontSize: 10,
				color: '#77778e',
				formatter: function (value, index) {
					if(value >= 1000)
					{
						value = value/1000;
						value = value+'K';
          }
         
					return value;
				}
            }
          },
		series: this.chartdata,
		color:[ '#049CB0', '#24D3EB']
	};
	this.saleschartbox5 = document.getElementById('saleschartboxnew');
        if(this.saleschartbox5){
          this.barsaleschartbox5 = echarts.init(this.saleschartbox5);
          this.barsaleschartbox5.setOption(this.option55);
        }
	  /********/
	  
	  
    this.chartdata44 = [
          {
            name: 'Actual Sales',
            type: 'bar',
            stack: 'Stack',
            barMaxWidth: 15,  
            data: this.actualSalesByWeek
          },
          {
            name: 'Projected Sales',
            type: 'bar',
            stack: 'Stack',
            barMaxWidth: 15,    
            data: this.projectedSalesByWeek
          }
        ];
        this.option44 = {
          grid: {
            top: '6',
            right: '30',
            bottom: '17',
            left: '25',
            containLabel: true
          },
          tooltip: {
            show: true,
            showContent: true,
            //alwaysShowContent: true,
            triggerOn: 'mousemove',
            trigger: 'axis',
            axisPointer:
            {
              label: {
                show: false,
              }
            }
          },
          xAxis: {
            data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept','Oct','Nov','Dec'],
            axisLine: {
            lineStyle: {
              color: 'rgba(119, 119, 142, 0.2)'
            }
            },
            axisLabel: {
            fontSize: 10,
            color: '#77778e'
            }
          },
          yAxis: {
            splitLine: {
				lineStyle: {
					color: 'rgba(119, 119, 142, 0.2)'
				}
            },
            axisLine: {
				lineStyle: {
					color: 'rgba(119, 119, 142, 0.2)'
				}
            },
            axisLabel: {
				fontSize: 10,
				color: '#77778e',
				formatter: function (value, index) {
					if(value >= 1000)
					{
						value = value/1000;
						value = value+'K';
          }
        
					
					return value;
				}
            }
          },
          series: this.chartdata44,
          color:[ '#049CB0', '#24D3EB' ]
        };
	  
        this.saleschartbox = document.getElementById('saleschartbox');
        if(this.saleschartbox){
          this.barsaleschartbox4 = echarts.init(this.saleschartbox);
          this.barsaleschartbox4.setOption(this.option44);
        }
  }
  showPieChart()
  {
      this.pieChartData = {
        legend: [
          {
             display: false,
          }
        ],
        datasets:[
          {
            label:['Actual Sales','Projected Sales'],
            backgroundColor: ['#1caba0','#ff596e'],
            data: [this.actualSalesTotal,this.projWeeklyRevQuarter],
            hoverBackgroundColor: ['#1caba0','#ff596e'],
          }
        ]
      };
  }
  getSales()
  {
    this.store_ID = localStorage.getItem('store_id');
    this.loading = true;
    this.pageLoading = true;
      this.salesService.getSales_v2(this.selectedStorage.id,this.yearQuarter.year,this.yearQuarter.quarter,this.selectedStorage.created_at).subscribe((response: any) =>{
        console.log(response);
        this.weeks = response.weeks;
        
        const storePrefix = [];
        for(let i = 0; i < this.weeks.length; i++){
          this.weeks[i].hidePrefix = false;
          if(storePrefix.includes(this.weeks[i].prefix)){
            this.weeks[i].hidePrefix = true;
          }
          storePrefix.push(this.weeks[i].prefix);
         
        }
        this.pageLoading = false;
        this.loading = false;
      });
    this.loading = false;
  }
  getYearlySales()
  {
    this.loading = true;
      this.salesService.getYearlySales_v2(this.selectedStorage.id,this.yearQuarter.year,this.selectedStorage.created_at).subscribe((response: any) =>{
       
		  
		this.actualSalesByWeek = response.monthsale;
		this.projWeeklyRevQuarter = response.all_projected_sales;
		this.projectedSalesByWeek = response.proj_month_rev_quarter;
		this.showLineChart();
        this.loading = false;
      });
    this.loading = false;
  }
	getQuarterSale()
	{
		this.actualSalesTotal = 0.00;
		this.loading = true;
		this.salesService.getQuarterSale(this.selectedStorage.id,this.yearQuarter.year).subscribe((response: any) =>{
			this.quarterfirstsales = response.quarterSales[0];
			this.quartersecondsales = response.quarterSales[1];
			this.quarterthirdsales = response.quarterSales[2];
			this.quarterforthsales = response.quarterSales[3];
			this.loading = false;
			
			this.actualSalesTotal = this.quarterfirstsales+this.quartersecondsales+this.quarterthirdsales+this.quarterforthsales;
		});
		
		this.loading = false;
	}
  
  calcActualSalesTotal(){
    this.initActualSalesByWeekArray();
    for (let i = 0; i < this.weeks.length; i++) {
      let total = this.weeks[i].totalDelivery + this.weeks[i].totalWire + this.weeks[i].totalRetail + this.weeks[i].totalWidding_events + this.weeks[i].totalWholesale;
      this.actualSalesByWeek[(this.weeks[i].number - (13 * (this.yearQuarter.quarter - 1)))-1] = total;
    }
  }

  loadHeaders(){
    this.cols = [
      { field: 'week', header: 'Week', width: '16' },
      { field: 'merchandiseSales', header: 'Retail Sales', width: '14' },
      { field: 'wireSales', header: 'Event Sales', width: '14' },
      { field: 'deliverySales', header: 'Wire Sales', width: '14' },
      { field: 'wholesale', header: 'Wholesale', width: '14' },
      { field: 'deliverySales', header: 'Delivery', width: '14' },
      { field: 'total', header: 'Total Sales', width: '14' },
    ];
  }

  onRowEditInit(days: any) {
    this.clonedDays[days.id] = {...days};
  }
	showEditDay(title:any) {
    const selWeek = String($('#week_'+title).val()).replace('$','');
		this.selWeek = Number(selWeek);
		this.dialogValues.id = String($('#dayid_'+title).val()).replace('$','');
		this.dialogValues.retail = String($('#retail_'+title).val()).replace('$','');
        this.dialogValues.wire = String($('#wire_'+title).val()).replace('$','');
        this.dialogValues.delivery = String($('#delivery_'+title).val()).replace('$','');
        this.dialogValues.widding_events = String($('#widding_events_'+title).val()).replace('$','');
        this.dialogValues.wholesale = String($('#wholesale'+title).val()).replace('$','');
        this.dialogValues.entered_date = $('#entered_date_'+title).val();
		this.saleformsum = parseFloat(this.dialogValues.retail)+parseFloat(this.dialogValues.wire)+parseFloat(this.dialogValues.delivery)+parseFloat(this.dialogValues.widding_events) +parseFloat(this.dialogValues.wholesale);
		this.visible = true;
	}
	
	keyUpFunction(event)
	{
		if(event.keyCode == 13)
		{
			this.updateDay();
		}
		this.setSaleForSum();
	}
    
	get f() { return this.dayform.controls; }
	
	updateDay(){
    this.formloading = true;
    this.submitted = true;
    if (this.dayform.invalid) {
      this.formloading = false;
      return;
    }
    this.error = '';
    
    this.salesService.updateDay_v2(this.selectedStorage.id,this.f.id.value, this.f.entered_date.value, this.f.retail.value, this.f.wire.value, this.f.delivery.value, this.f.widding_events.value,this.f.wholesale.value)
        .pipe(first())
        .subscribe(
            data => {
				this.getSales();
				this.getYearlySales();
				this.getQuarterSale();
              this.visible = false;
              this.formloading = false;
            },
            error => {
              this.visible = false;
              this.formloading = false;
            });
	}
  
	setSaleForSum(){
		this.saleformsum = 0;
		if(this.f.retail.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.retail.value);
		if(this.f.wire.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.wire.value);
		if(this.f.widding_events.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.widding_events.value);
		if(this.f.delivery.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.delivery.value);
      if(this.f.wholesale.value != "")
			this.saleformsum = this.saleformsum+parseFloat(this.f.wholesale.value);
	}
  onRowEditSave(days: any, index: number) {
      if (days.merchandise >= 0 && days.wire >= 0 && days.delivery >= 0) {
        this.loading = true;
        this.salesService.updateDay(days.id,days.merchandise,days.wire,days.delivery).subscribe(
              response=> {
                this.loading = false;
                delete this.clonedDays[days.id];
                this.getSales();
				this.getYearlySales();
				this.getQuarterSale();
              },
              error => {
                delete this.clonedDays[days.id];
                this.loading = false;
              }
        );
              
      }
      else {
        this.getSales();
      }
  }

  onRowEditCancel(days: any, index: number) {
    this.getSales();
	this.getQuarterSale();
	this.getYearlySales();
  }

 
  getProjectedSales(){
    this.salesService.getProjWeeklyRevQuarter(this.selectedStorage.id,this.yearQuarter.year,this.yearQuarter.quarter).subscribe((response: any) =>{
      this.projWeeklyRevQuarter = response.proj_weekly_rev_quarter;
      this.projectedSalesByWeek = response.all_projected_sales;
      this.showLineChart();
    });
  }

  openSalesModal(week_id){
    this.daily_revenue.clear();
    this.week_show = this.weeks[week_id].week_show;
    this.week_prefix = this.weeks[week_id].prefix;

    this.week_sales = this.weeks[week_id].weekSales;
	 for (let i = 0; i < this.week_sales.length; i++) {
      this.daily_revenue.push(this.insertData());
    }

  }
	
	retailsSum() {
  	return this.week_sales.map(week => week.retail).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
	}
	widding_eventsSum() {
  	return this.week_sales.map(week => week.widding_events).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
	}
	wiresSum() {
  	return this.week_sales.map(week => week.wire).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
	}
	deliverysSum() {
  	return this.week_sales.map(week => week.delivery).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
	}
  wholesaleSum() {
  	return this.week_sales.map(week => week.wholesale).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
	}
	overallsum()
	{
	 return	this.retailsSum() + this.widding_eventsSum() + this.wiresSum() + this.deliverysSum() + this.wholesaleSum();
	}

  sales_form = new FormGroup({
    daily_revenue: this.formBuilder.array([]) ,
  });
  
  
  get sales_f(){
    return this.sales_form.controls;
  }
  
  sales_submit(){
    if(this.sales_form.invalid){
      return;
    }
    for (let i = 0; i < this.sales_form.value.daily_revenue.length; i++) {
      const id = this.sales_form.value.daily_revenue[i].id;
      const entered_date = this.sales_form.value.daily_revenue[i].entered_date;
      const retail = this.sales_form.value.daily_revenue[i].retail == '' ? 0 : this.sales_form.value.daily_revenue[i].retail;
      const wire_sales = this.sales_form.value.daily_revenue[i].wire_sales == '' ? 0 : this.sales_form.value.daily_revenue[i].wire_sales;
      const delivery = this.sales_form.value.daily_revenue[i].delivery == '' ? 0 : this.sales_form.value.daily_revenue[i].delivery;
      const widding_events = this.sales_form.value.daily_revenue[i].widding_events == '' ? 0 : this.sales_form.value.daily_revenue[i].widding_events;
      const wholesale = this.sales_form.value.daily_revenue[i].wholesale == '' ? 0 : this.sales_form.value.daily_revenue[i].wholesale;
      
       this.salesService.updateDay_v2(this.selectedStorage.id, id, entered_date, retail, wire_sales, delivery, widding_events,wholesale)
        .pipe(first())
        .subscribe(
          data => {
            this.getSales();
            this.getYearlySales();
            this.getQuarterSale();
            this.visible = false;
            this.formloading = false;
          },
          error => {
            this.visible = false;
            this.formloading = false;
            
          }
        ); 
    }

    let element:HTMLElement = document.querySelector('.enterSale') as HTMLElement;
    element.click();
  }

  get daily_revenue() : FormArray {
    return this.sales_form.get("daily_revenue") as FormArray
  }

  insertData(): FormGroup {
    return this.formBuilder.group({
      id: '',
      entered_date : '',
      retail: '',
      widding_events: '',
      wholesale:'',
      wire_sales: '',
      delivery: '',
    })
  }

 
  
  checkUserPermission(type){
   
		const hasPermission = this.userPermission;
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if(currentUser.isAdmin){
			return true;
		}
		else if (typeof hasPermission !== "undefined" && hasPermission.includes(type))
		{
			return true;
		}
		else{
			return true;
		}
		return false; 
		
	}
}
