import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { NgZone } from '@angular/core';
import { AuthenticationService } from '@app/_services'; 
import Swal from 'sweetalert2/dist/sweetalert2.js'; 
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.less']
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  confirmEmail = '';
  validPassword = false;
  validPasswordMsg = '';
  emailError:string = '';
  passwordError:string = '';
  confirmPasswordError:string = '';
  signupSuccess='';
  error = '';
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute, 
    private router: Router,
    private authenticationService: AuthenticationService,
    private zone: NgZone
  ) {
    if (this.authenticationService.currentUserValue) { 
      this.router.navigate(['/home']);
  }
  }

  ngOnInit(): void {
    this.signupForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      c_password:['', Validators.required],
  });

  // get return url from route parameters or default to '/'
  this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() { return this.signupForm.controls; }
	
	keyDownFunction(event)
	{
		if(event.keyCode == 13)
		{
			this.onSubmitReg();
		}
    }
    
   

    onSubmitReg() {
        this.submitted = true; 

        // stop here if form is invalid
        if (this.signupForm.invalid) {
            this.loading = false;
            return;
        }
        

        this.loading = true;
        
        if(this.f.password.value !=this.f.c_password.value)
        {
          this.confirmPasswordError = 'Please enter same password as above';
          this.submitted = false; 
          this.loading = false;
          
        }
       
            else{
            this.confirmPasswordError = '';
              this.authenticationService.signupRequest(this.f.first_name.value,this.f.last_name.value,this.f.email.value,this.f.password.value).subscribe(
                resp => {
                  this.submitted = false; 
                  this.loading = false;
                  if(resp.status == 2){
                    this.error = resp.validationErrors.email;
                    this.passwordError = resp.validationErrors.password;
                  }else{
                    this.emailError = '';
                    this.passwordError = '';
                    
                    Swal.fire(resp.msg_desc, resp.msg, 'success')
                    this.router.navigate(['/login']);
                  
                  }
                }
              );
            }
         

        
               
    }

}
