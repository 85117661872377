import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/_models';
import {  AuthenticationService } from './authentication.service';


@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient, private AuthenticationService:AuthenticationService) { }
    //api_url = 'https://nuflorist.com/nfsadmin/api';
    //api_url = 'http://127.0.0.1:8000/api';
      api_url = 'https://dev.iflorist.io/ifsadmin/api';
    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }
    public static imagePath: string = environment.imagePath;
    forgotPassword(email) {
        const product = 'iflorist';
        return this.http.post<any>(this.api_url + '/auth/forgotpassword', { email,product });
      }
 
      changeUserPassword(newPassword,confirmPassword) {
       var current_user = this.AuthenticationService.currentUserValue;
       var user_id = localStorage.getItem('user_id');   
        return this.http.post<any>(this.api_url + '/auth/updateUserPassword',{newPassword,confirmPassword,user_id})
      }

      checkPasswordToken( p_token){
        // console.log(token);
          return this.http.post<any>(this.api_url + '/auth/checkPasswordToken',{ p_token})     
         
        }  


}