import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) { 
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any { 
        return this.currentUserSubject.value;
    }

    signupRequest(first_name,last_name,email, password) {
        return this.http.post<any>(`${environment.apiUrl}/auth/signUp`, {first_name,last_name,email, password });
      }
    // loginwithToken(token) {
    //     return this.http.post<any>(`${environment.apiUrl}/auth/loginwithToken`, {token });
    //   }
      getUnAllottedLicensesList(){
        var user_id = localStorage.getItem('user_id'); 
        return this.http.post<any>(`${environment.apiUrl}/auth/getUnAllottedLicensesList`, {user_id });
      }
      loginwithToken(token) {
        return this.http.post<any>(`${environment.apiUrl}/auth/loginwithToken`, { token })
            .pipe(map(data => {
                if(data.status=='2')
                {
                    return data;
                }
                else
                {
                  console.log(data);
					
                    localStorage.setItem('currentUser', JSON.stringify(data));
                    localStorage.setItem('user_id', data.user_id);
                    localStorage.setItem('role_vendor', data.role_vendor);
                    localStorage.setItem('user_type', data.user_type);
                    
                    this.currentUserSubject.next(data);
                    return data;
                }
                
            }));
    }
    login(email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/auth/login`, { email, password })
            .pipe(map(data => {
                if(data.status=='2')
                {
                    return data;
                }
                else
                {
                  console.log(data);
					          localStorage.setItem('currentUser', JSON.stringify(data));
                    localStorage.setItem('user_id', data.user_id);
                    localStorage.setItem('role_vendor', data.role_vendor);
                    localStorage.setItem('user_type', data.user_type);
                    
                    this.currentUserSubject.next(data);
                    return data;
                }
                
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('user_id');
        localStorage.removeItem('store_id');
        localStorage.removeItem('selectedStorage');
        localStorage.removeItem('yearQuarter');
        localStorage.removeItem('email');
        localStorage.removeItem('store_id_new');
        localStorage.removeItem('role_vendor');
        localStorage.removeItem('user_type');
        this.currentUserSubject.next(null);
    }
    getProductTransactionsBilling() {
        var user_id = localStorage.getItem('user_id'); 
        return this.http.post<any>(`${environment.apiUrl}/auth/getProductTransactionsBilling`, {user_id });
      }

    downloadInvoice(billing_id){
        var user_id = localStorage.getItem('user_id'); 
        return this.http.post<any>(`${environment.apiUrl}/auth/downloadInvoice`, {user_id,billing_id });
      }

    getcountries() {
        return this.http.post<any>(`${environment.apiUrl}/auth/getCountries`, {});
      }
      
    getYearList() {
        return this.http.post<any>(`${environment.apiUrl}/auth/getYearList`, {});
      }
    
    getRegions(country_id) {
        return this.http.post<any>(`${environment.apiUrl}/auth/getRegions`, {country_id});
      }
    gettimezones() {
        return this.http.post<any>(`${environment.apiUrl}/auth/getTimeZone`, {});
      }
      getStoresInfo() {
        var user_id = localStorage.getItem('user_id'); 
        return this.http.post<any>(`${environment.apiUrl}/companyemployee/getStoresInfo`, {user_id});
      }

    getAllottedLicensesList(transaction_id){
        var user_id =  localStorage.getItem('user_id');
        return this.http.post<any>(`${environment.apiUrl}/auth/getAllottedLicenses`, {transaction_id,user_id});
      }
    getProductTransactionsSingle(){
        var user_id =  localStorage.getItem('user_id');
        return this.http.post<any>(`${environment.apiUrl}/auth/getProductTransactionsSingle`, {user_id});
    }

    changeSubscription(license_id,package_id,package_type,free_plan) {
      return this.http.post<any>(`${environment.apiUrl}/auth/changeSubscription`, {license_id,package_id,package_type,free_plan});
    }
    getUserCards(){
      var user_id = localStorage.getItem('user_id');
      return this.http.post<any>(`${environment.apiUrl}/auth/getUserCards`, {user_id});
    }
    getUserCardsWorking(){
      var user_id = localStorage.getItem('user_id');
      return this.http.post<any>(`${environment.apiUrl}/auth/getUserCardsWorking`, {user_id});
    }
    getUserPlans(){
      var user_id = localStorage.getItem('user_id');
      return this.http.post<any>(`${environment.apiUrl}/auth/getUserPlans`, {user_id});
    }
    updateLicenseCard(card_id,license_id){
      var user_id = localStorage.getItem('user_id');
      return this.http.post<any>(`${environment.apiUrl}/auth/updateLicenseCard`, {card_id,user_id,license_id});
    }
    stripe_key(){
      return 'pk_test_3TLPNfRAH1xS1R8mZRmlZ9An00S1qrBW9R';
    }
    getProductPackageAll(package_type) {
      return this.http.post<any>(`${environment.apiUrl}/auth/getProductPackageAll`, {package_type});
    }
    addUserCard(card_id,stripe_token,licenseId,addcard = ''){
      var user_id = localStorage.getItem('user_id');
      return this.http.post<any>(`${environment.apiUrl}/auth/addUserCard`, {user_id,card_id,stripe_token,licenseId,addcard});
      
    }
    Cancellicenses(subscription_id){
      var user_id = localStorage.getItem('user_id');
      return this.http.post<any>(`${environment.apiUrl}/auth/cancelSubscription`, {subscription_id,user_id});
    }

    getByStoreId(store_id)
    {
      return this.http.get<any>(`${environment.apiUrl}/companyemployee/getByStoreId/${store_id}`, {});
    }

    updateStore(storeData){
      var user_id = localStorage.getItem('user_id');
      let StoreData = {
        "storeData":storeData,
        "user_id":user_id
      }
      return this.http.post<any>(`${environment.apiUrl}/auth/updateStore`, {StoreData});
      
    }

    CreateCompanyAndStores(companyData){
      var user_id = localStorage.getItem('user_id');
      // let storesData  = JSON.parse(companyData);  
      let CompanyData = {
        "CompanyData":companyData,
        "user_id":user_id
      }
    
      return this.http.post<any>(`${environment.apiUrl}/auth/addCompanyPlusStore`, {CompanyData});
    }
    checkFreeTrial() {
      var user_id = localStorage.getItem('user_id');
      return this.http.post<any>(`${environment.apiUrl}/auth/checkFreeTrial`, {user_id});
    }
    getProductPackage() {
      return this.http.post<any>(`${environment.apiUrl}/auth/getProductPackage`, {});
    }
    getProductPackageById(package_id) {
      return this.http.post<any>(`${environment.apiUrl}/auth/getProductPackageById`, {package_id});
    }
    GetUserDetails() {
        var user_id = localStorage.getItem('user_id');
        return this.http.post<any>(`${environment.apiUrl}/auth/getUserDetails`, {user_id});
    }
    getCustomerCards(){
        var user_id =  localStorage.getItem('user_id');
        return this.http.post<any>(`${environment.apiUrl}/auth/getCustomerCards`, {user_id});
    }

    custmoreRegisterFinal(customre, StripeToken, Card_id, Total_price, packageDetail, save_card='') {
var store_id = localStorage.getItem('store_id_new')
        if(localStorage.getItem('user_id') !== null){
          var user_id =  localStorage.getItem('user_id');
         } 
        let filterProducts = JSON.parse(localStorage.getItem("FilterProduct"));
        let TotalAmount = JSON.parse(localStorage.getItem('OverallTotal'))
        let AllData = {
          "customre":customre,
          "StripeToken":StripeToken,
          "Card_id":Card_id,
          "filterProducts":packageDetail,
          "TotalAmount":Total_price,
          "user_id":user_id,
          "store_id":store_id,
          "save_card":save_card,
        }

     return this.http.post<any>(`${environment.apiUrl}/auth/customerRegisterFinal`, {AllData});
  }

   
   
}
