import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {retry, catchError} from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WeeklyProjectionService {

  constructor(
      private http: HttpClient
  ) { }

  getProjectionList(store_idd,year): Observable<any> {
    var user_id = localStorage.getItem('user_id'); 
    var store_id = localStorage.getItem('store_id'); 
    return this.http.get(`${environment.apiUrl}/weekly_projection_percent_revenue/projections/${store_id}/${year}/${user_id}`);
  }

  updateProyection(proyection_id,adjust,projected_value, week_number, store_idd, year_projection, year_reference, amt_total){
    var store_id = localStorage.getItem('store_id'); 
    return this.http.put(`${environment.apiUrl}/weekly_projection_percent_revenue/projections/update/${proyection_id}`,
        {adjust, projected_value, week_number, store_id, year_projection, year_reference, amt_total} /*{amt_total,year_reference}*/ );
  }
  
  getProjectionList_v2(store_idd,year,quarter,type,store_at): Observable<any> {
    var user_id = localStorage.getItem('user_id'); 
    var store_id = localStorage.getItem('store_id'); 
    return this.http.get(`${environment.apiUrl}/weekly_projection_percent_revenue/projections_v2/${store_id}/${year}/${quarter}/${type}/${store_at}/${user_id}`);
  }

	updateProyection_v2(store_idd,week_year,week_number,projected_value,projected_retail,projected_wire,projected_wedding,projected_wholesale){
    var store_id = localStorage.getItem('store_id'); 
    return this.http.put(`${environment.apiUrl}/weekly_projection_percent_revenue/projections/update_v2/${store_id}`,
        {week_year, week_number, projected_value,projected_retail,projected_wire,projected_wedding,projected_wholesale});
  }
  
  updateNote_v2(store_idd,note_id,text){
    var store_id = localStorage.getItem('store_id'); 
    return this.http.put(`${environment.apiUrl}/note/update_v2/${note_id}`,
        {store_id, text});
  }
  
  getNotes_v2(store_idd, year): Observable<any> {
    var store_id = localStorage.getItem('store_id'); 
    return this.http.get(`${environment.apiUrl}/note/allyear/${store_id}/${year}`);
  }

  updateSale(store_idd,week_year,week_number,week_sale){
    var store_id = localStorage.getItem('store_id'); 
    return this.http.put(`${environment.apiUrl}/weekly_projection_percent_revenue/projections/update_sale/${store_id}`,
        {week_year, week_number, week_sale});
  }
	 getweekprojectionbyid(id)
  {
      return this.http.get(`${environment.apiUrl}/weekly_projection_percent_revenue/getweeprojectval/${id}`);
  }

  updateNote(week_number,note_text,year, store_idd){
    var store_id = localStorage.getItem('store_id'); 
    return this.http.post(`${environment.apiUrl}/weekly_projection_percent_revenue/projections/update_note`,
        {week_number, note_text, year, store_id});
  }
	
	create_store_percent(store_idd,cof_retail,cof_wire,cof_weddings,cog_retail,cog_wire,cog_weddings){
    var store_id = localStorage.getItem('store_id'); 
    return this.http.post(`${environment.apiUrl}/store_percent/create`,{store_id,cof_retail,cof_wire,cof_weddings,cog_retail,cog_wire,cog_weddings});
  }
	getStoresPercent(store_idd){
    var store_id = localStorage.getItem('store_id'); 
    return this.http.post(`${environment.apiUrl}/store_percent/get`,{store_id});
  }
	
}
