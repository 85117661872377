import { Component, Output, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { UserService, AuthenticationService, PlanService, CompanyService } from '../_services';
/*import {PlanService} from '../_services'*/
import { User,Role } from '../_models';
import { CheckRole } from "../_helpers/check-role";
import { MessageService } from "../_services/message.service";
@Component({
  selector: 'app-select-store',
  templateUrl: './select-store.component.html',
  styleUrls: ['./select-store.component.less']
})
export class SelectStoreComponent implements OnInit {
  currentUser: User;
  toggled: boolean = false;
  modules: any;
  stores: Storage[] = [];
	companyName:string = '';
	varr:boolean = true;
	storeIndexSelected:number;
  imagePath = UserService.imagePath;
  constructor(
    private router: Router,
    private userService: UserService,
    private planService:PlanService,
    private companyService:CompanyService,
    private authenticationService: AuthenticationService,
    private checkRole: CheckRole,
    private messageService: MessageService,
  ) {
    var local_store_id = localStorage.getItem('store_id');
		const currentUser = this.authenticationService.currentUserValue;
		if(currentUser !== null){
			this.stores = currentUser.storeInfo;
			localStorage.setItem('api_access_token', JSON.stringify(currentUser.access_token));
		}
    if(this.stores && this.stores.length > 0){
			this.storeIndexSelected = this.stores[0].id;
    }
    var store_id = local_store_id != 'null' ? local_store_id : this.storeIndexSelected;
		this.storeSelected(store_id,'');


   }
  @Output() messageToEmit = new EventEmitter<string>();
  ngOnInit(): void {
    
		
  }


  storeSelected(store_id,dropdown=''){
    this.messageToEmit.emit(store_id);
    this.storeIndexSelected = store_id;
    localStorage.setItem('store_id', store_id);
    var foundStore = this.stores.filter(obj=>obj.id == store_id);
    console.log(foundStore,'foundStore')
    console.log(store_id);
    if(store_id=='90000')
		{
      setTimeout(() => {
				$('.vertMenuUl li:nth-child(6)').hide();
			  }, 1000); 
		}
    let old_storage = localStorage.getItem('selectedStorage')
    let new_store = foundStore.length > 0 ? foundStore[0] : JSON.parse(old_storage);
    this.populateSelectedStorage(new_store);
    this.sendMessage(new_store);
	}
	populateSelectedStorage(storage){
		localStorage.setItem('selectedStorage', JSON.stringify(storage));
  }

  sendMessage(value): void {
    // send message to subscribers via observable subject
    this.messageService.sendMessage(value);
  }
  
}
