import { Component, OnInit, NgZone } from '@angular/core';
import { AuthenticationService } from '@app/_services'; 
import { SalesService } from "../_services/sales.service";
import { UtilsService } from "../_services/utils.service";
import { AuthGuard } from "../_helpers/auth.guard";
import {TableModule} from 'primeng/table';
import {Observable} from "rxjs";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray ,ValidationErrors} from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-add-product-subscriptions',
  templateUrl: './add-product-subscriptions.component.html',
  styleUrls: ['./add-product-subscriptions.component.less']
})
export class AddProductSubscriptionsComponent implements OnInit {
  paymentType: string = 'M';
  paymentTypeName : string = '';
  productPackage = [];
  freeTrial = false;
  freeTrialDays = '';
  licenseCount = [];
  licenseCountSelected = [];
  form : FormGroup;
  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,//service used to receive store from top bar stores combobox
    private salesService: SalesService,
    private utilService: UtilsService,
    private route : Router,
    private authGuard : AuthGuard,
    private zone: NgZone,
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      //licence_count: new FormControl(''),
    });
    document.body.classList.remove('loginbody');
    let list = [];
    for (var i = 1; i <= 10; i++) {
      list.push(i);
    }
    this.licenseCount = list;
    this.paymentType = localStorage.getItem('package_type') != null ? localStorage.getItem('package_type') : 'M';
    this.authenticationService.getProductPackage().subscribe((data: any) => {
      if (data.status == 1) {
        let i = 0;
        let array = [];
        var license_count_arr = localStorage.getItem('license_count_arr') != null ? JSON.parse(localStorage.getItem('license_count_arr')): {};
        data.productPackage.forEach(function (value) {
          
          const selected_license = license_count_arr !== undefined && license_count_arr[value.id] !== undefined && license_count_arr[value.id] != null ? license_count_arr[value.id] : '1';
          value.description_points = JSON.parse(value.description_points);
          value.selected_license = selected_license;
          array[i] = value;
          i++;
        });
        this.productPackage = array;
        this.paymentTypeName = this.paymentType == 'M' ? 'Month' : 'Year';
      }
      
    });


    this.authenticationService.checkFreeTrial().subscribe((data: any) => {
      if (data.status == 1) {
        this.freeTrial = true;
        this.freeTrialDays = data.days;
      }
    });
  }
  paymentTypeChange(event){
    const target = event.checked;
    if(target){
      this.paymentType = 'M';
    }
    else{
      this.paymentType = 'Y';
    }
    localStorage.setItem('package_type', this.paymentType);
  }

  submit(event){
    const target = event.target;
    const package_id = target.querySelector(".package_id").value;
    const license_count = target.querySelector(".licence_count").value;
    let license_count_arr= {
    };
    license_count_arr[package_id] = license_count;
    this.licenseCountSelected = license_count;
    localStorage.setItem('license_count', license_count);
    this.route.navigate(['/checkout/'+package_id]);
  }

}
