import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/_services'; 
import { SalesService } from "../_services/sales.service";
import { UtilsService } from "../_services/utils.service";
import { AuthGuard } from "../_helpers/auth.guard";
import {TableModule} from 'primeng/table';
import {Observable} from "rxjs";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.less']
})
export class PaymentsComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,//service used to receive store from top bar stores combobox
    private salesService: SalesService,
    private utilService: UtilsService,
    private route : Router,
    private authGuard : AuthGuard,
  ) { }
  transactions = [];
  ngOnInit(): void {
    document.body.classList.remove('loginbody');
    this.authenticationService.getProductTransactionsBilling().subscribe((data: any) => {
      if (data.status == 1) {
        console.log(data.transactions);
        this.transactions = data.transactions;
      }
    });
  
  }
  downloadClick(billing_id){
    this.authenticationService.downloadInvoice(billing_id).subscribe((data: any) => {
      //console.log(data);
      if(data.status == 1){
        const invoice_file = data.invoice_url;
        const download_invoice = document.querySelector('#download_invoice');
        if (download_invoice instanceof HTMLElement) {
          download_invoice.setAttribute('href', invoice_file);
          download_invoice.click();
        }
      }
    });
  }

}
