import { Component, OnInit, NgZone } from '@angular/core';
import { AuthenticationService } from '@app/_services'; 
import { SalesService } from "../_services/sales.service";
import { UtilsService } from "../_services/utils.service";
import { AuthGuard } from "../_helpers/auth.guard";
import {TableModule} from 'primeng/table';
import {Observable} from "rxjs";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { CompanyemployeeService } from '../_services/companyemployee.service';

@Component({
  selector: 'app-manage-subscriptions',
  templateUrl: './manage-subscriptions.component.html',
  styleUrls: ['./manage-subscriptions.component.less']
})
export class ManageSubscriptionsComponent implements OnInit {
  loadStripe() {
     
    // if(!window.document.getElementById('stripe-script')) {
    //   var s = window.document.createElement("script");
    //   s.id = "stripe-script";
    //   s.type = "text/javascript";
    //   s.src = "https://js.stripe.com/v2/";
    //   s.onload = () => {
    //     window['Stripe'].setPublishableKey(this.authenticationService.stripe_key());
    //   }
    //   window.document.body.appendChild(s);
    // }

    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        (window as any)['Stripe'].setPublishableKey(this.authenticationService.stripe_key());
      };
      window.document.body.appendChild(s);
    }


  }
  userService: any;
  //successMessage = HelperService.successMessage;
  hasSubscription = false;
  planloading = false;
  addcardloading = false;
  storeSubmitted = false;
  stateLoadingStore = false;
  storeupdateloading = false;
  States = [];
  Stores = [];
  subscription = [];
  yearList= [];
  Store_data = [];
  planSuccessMessage='';
  planErrorMessage = '';
  store_added:string="";
  card_form_submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,//service used to receive store from top bar stores combobox
    private salesService: SalesService,
    private utilService: UtilsService,
    private route : Router,
    private authGuard : AuthGuard,
    private zone: NgZone,
    private companyEmployeeService: CompanyemployeeService,
  ) { }
  countries=[];
  timezones=[];
  add_card_submitted = false;
  transaction_id = '';
  licenseCount : number;
  allottedLicenses = [];
  productPackage = [];
  package_id :string = '';
  subscriptionSubmitClick = false;
  package_type:string='month';
  StripeCardEror:string = "";
  cardList = [];
  planList = [];
  cardCount = "";
  cardListWorking = [];
  cardCountWorking = [];
  license_count:any = "";
  licenseId = '';
  cardSelectMessage = "";
  buttonLoading = false;
  ngOnInit(): void {
    this.loadStripe();
    document.body.classList.remove('loginbody');
    this.authenticationService.getcountries().subscribe((data: any) => {
      this.countries = data.countries;
    });

    this.authenticationService.gettimezones().subscribe((data: any) => {
      this.timezones = data;
    });
    this.authenticationService.getStoresInfo().subscribe((data: any) => {
      this.Stores = data.stores;
    });
    this.authenticationService.getYearList().subscribe((data: any) => {
      this.yearList = data;
    });
   

    this.authenticationService.getProductTransactionsSingle().subscribe((data: any) => {
      if (data.status == 1 && data.transactions) {
        this.hasSubscription = true;
        this.subscription = data.transactions;
        this.package_id = data.transactions.package_id;
      }
      // else{
      //   this.router.navigate(['/subscriptions']);
      // }
      //this.SpinnerService.hide(); 
      //this.divReady = true;
      
    });
    this.userCards();
    this.getUserCardsWorking();
    this.manageLicences();
    this.getPackageList();

  }

  fillStoreForm(index){
    this.authenticationService.getByStoreId(index).subscribe((data: any) => {
    this.Store_data = data;
    const country = data.country;
      this.authenticationService.getRegions(country).subscribe(res => {
        this.States = res.regions;
      });
   })
  }

  manageLicences(){
    this.authenticationService.getAllottedLicensesList(this.transaction_id).subscribe((data: any) => {
      if (data.status == 1) {
        this.licenseCount = data.subscription_info.length;
        this.allottedLicenses = data.subscription_info
      }
    })
  }

  subscription_form = new FormGroup({
    package_id: new FormControl(this.package_id, [Validators.required]),
    license_id: new FormControl('', [Validators.required]),
    package_type: new FormControl('', [Validators.required]),
    free_plan: new FormControl('', [Validators.required]),
  });
  
  get subscription_f(){
    return this.subscription_form.controls;
  }
  
  submit_subscription(){
    this.planSuccessMessage = '';
    this.planErrorMessage = '';
    this.planloading = true; 
    this.subscriptionSubmitClick = true;
   // ////console.log(this.subscription_form.value);
    const license_id = this.subscription_form.value.license_id;
    const package_id = this.subscription_form.value.package_id;
    const package_type = this.subscription_form.value.package_type;
    const free_plan = this.subscription_form.value.free_plan;
    if (this.subscription_form.invalid) {
      this.planloading = false; 
      return;
    }
    this.authenticationService.changeSubscription(license_id, package_id, package_type,free_plan).subscribe((res => {
      this.planloading = false; 
      ////console.log(res);
      if(res.status == 1){
        this.manageLicences();
        this.planSuccessMessage = res.msg;
        setTimeout(() => {
          this.planSuccessMessage = '';
          this.planErrorMessage = '';
          const cancelbttn = document.querySelector('.cancelbttn_plan');
          if (cancelbttn instanceof HTMLElement) {
              cancelbttn.click();
          }
        }, 1500);
      }
      if(res.status == 2){
        this.planErrorMessage = res.msg;
      }
    }));
  }

  selectPackage(event) {
    this.planSuccessMessage = '';
    this.planErrorMessage = '';
  }
  changePlan(event,isFreePlan){
    this.planErrorMessage = "";
    this.subscriptionSubmitClick = false;
    this.subscription_form['controls']['license_id'].patchValue(event);
    this.subscription_form['controls']['free_plan'].patchValue(isFreePlan);
    this.subscription_form['controls']['package_type'].patchValue("");
    this.subscription_form['controls']['package_id'].patchValue("");
  }
  changePlanType(event){
    this.package_type = event;
    this.getPackageList();
    this.subscription_form['controls']['package_id'].patchValue("");
    this.planErrorMessage = "";
    this.planSuccessMessage = "";
  }
  cancelSubscription(id){
    if (confirm("Are you sure you want to cancel the subscription?")) {
     // this.SpinnerService.show(); 
      this.authenticationService.Cancellicenses(id).subscribe((res) => {
       // this.SpinnerService.hide(); 
        this.manageLicences();
      })
    }
  }

  userCards(){
    this.authenticationService.getUserCards().subscribe((res => {
      if(res.status == 1){
        this.cardList = res.card;
        this.cardCount = res.card.length;
      }
    }));
  }
  getUserCardsWorking(){
    this.authenticationService.getUserCardsWorking().subscribe((res => {
      if(res.status == 1){
        this.cardListWorking = res.card;
        this.cardCountWorking = res.card.length;
      }
    }));
  }
  getUserPlans(){
    this.authenticationService.getUserPlans().subscribe((res => {
      if(res.status == 1){
        this.planList = res.plan;
        this.license_count = res.license_count;
      }
    }));
  }
  SelectedCard(event){
  }
  card_form = new FormGroup({
    card_id: new FormControl('', [Validators.required]),
    license_id: new FormControl(''),
  });
  
  get card_f(){
    return this.card_form.controls;
  }

  card_submit(){
    //this.SpinnerService.show();
    this.card_form_submitted = true;
    if(this.card_form.invalid){
      //this.SpinnerService.hide();
      return false;
    }
    const card_id = this.card_form.value.card_id;
    const license_id = this.card_form.value.license_id;
    this.authenticationService.updateLicenseCard(card_id,license_id).subscribe((data: any) => {
      //this.SpinnerService.hide();
      if (data.status == 1) {
        this.cardSelectMessage = data.msg;
        this.ngOnInit();
        setTimeout(() => {
          this.cardSelectMessage = '';
          const close_card_mdl = document.querySelector('.close_card_mdl');
          if (close_card_mdl instanceof HTMLElement) {
            close_card_mdl.click();
          }
          this.card_form.reset();
          this.card_form_submitted = false;
        }, 1500);
      }
    });
  }


  selectCard(){
    this.card_form_submitted = false;
    this.licenseId = '';
    this.card_form.get("card_id").setValue("");
  }
  selectCardSingle(event){
    this.card_form_submitted = false;
    this.card_form.get("card_id").setValue("");
    this.card_form.get("license_id").setValue(event);
    this.licenseId = event;
  }
  addNewCard(){
    const close_card_mdl = document.querySelector('.close_card_mdl');
    if (close_card_mdl instanceof HTMLElement) {
      close_card_mdl.click();
    }
  }
  add_card_form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required]),
    exp_month: new FormControl('', [Validators.required]),
    exp_year: new FormControl('', [Validators.required]),
    cvv: new FormControl('', [Validators.required]),
  });
  
  get add_card_f(){
    return this.add_card_form.controls;
  }
  add_card_submit(){
    this.buttonLoading = true;
    this.add_card_submitted = true;
    //this.SpinnerService.show();
    if(this.add_card_form.invalid){
      this.buttonLoading = false;
    //  this.SpinnerService.hide();
      return false;
    }
    //console.log(this.add_card_form.value);
    const CardNumber = this.add_card_form.get('number').value.trim();
    const Expirationdate = this.add_card_form.get('exp_month').value;
    const expYear = this.add_card_form.get('exp_year').value;
    const CVV = this.add_card_form.get('cvv').value.trim();
   
    if (!window['Stripe']) {
      alert('Oops! Stripe did not initialize properly.');
      return;
    }
    
    (<any>window).Stripe.card.createToken({
      number: CardNumber,
      exp_month: Expirationdate,
      exp_year: expYear,
      cvc: CVV,
    }, (status: number, response: any) => {
      this.zone.run(() => {
        this.buttonLoading = false;
        //this.SpinnerService.hide();
        if (status == 200) {
          this.StripeCardEror = '';
          const stripe_token = response.id ;
          const card_id = response.card.id;
          this.authenticationService.addUserCard(card_id,stripe_token,this.licenseId).subscribe((result)=>{
            if (result.status == 1) {
              this.StripeCardEror = result.msg;
              this.userCards();
              setTimeout(() => {
                this.ngOnInit();
                this.StripeCardEror = '';
                const close_card_mdl_add = document.querySelector('.close_card_mdl_add');
                if (close_card_mdl_add instanceof HTMLElement) {
                  close_card_mdl_add.click();
                }
                
                this.add_card_form.get('number').setValue("");
                this.add_card_form.get('exp_month').setValue("");
                this.add_card_form.get('exp_year').setValue("");
                this.add_card_form.get('cvv').setValue("");
                this.add_card_submitted = false;
              }, 1000);
            }
            else{
              this.StripeCardEror = result.validationErrors.card;
            }
          });
        } else {
            this.StripeCardEror = response.error.message;
            this.buttonLoading = false;
        }
      });
     
      
    });
   
  }

  getPackageList(){
    this.authenticationService.getProductPackageAll(this.package_type).subscribe((data: any) => {
      if (data.status == 1) {
        let i = 0;
        let array = [];
        var license_count_arr = localStorage.getItem('license_count_arr') != null ? JSON.parse(localStorage.getItem('license_count_arr')): {};
        if(data.productPackage.length>0){
          data.productPackage.forEach(function (value) {
            
            const selected_license = license_count_arr !== undefined && license_count_arr[value.id] !== undefined && license_count_arr[value.id] != null ? license_count_arr[value.id] : '1';
            value.description_points = JSON.parse(value.description_points);
            value.selected_license = selected_license;
            array[i] = value;
            i++;
          });
        }
        this.productPackage = array;
        //this.SpinnerService.hide(); 
        ////console.log(this.productPackage);
      }
      
    });
  }

  store_update = new FormGroup({
    id: new FormControl(''),
    Storeimage: new FormControl(''),
    store_name: new FormControl('', [Validators.required]),
    owner_name: new FormControl('', [Validators.required]),
    contact_number: new FormControl('', [Validators.required]),
    email_Address: new FormControl('', [Validators.required]),
    address_line1: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    address_line2: new FormControl(''),
    city: new FormControl('', [Validators.required]),
    State: new FormControl('', [Validators.required]),
    zip: new FormControl('', [Validators.required]),
    timezone: new FormControl("", [Validators.required]),
    primary_color: new FormControl(''),
  });
  get store_form(){
    return this.store_update.controls;
  }

  store_submit(event){
    event.preventDefault();
    this.storeSubmitted = true;
    this.storeupdateloading = true; 
    if (this.store_update.invalid) {
      this.storeupdateloading = false; 
      return;
    }
    const storeData = this.store_update.value;
    const store_id = this.store_update.value.id;
    const country = this.store_update.value.country;
    if(store_id){
      this.authenticationService.updateStore(storeData).subscribe(resp => {
        this.storeupdateloading = false; 
        if (resp.status == 2) {
        } else {
          this.ngOnInit();
          //this.store_added = this.successMessage;
          setTimeout(() => {
            this.store_added = '';
            const close_store_modal = document.querySelector('.close_store_modal');
            if (close_store_modal instanceof HTMLElement) {
              close_store_modal.click();
            }
          }, 1000);
        }
      })  
    }
   
  }

  changeStateStore(event) {
    this.stateLoadingStore = true;
    this.authenticationService.getRegions(event).subscribe(res => {
      this.States = res.regions;
      this.stateLoadingStore = false;
    })
    this.store_update.get('State').setValue("");

  }

  renewlic(store_id)
  {
    localStorage.setItem('store_id_new', store_id);
    this.route.navigate(['/add-product-subscriptions']);
  }



}
