import { ViewEncapsulation, Component, OnInit } from '@angular/core';
import { StoreSubscriberService } from "../_services/storeSubscriber.service";
import {TableModule} from 'primeng/table';
import {WeeklyProjectionService} from "../_services/weeklyprojection.service";
import { MessageToastService } from "../_services/messageToast.service";
import { UtilsService } from "../_services/utils.service";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {first} from "rxjs/operators";
import { WeekPanelService } from "../_services/weekPanel.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as $ from 'jquery' 
declare  var jQuery:  any;
import { AuthGuard } from "../_helpers/auth.guard";
@Component({
  selector: 'app-weeklyprojection',
  templateUrl: './weeklyprojection.component.html',
  styleUrls: ['./weeklyprojection.component.less'],
  /*encapsulation: ViewEncapsulation.None*/
})
export class WeeklyProjectionComponent implements OnInit {
	submittedFormNote: boolean = false;
	noteloading: boolean = false;
	visibleDialogNote: boolean = false;
	visibleDialogNote1: boolean = false;
	Userloading = false;
  store_ID:any;
	noteerror: "";
	dialogValues : any;
	hideEdit : "";
	hideEdit2 : "";
  cols:any;
  yearIndexSelected:number;
  selectedStorage: any;
  proyections:any[];
  noteyears:any[];
  allnotes:any[];
  quarterprojections:any[];
  yearprojections:any[];
  loading: boolean;
  adjustOptions: any[] = [] ;
  clonedProjections: { [s: string]: any; } = {};
  yearQuarter: any;
  projected_value_temp: number = -1;
  adjust_temp: number = -1;
  storename: string;
  week_year:number;
  week_number:number;
  week_sale:number;
  noteform: FormGroup;
  projected_retail:number;
  projected_wire:number;
  projected_wholesale:number;
  projected_weddings:number;
  projected_wedding:number;
  week_proj_id:number;


  note_id = '';
  edit_note = false;
  edit_projection_submitted = false;
  noteAddEdit = '';
  noteAddEditBtn = '';
  requiredError = [];

  userPermission = [];

  loadingPage = true;
  constructor(
    private formBuilder: FormBuilder,
    private storeSubscriberService: StoreSubscriberService,
    private projectionService: WeeklyProjectionService,
    private messageToastService: MessageToastService,
    private utilService: UtilsService,
    private weekPanelService: WeekPanelService,
    private authGuard : AuthGuard,
  ) 
  {
    this.authGuard.userPermissions();
    const permissions = this.authGuard.userPermissions();
    this.userPermission = permissions;
	  this.dialogValues = {'note_id':0,'notes': ''};
    storeSubscriberService.subscribe(this,function (ref,store) {
      ref.receiveStorage(store);
    });
    let currentYear = this.utilService.GetCurrentYear();
    this.yearQuarter = {year : currentYear, quarter: this.utilService.GetCurrentQuarter()};
    for (let i = 0 ; i <= 100 ; i+=5){
      this.adjustOptions.push({'option':i, 'value': '+' + i + '%'});
    }
    this.adjustOptions
  }

  ngOnInit() {
    document.body.classList.remove('loginbody');
    this.loading = true;
    this.selectedStorage = JSON.parse(localStorage.getItem('selectedStorage'));
    this.storename = this.selectedStorage.store_name;
    this.loadHeaders();
    this.loadQuarterProjection();
    this.loadYearProjection();
	this.getNotes();
	  
	  this.projectionService.getStoresPercent(this.selectedStorage.id).subscribe((response: any) =>{
			
			if(response.status == 'false')
			{
				const cof_retail ="0.2";
		        const cof_wire = "0.2";
				const cof_weddings = "0.14";
				const cog_retail = "0.05";
				const cog_wire = "0.05";
				const cog_weddings = "0.05";
		
		this.projectionService.create_store_percent(this.selectedStorage.id,cof_retail,cof_wire,cof_weddings,cog_retail,cog_wire,cog_weddings).subscribe((response: any) =>{
		   });
			}
		});
	
	this.noteform = this.formBuilder.group({
      week_number: ['', Validators.required],
      text: ['', Validators.required],
    });
    
  }

  loadHeaders(){
    this.cols = [
      { field: 'week', header: 'Week' },
      { field: 'prev_year_sale' , header: Number(this.yearQuarter.year)-1+" Total Sales"  },//esto esta mal, hay ke poner los datos reales del year proyection y year reference
      { field: 'adjust', header: 'Adjust' },
      { field: 'current_year_sale', header: "Adjust Sales "+Number(this.yearQuarter.year) },
      { field: 'note', header: 'Event Override' },
      { field: 'note', header: 'Note' }
    ];

    }

  receiveYearQuarter($event){
    this.yearQuarter = $event;
    this.loadHeaders();
    this.loadProjection();
    this.loadQuarterProjection();
    //this.getNotes();
  }

  receiveStorage(storage){
 this.selectedStorage = storage;
    this.storename = this.selectedStorage.store_name;
    this.loadProjection();
    this.getNotes();
    this.loadQuarterProjection();
  }

  loadProjection()
  {
    this.store_ID = localStorage.getItem('store_id');
    this.proyections = [];
    this.loading = true;
    this.projectionService.getProjectionList(this.selectedStorage.id,this.yearQuarter.year).subscribe((data: any) =>{
      this.proyections = data.projections;
      this.parseData(this.proyections);
      this.loading = false;
    });
  }
  
  loadQuarterProjection()
  {
    this.loadingPage = true;
    this.proyections = [];
    this.loading = true;
    this.projectionService.getProjectionList_v2(this.selectedStorage.id,this.yearQuarter.year,this.yearQuarter.quarter,1,this.selectedStorage.created_at).subscribe((data: any) =>{
      this.quarterprojections = data.projections;
      this.loadingPage = false;
      this.loading = false;
    });
  }
  
  loadYearProjection()
  {
    this.proyections = [];
    this.loading = true;
    this.projectionService.getProjectionList_v2(this.selectedStorage.id,this.yearQuarter.year,this.yearQuarter.quarter,2,this.selectedStorage.created_at).subscribe((data: any) =>{
      this.yearprojections = data.projections;
   
      this.loading = false;
    });
  }
	showKeyupEditDay(event,section: number, week: number)
	{
		if(event.keyCode == 13)
		{
			this.showEditDay(section, week);
		}
		
	}
	
	showEditDay(section: number, week: number)
	{
    this.hideEdit2 = '';
		//this.week_year = Number($('#'+section+'_year_'+week).val());
		this.week_year = this.yearQuarter.year;
    this.week_number = week;
    const week_sale = String($('#'+section+'_current_year_sale_'+week).val()).replace('$','');
		this.week_sale = Number(week_sale);
		String($('#'+section+'_current_year_sale_'+week).attr('disabled','true'));
		$('.spin-'+week).css('display','block');
	}
	
	showEditNote(note_id: number) {
		
		this.dialogValues.note_id = note_id;
		this.dialogValues.notes = $('.note-'+note_id).text();
		this.visibleDialogNote = true;
	}
	openNoteModal(event,week_number,msg){
    this.noteform.get("text").setValue('');
    const checked = event.target.checked;
    this.noteform.get("week_number").setValue(week_number);
		if(msg =="")
		{
			this.submittedFormNote = false;
		    this.visibleDialogNote = true;
		    this.noteAddEditBtn = 'Save'
		}

    if(checked || msg){
      this.submittedFormNote = false;
      this.visibleDialogNote = true;
      this.noteAddEditBtn = 'Save'
	}
    if(msg){
      this.noteAddEditBtn = 'Update'
      this.noteform.get("text").setValue(msg);
		
    }
		
  }	
	
	
	getNotes = function(){
    this.projectionService.getNotes_v2(this.selectedStorage.id,this.yearQuarter.year).subscribe((response: any) =>{
   	this.noteyears = response.result.years;
		this.allnotes = response.result.notes;
    });
  }
	get n() { return this.noteform.controls; }
	
	updateNote(){
    this.submittedFormNote = true;
    this.noteloading = true;

    if (this.noteform.invalid) {
      this.noteloading = false;
      return;
    }
		
     this.projectionService.updateNote(this.noteform.value.week_number,this.n.text.value,this.yearQuarter.year,this.selectedStorage.id)
              .pipe(first())
              .subscribe(
                  data => {
                    this.getNotes();
                    this.visibleDialogNote = false;
                    this.n.text.setValue('');
                    this.loadQuarterProjection();
                    this.loadYearProjection();
                  },
                   error => {
                    this.noteloading = false;
              } );
        

        
  }
  
  parseData(projections){
    for (let i = 0 ; i < projections.length ; i++){
      let p = projections[i];
      p.arraypos = i;
      p.projection_id = p.id;
      p.id = i;
      if(p.adjust == undefined){
        if(p.amt_total == 0)
          p.adjust = 0;
        else
          // p.adjust = Math.round((p.projected_value - p.amt_total) * 100 / p.amt_total);
        console.log(p.adjust)
      }
      else p.adjust = parseInt(p.adjust)

      if(p.adjust < 0) p.adjust = 0;
    }
  }

  onRowEditInit(projection: any) {
    let proj = {...projection};
    this.clonedProjections[projection.id] = proj;
    this.projected_value_temp = proj.projected_value;
    this.adjust_temp = proj.adjust;
  }

  onRowEditSave(projections: any, index: number) {

        this.loading = true;
        this.projectionService.updateProyection(projections.projection_id,projections.adjust,
            projections.projected_value,projections.number,projections.store_id,projections.year_proyection ,projections.year_reference, projections.amt_total).subscribe(
              response=> {
                this.loading = false;
                delete this.clonedProjections[projections.id];
                this.messageToastService.sendMessage('success','Projection Message','Projection updated successfully !');

			   this.loadProjection();
			 
              },
              error => {
                this.proyections[index] = this.clonedProjections[projections.id];
                delete this.clonedProjections[projections.id];
               
                this.loading = false;
              }
        );
    this.cleanProjectedValue_and_Adjust_Temp();
  }

  onRowEditCancel(projections: any, index: number) {
      this.proyections[index] = this.clonedProjections[projections.id];
      delete this.clonedProjections[projections.id];
      this.cleanProjectedValue_and_Adjust_Temp();
  }

  adjustOnChange(value,pos){

    let increment = value * this.proyections[pos].amt_total / 100;
    this.proyections[pos].projected_value = this.round(parseFloat(this.proyections[pos].amt_total) + increment);
  }

  round(num){
    return Math.round((num + Number.EPSILON) * 100) / 100
  }

  cleanProjectedValue_and_Adjust_Temp(){
    this.projected_value_temp = -1;
    this.adjust_temp = -1;
  }

  projectedValueOnChange(value,pos)
  {
      if(this.projected_value_temp != value){
        this.proyections[pos].adjust = 0;
      }
      else this.proyections[pos].adjust = this.adjust_temp;
  }


  editNote(id,text){
    this.submittedFormNote = false;
    this.note_id = id;
    this.n.text.setValue(text);
    this.edit_note = true;
    this.noteAddEdit = 'Edit';
    this.noteAddEditBtn = 'Update';
  }
	
	
	projection_form = new FormGroup({
		id : new FormControl(''),
		vendor_id : new FormControl(''),
		projected_retail: new FormControl('', [Validators.required]),
		projected_wire: new FormControl('', [Validators.required]),
    projected_wholesale: new FormControl('', [Validators.required]),
    projected_wedding: new FormControl('', [Validators.required]),
		week_numberhidden: new FormControl(),
		//status: new FormControl('', [Validators.required]),
		
	});
	
	get add_projection_f(){
		return this.projection_form.controls;
	}
	
	 edit_projection_submit(){
		this.edit_projection_submitted = true;
		if(this.projection_form.invalid){
			this.Userloading = false;
			return;
		}
		this.Userloading = true;
		const projected_retail = this.projection_form.value.projected_retail;
		const projected_wire = this.projection_form.value.projected_wire;
    const projected_wholesale = this.projection_form.value.projected_wholesale;
		const projected_wedding = this.projection_form.value.projected_wedding;
		const week_numberhidden = this.projection_form.value.week_numberhidden;
		const projected_value = parseInt(projected_retail) + parseInt(projected_wire) + parseInt(projected_wedding)+ parseInt(projected_wholesale);
		
	this.projectionService.updateProyection_v2(this.selectedStorage.id,this.yearQuarter.year,week_numberhidden,projected_value,projected_retail,projected_wire,projected_wedding,projected_wholesale).subscribe(
              response=> {
				this.visibleDialogNote1 = false;
				  this.Userloading = false;
                this.loading = false;
                this.loadQuarterProjection();
                this.loadYearProjection();
		      },
              error => {
                this.loading = false;
              }
        );
		 
	
		
	}

  
  deleteNoteConfirm(id){
   Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this note!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.weekPanelService.deleteNoteIf(id).subscribe((response: any) =>{
          this.messageToastService.sendMessage('success','Note Message','One note was deleted !');
          this.getNotes();
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  archiveNote(note_id, status){
    status = status == '1' ? '0' : '1';
    if(status == '1'){
      this.weekPanelService.archiveNoteIf(note_id,status).subscribe((response: any) =>{
        this.messageToastService.sendMessage('success','Note Message','One note was unarchived !');
        this.getNotes();
      });
    }
    else{
      Swal.fire({
        title: 'Are you sure want to archive?',
      // text: 'You will not be able to recover this note!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, archive it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.weekPanelService.archiveNoteIf(note_id,status).subscribe((response: any) =>{
            this.messageToastService.sendMessage('success','Note Message','One note was archived !');
            this.getNotes();
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      })
    }
  }

  pinNote(note_id, status){
    status = status == '1' ? '0' : '1';
    const note_message = status == '1' ? 'One note was pinned!' : 'One note was unpinned!';
    this.weekPanelService.pinNoteIf(note_id,status).subscribe((response: any) =>{
      this.messageToastService.sendMessage('success','Note Message',note_message);
      this.getNotes();
    });
  }

  addNote(){
    this.submittedFormNote = false;
    this.note_id = '';
    this.n.text.setValue('');
    this.edit_note = false;
    this.noteAddEdit = 'Create';
    this.noteAddEditBtn = 'Save';
  }

  submitNewAdjust(week_id,index){
    const input_value = (<HTMLInputElement>document.getElementById("adjust_sale"+index)).value;
    const error = Array();
    if(input_value == ''){
      error.push(index,'required');
      this.requiredError = error;
      return false;
    }
    if(Number(input_value) > 100){
      error.push(index,'max');
      this.requiredError = error;
      return false;
    }
    if(Number(input_value) < -100){
      error.push(index,'min');
      this.requiredError = error;
      return false;
    }

    
    this.requiredError = error;
    this.loading = true;
		this.projectionService.updateSale(this.selectedStorage.id,this.yearQuarter.year,week_id,input_value).subscribe(
              response=> {
				    this.loading = false;
                this.loadQuarterProjection();
                this.loadYearProjection();
				   this.hideEdit = "";
				  },
              error => {
                this.loading = false;
              }
        );
  }


  year_sale_form = new FormGroup({
    week_sale: new FormControl('', [Validators.required]),
    week_id: new FormControl('', [Validators.required]),
  });
  
  get year_sale_f(){
    return this.year_sale_form.controls;
  }
  editInputPro(index){
	  this.hideEdit = '';
	  this.hideEdit = index;
  }
	
	editInputPro2(id,week){
		if(id !="0")
		{
		this.projectionService.getweekprojectionbyid(id).subscribe((response: any) =>{
			
			this.week_proj_id = response.data.id;
			this.week_number = response.data.week_number;
			this.projected_retail = response.data.projected_retail;
			this.projected_wire = response.data.projected_wire;
      this.projected_wholesale = response.data.projected_wholesale;
			this.projected_weddings = response.data.projected_weddings;
		 this.visibleDialogNote1 = true;
			
			});
			}	
		else
		{
			 this.week_number = week;
			 this.visibleDialogNote1 = true;
		}
	 
  }
  year_sale_submit(){
  }

  adjustSale(week_id,index){
    this.requiredError = [];
  }

  checkUserPermission(type){
    
		const hasPermission = this.userPermission;
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if(currentUser.isAdmin){
			return true;
		}
    else if (typeof hasPermission === "undefined")
		{
			return true;
		}
		else if (hasPermission.includes(type))
		{
			return true;
		}
		return false; 
	}
}
